
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function scrollElement(element: HTMLElement, options: ScrollToOptions): void {
    let behavior = options.behavior;

    // Safari has never supported behavior: 'smooth', but it worked as if 'auto'
    // However, in early 2022 an update stopped it from working at all.
    if (behavior == 'smooth' && isSafari) {
        behavior = 'auto';
    }

    element.scroll({
        left: options.left,
        behavior
    });
}

const MAX_HEADER_HEIGHT = 144;
const MAX_FOOTER_HEIGHT = 56;

export function ensureElementVisible(el: HTMLElement): void {
    const rect = el.getBoundingClientRect();

    if (rect.top < MAX_HEADER_HEIGHT) {
        window.scrollTo({
            top: window.scrollY + rect.top - MAX_HEADER_HEIGHT,
        });
    } else if (rect.bottom > window.innerHeight - MAX_FOOTER_HEIGHT) {
        window.scrollTo({
            top: window.scrollY + rect.bottom - (window.innerHeight - MAX_FOOTER_HEIGHT),
        });
    }
}
