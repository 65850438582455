import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-098726e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "flockler__grid"
}
const _hoisted_3 = ["href", "target", "alt"]
const _hoisted_4 = { class: "flockler__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_ctx.items)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.items)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: index,
                  href: item.url,
                  target: item.target,
                  class: "flockler__grid-card",
                  style: _normalizeStyle({ 'background-image': `url(${item.imageUrl})` }),
                  alt: item.title
                }, null, 12, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_varma_button, {
            ghost: "",
            onClick: _ctx.load
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('/blocks/flockler/loadmore')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 512), [
          [_vShow, _ctx.nextUrl]
        ])
      ]))
    : _createCommentVNode("", true)
}