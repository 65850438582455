
import { defineComponent } from 'vue';

declare const window: any;

export default defineComponent({
    props: { 
        name: { type: String }, 
        event: { type: String },
        action: { type: String } 
    },
    methods: {
        click(): void {
            if (!this.name || !this.action || !this.event) {
                return;
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(
                { 'name': this.name, 'action': this.action, 'event': this.event }
            )
        }
    }
});
