import { renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    "aria-hidden": !_ctx.active,
    role: "tabpanel"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1)), [
    [_vShow, _ctx.active]
  ])
}