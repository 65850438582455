
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        id: { type: String, required: true },
        jumpToId: { type: String, required: true }
    },
    data() {
        return {
            query: ''
        };
    },
    mounted() {
        document.addEventListener('DOMContentLoaded', () => {
            const skipToContentElPri = document.getElementById(this.jumpToId);
            const pageHasSkipToContent = typeof skipToContentElPri !== undefined && skipToContentElPri !== null;
            const skipAnchorEl = document.getElementById(this.id);

            this.query = window.location.search;

            if (!pageHasSkipToContent && skipAnchorEl) {
                skipAnchorEl.style.display = 'none';
            }               
        });
    }
});
