import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f441344"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "innerEl",
  class: "image-slider__inner"
}
const _hoisted_2 = {
  ref: "trackEl",
  "aria-live": "polite",
  class: "image-slider__track"
}
const _hoisted_3 = ["aria-hidden", "src", "alt"]
const _hoisted_4 = ["aria-hidden", "aria-label"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = {
  key: 0,
  class: "image-slider__pages"
}
const _hoisted_8 = ["id", "aria-label", "aria-current", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_image_slider_cards = _resolveComponent("varma-image-slider-cards")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'image-slider--fill': _ctx.fill }, "image-slider"]),
    onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startSwipe && _ctx.startSwipe(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["image-slider__view", { 'image-slider__view--round': _ctx.roundView }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.fill)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.images, (img, i) => {
                return (_openBlock(), _createElementBlock("img", {
                  key: i,
                  "aria-hidden": i != _ctx.innerIndex,
                  src: _ctx.getImageUrl(img.url),
                  alt: img.alt,
                  class: "image-slider__image"
                }, null, 8, _hoisted_3))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.images, (img, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  "aria-hidden": i != _ctx.innerIndex,
                  "aria-label": img.alt,
                  "aria-role": "img",
                  style: _normalizeStyle({ 'background-image': 'url(' + _ctx.getImageUrl(img.url) + ')' }),
                  class: "image-slider__fill-item"
                }, null, 12, _hoisted_4))
              }), 128))
        ], 512)
      ], 512),
      (_ctx.hasControls)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "image-slider__button image-slider__prev-button",
            "aria-label": _ctx.$t('/common/previous'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIndexRelative(-1)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "arrow-left-1",
              size: "S",
              weight: "bold",
              color: "black",
              "vertical-align": "middle"
            })
          ], 8, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.hasControls)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "image-slider__button image-slider__next-button",
            "aria-label": _ctx.$t('/common/next'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIndexRelative(1)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "arrow-right-1",
              size: "S",
              weight: "bold",
              color: "black",
              "vertical-align": "middle"
            })
          ], 8, _hoisted_6))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.hasControls && _ctx.showPages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (_, i) => {
            return (_openBlock(), _createElementBlock("button", {
              id: 'page-button' + i,
              key: i,
              class: _normalizeClass([{ active: i == _ctx.innerIndex }, "image-slider__page-button"]),
              "aria-label": `${_ctx.$t('/common/showImage')} ${i + 1}/${_ctx.images.length}`,
              "aria-current": i == _ctx.innerIndex,
              onClick: ($event: any) => (_ctx.setIndex(i))
            }, null, 10, _hoisted_8))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasControls && _ctx.showCards)
      ? (_openBlock(), _createBlock(_component_varma_image_slider_cards, {
          key: 1,
          images: _ctx.images,
          index: _ctx.innerIndex,
          "onUpdate:index": _ctx.setIndex
        }, null, 8, ["images", "index", "onUpdate:index"]))
      : _createCommentVNode("", true)
  ], 34))
}