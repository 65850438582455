
import { defineComponent, PropType } from 'vue';
import SearchRequest from '../search/VarmaSearchRequestModel';
import { SearchResult, FacetTag } from '../search/VarmaSearchResponseModel';
import VarmaCardSearchOptions from './VarmaCardSearchOptions.vue';
import VarmaCardSearchResults from './VarmaCardSearchResults.vue';
import { SortType } from '../../features/search/VarmaSearchApiService';

export default defineComponent({
    components: {
        VarmaCardSearchOptions,
        VarmaCardSearchResults
    },
    props: {
        filtersTitle: { type: String, required: true },
        typeFilters: { type: Object as PropType<FacetTag[]>, required: true },
        tagFilters: { type: Object as PropType<FacetTag[]>, required: true },
        preloadedUpcomingResult: { type: Object as PropType<SearchResult>, required: true },
        preloadedPastResult: { type: Object as PropType<SearchResult>, required: true },
        language: { type: String, required: true },
        pageSize: { type: Number, required: true },
        upcomingStartCount: { type: Number, required: true },
        pastStartCount: { type: Number, required: true },
        loadMoreButtonText: { type: String, default: '' },
    },
    data() {
        return {
            typeIds: [],
            tagIds: []
        } as {
            typeIds: number[];
            tagIds: number[];
        };
    },
    computed: {
        anySelections(): boolean {
            return this.typeIds.length > 0 || this.tagIds.length > 0;
        },
        searchRequest(): SearchRequest {
            return {
                query: '',
                skip: 0,
                take: this.pageSize,
                sort: SortType.SortDateDesc,
                types: this.typeIds.length
                    ? this.typeIds
                    : this.typeFilters.map(f => f.id),
                tags: this.tagIds,
                language: this.language
            };
        },
        upcomingSearchRequest(): SearchRequest {
            return {
                ...this.searchRequest,
                take: this.upcomingStartCount,
                minSortDate: new Date().toISOString().split('T')[0],
                sort: SortType.SortDateAsc,
            };
        },
        pastSearchRequest(): SearchRequest {
            const minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 1);

            return {
                ...this.searchRequest,
                take: this.pastStartCount,
                maxSortDate: new Date().toISOString().split('T')[0],
                minSortDate: minDate.toISOString().split('T')[0],
                sort: SortType.SortDateDesc
            };
        }
    }
});
