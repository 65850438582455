import { createApp } from 'vue';

// import standalone styles
import '../../styles/styles.scss';

import VarmaArticleCard from './components/common/card/VarmaArticleCard.vue';
import VarmaCardSlider from './components/features/card-slider/VarmaCardSlider.vue';
import VarmaIcon from './components/common/icons/VarmaIcon.vue';
import VarmaQaBlock from './components/features/qa-block/VarmaQABlock.vue';
import VarmaQaBlockContent from './components/features/qa-block/VarmaQABlockContent.vue';
import VarmaVerticalCollapse from './components/common/accordions/varma-vertical-collapse/VarmaVerticalCollapse.vue';
import VarmaButton from './components/common/buttons/varma-button/VarmaButton.vue';
import VarmaChip from './components/common/chip/VarmaChip.vue';
import VarmaTabGroup from './components/common/tabs/VarmaTabGroup.vue';
import VarmaTab from './components/common/tabs/VarmaTab.vue';
import VarmaInput from './components/common/forms/VarmaInput.vue';
import VarmaSelect from './components/common/forms/VarmaSelect.vue';
import VarmaTooltip from './components/common/tooltip/VarmaTooltip.vue';
import VarmaSitewideAlert from './components/features/sitewide-alert/VarmaSitewideAlert.vue';
import VarmaNotification from './components/features/notification/VarmaNotification.vue';
import VarmaCurrentIssuesSearch from './components/features/card-search/VarmaCurrentIssuesSearch.vue';
import VarmaEventsSearch from './components/features/card-search/VarmaEventsSearch.vue';
import VarmaTestComponent from './VarmaTestComponent.vue';
import VarmaWidowPensionCalculator from './components/features/widow-pension-calculator/VarmaWidowPensionCalculator.vue';
import VarmaAccessibilityFeedbackForm from './components/features/accessibility-feedback-form/VarmaAccessibilityFeedbackForm.vue';
import VarmaAddressChangeForm from './components/features/address-change-form/VarmaAddressChangeForm.vue';
import VarmaConsentForm from './components/features/consent-form/VarmaConsentForm.vue';
import VarmaGdprForm from './components/features/gdpr-form/VarmaGdprForm.vue';
import VarmaCheckbox from './components/common/forms/VarmaCheckbox.vue';
import VarmaRadioGroup from './components/common/forms/VarmaRadioGroup.vue';
import VarmaSearchPageFunctions from './components/features/search/VarmaSearchPageFunctions.vue';
import VarmaQuizBlock from './components/features/quiz-block/VarmaQuizBlock.vue';
import VarmaYelCalculator from './components/features/yel-calculator/VarmaYelCalculator.vue';
import VarmaPensionAgeCalculator from './components/features/pension-age-calculator/VarmaPensionAgeCalculator.vue';
import VarmaDialog from './components/common/dialog/VarmaDialog.vue';
import VarmaCookieDialog from './components/features/cookies/VarmaCookieDialog.vue';
import VarmaCookieSelect from './components/features/cookies/VarmaCookieSelect.vue';
import VarmaFeedback from './components/common/feedback/VarmaFeedback.vue';
import VarmaPodcast from './components/features/podcast-block/VarmaPodcast.vue';
import VarmaVideo from './components/features/video-block/VarmaVideo.vue';
import VarmaHeading from './components/common/headings/VarmaHeading.vue';
import VarmaRehabPartners from './components/features/rehab-partners/VarmaRehabPartners.vue';
import VarmaAbsenceCalculator from './components/features/absence-calculator/VarmaAbsenceCalculator.vue';
import VueFinalModal from 'vue-final-modal';
import SkipToContent from './components/features/skip-to-content/VarmaSkipToContent.vue';
import VarmaFlockler from './components/features/flockler/VarmaFlockler.vue';
import VarmaImageSlider from './components/features/images/VarmaImageSlider.vue';
import VarmaCampaignRentalProduct from './components/features/campaign-rental-product/VarmaCampaignRentalProduct.vue';
import VarmaPremisesHeader from './components/features/premises-header/VarmaPremisesHeader.vue';
import VarmaPremisesHeaderMenuItem from './components/features/premises-header/VarmaPremisesHeaderMenuItem.vue';
import VarmaGaEventPush from './components/common/gaevent/VarmaGaEventPush.vue';
import VarmaPremisesMapModal from './components/features/premises-map-modal/VarmaPremisesMapModal.vue';
import VarmaPremisesSearch from './components/features/premises-search/VarmaPremisesSearch.vue';
import VarmaPremisesLocationCard from './components/common/card/VarmaPremisesLocationCard.vue';
import VarmaPremisesFreeSpaces from './components/features/premises-free-spaces/VarmaPremisesFreeSpaces.vue';
import VarmaFormGrey from './components/common/forms/VarmaFormGrey.vue';
import VarmaPremisesContactForm from './components/features/premises-contact-form/VarmaPremisesContactForm.vue';
import VarmaFloatingContactPersonForm from './components/features/floating-contact-person-form/VarmaFloatingContactPersonForm.vue';
import VarmaTyelCalculator from './components/features/tyel-calculator/VarmaTyelCalculator.vue';
import VarmaWorkAbilityReport from './components/features/work-ability-report/VarmaWorkAbilityReport.vue';
import VarmaSimpleSlider from './components/features/simple-slider/VarmaSimpleSlider.vue';
import VarmaCareerStoriesVideo from './components/features/career-stories-video/VarmaCareerStoriesVideo.vue';
import VarmaYelIncomeCalculator from './components/features/yel-income-calculator/VarmaYelIncomeCalculator.vue';
import VarmaComboBox from './components/common/forms/VarmaComboBox.vue';
import VarmaPensionIncreaseCalculator from './components/features/pension-increase-calculator/VarmaPensionIncreaseCalculator.vue';
import VarmaToggle from './components/common/generic/VarmaToggle.vue';
import VarmaImageGallery from './components/features/images/VarmaImageGallery.vue';
import VarmaReact from './components/features/react-block/VarmaReact.vue';
import VarmaHeader2024 from './components/features/header2024/VarmaHeader2024.vue';

const components: { [key: string]: any } = {
    VarmaArticleCard,
    VarmaCardSlider,
    VarmaTestComponent,
    VarmaVerticalCollapse,
    VarmaQaBlock,
    VarmaQaBlockContent,
    VarmaButton,
    VarmaChip,
    VarmaTabGroup,
    VarmaTab,
    VarmaTooltip,
    VarmaSitewideAlert,
    VarmaNotification,
    VarmaWidowPensionCalculator,
    VarmaAccessibilityFeedbackForm,
    VarmaAddressChangeForm,
    VarmaConsentForm,
    VarmaGdprForm,
    VarmaCheckbox,
    VarmaRadioGroup,
    VarmaCurrentIssuesSearch,
    VarmaEventsSearch,
    VarmaSearchPageFunctions,
    VarmaQuizBlock,
    VarmaDialog,
    VarmaYelCalculator,
    VarmaPensionAgeCalculator,
    VarmaCookieDialog,
    VarmaCookieSelect,
    VarmaFeedback,
    VarmaPodcast,
    VarmaVideo,
    VarmaHeading,
    VarmaRehabPartners,
    VarmaAbsenceCalculator,
    VarmaFlockler,
    VarmaImageSlider,
    VarmaPremisesHeader,
    VarmaPremisesHeaderMenuItem,
    SkipToContent,
    VarmaCampaignRentalProduct,
    VarmaGaEventPush,
    VarmaPremisesMapModal,
    VarmaPremisesSearch,
    VarmaPremisesLocationCard,
    VarmaPremisesFreeSpaces,
    VarmaFormGrey,
    VarmaPremisesContactForm,
    VarmaFloatingContactPersonForm,
    VarmaTyelCalculator,
    VarmaWorkAbilityReport,
    VarmaSimpleSlider,
    VarmaCareerStoriesVideo,
    VarmaYelIncomeCalculator,
    VarmaComboBox,
    VarmaPensionIncreaseCalculator,
    VarmaToggle,
    VarmaImageGallery,
    VarmaReact,
    VarmaHeader2024,
};

const app = createApp({
    components: components
});

import Highcharts from 'highcharts';
import loadTreemap from 'highcharts/modules/treemap';
import loadWordCloud from 'highcharts/modules/wordcloud';
import VueHighcharts from 'vue-highcharts';

loadTreemap(Highcharts);
loadWordCloud(Highcharts);
app.use(VueHighcharts, { Highcharts });
app.use(VueFinalModal());

app.component('VarmaIcon', VarmaIcon);
app.component('VarmaCheckbox', VarmaCheckbox);
app.component('VarmaRadioGroup', VarmaRadioGroup);
app.component('VarmaInput', VarmaInput);
app.component('VarmaSelect', VarmaSelect);
app.component('VarmaButton', VarmaButton);
app.component('VarmaTooltip', VarmaTooltip);
app.component('VarmaChip', VarmaChip);
app.component('VarmaFeedback', VarmaFeedback);
app.component('VarmaDialog', VarmaDialog);
app.component('VarmaHeading', VarmaHeading);
app.component('VarmaGaEventPush', VarmaGaEventPush);
app.component('VarmaFormGrey', VarmaFormGrey);

import VarmaI18n from './plugins/varma-i18n';
import axios from 'axios';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t: (key: string) => string;
    }
}

declare global {
    interface Window {
        VarmaI18n?: { [key: string]: string };
        VarmaI18nLocale?: string;
    }
}

app.use(VarmaI18n);
app.provide('t', app.config.globalProperties.$t);

app.mount('#vfi-app');

axios.defaults.headers.common = {
    'X-CSRF-Token': (window as any).VarmaAntiForgeryToken
};
