import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be1fb12a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "onClick", "onKeyup"]
const _hoisted_2 = { class: "more-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_image_gallery_modal = _resolveComponent("varma-image-gallery-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass('images ' + 'images-' + _ctx.displayCount)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayImages, (img, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          ref_for: true,
          ref: "image",
          role: "button",
          tabindex: "0",
          style: _normalizeStyle({ 'background-image': `url(${_ctx.getImageUrl(img.url)})` }),
          alt: img.alt,
          class: _normalizeClass('image image-' + (index + 1)),
          onClick: ($event: any) => (_ctx.openImage(index)),
          onKeyup: _withKeys(($event: any) => (_ctx.openImage(index)), ["enter"])
        }, null, 46, _hoisted_1))
      }), 128))
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_varma_button, {
        ref: "more",
        ghost: "",
        onClick: _ctx.more
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("/blocks/imagegallery/showmoreimages")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 512), [
      [_vShow, _ctx.moreVisible]
    ]),
    _createVNode(_component_varma_image_gallery_modal, {
      index: _ctx.modalIndex,
      "onUpdate:index": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalIndex) = $event)),
      title: _ctx.title,
      images: _ctx.images,
      open: _ctx.modalOpen,
      "return-focus": _ctx.returnFocus,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalOpen = false))
    }, null, 8, ["index", "title", "images", "open", "return-focus"])
  ], 64))
}