
import { defineComponent } from 'vue';
import VarmaCookieCheck from '../cookies/VarmaCookieCheck.vue';

export default defineComponent({
    components: {
        VarmaCookieCheck,
    },
    props: {
        id: { type: String, required: true },
        iframeSrc: { type: String, required: true },
        manageCookiesUrl: { type: String, required: true }
    },
    data() {
        return {
            marketingCookiesAccepted: false
        } as { 
            marketingCookiesAccepted: boolean;
        };
    }
});
