import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62df8b8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accessibility-form" }
const _hoisted_2 = { class: "grid-contain" }
const _hoisted_3 = { class: "content col6-m-width" }
const _hoisted_4 = { class: "title heading-l" }
const _hoisted_5 = { class: "body content-text content-text--M" }
const _hoisted_6 = { class: "accessibility-form__input" }
const _hoisted_7 = {
  class: "accessibility-form__email-trap",
  "aria-hidden": "true"
}
const _hoisted_8 = { class: "contact-details-title" }
const _hoisted_9 = { class: "form-actions" }
const _hoisted_10 = { class: "title heading-l" }
const _hoisted_11 = { class: "confirm-field" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = { class: "confirm-field" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "value" }
const _hoisted_17 = { class: "confirm-field" }
const _hoisted_18 = { class: "label" }
const _hoisted_19 = { class: "value" }
const _hoisted_20 = { class: "form-actions" }
const _hoisted_21 = { class: "success-icon" }
const _hoisted_22 = { class: "title heading-l" }
const _hoisted_23 = { class: "content-text content-text--M" }
const _hoisted_24 = { class: "return-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_checkbox = _resolveComponent("varma-checkbox")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.state == 'Start')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("h2", _hoisted_4, [
                _renderSlot(_ctx.$slots, "title", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email2) = $event)),
                    type: "text",
                    tabindex: "-1"
                  }, null, 512), [
                    [_vModelText, _ctx.form.email2]
                  ])
                ]),
                _createVNode(_component_varma_input, {
                  ref: "feedback",
                  modelValue: _ctx.form.feedback,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.feedback) = $event)),
                  "label-text": _ctx.$t('/blocks/accessibilityfeedbackform/feedback'),
                  type: "textarea",
                  "min-height": "120px",
                  required: "",
                  rules: [_ctx.validateField]
                }, null, 8, ["modelValue", "label-text", "rules"])
              ]),
              _createVNode(_component_varma_checkbox, {
                modelValue: _ctx.requireReply,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.requireReply) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/requirereply')), 1)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _withDirectives(_createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/contactdetails')), 1),
                _createVNode(_component_varma_input, {
                  ref: "name",
                  modelValue: _ctx.form.name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.name) = $event)),
                  "label-text": _ctx.$t('/blocks/accessibilityfeedbackform/name'),
                  required: "",
                  rules: [_ctx.validateField]
                }, null, 8, ["modelValue", "label-text", "rules"]),
                _createVNode(_component_varma_input, {
                  ref: "email",
                  modelValue: _ctx.form.email,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.email) = $event)),
                  type: "email",
                  "label-text": _ctx.$t('/blocks/accessibilityfeedbackform/email'),
                  required: "",
                  rules: [_ctx.validateField, _ctx.validateEmail]
                }, null, 8, ["modelValue", "label-text", "rules"])
              ], 512), [
                [_vShow, _ctx.requireReply]
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_varma_button, { onClick: _ctx.next }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/continue')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_varma_button, {
                  ghost: "",
                  onClick: _ctx.reset
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/resetform')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ], 64))
          : (_ctx.state == 'Confirm' || _ctx.state == 'Sending')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/confirmtitle')), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/feedback')), 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.form.feedback), 1)
                ]),
                (_ctx.requireReply)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/name')), 1),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.form.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/email')), 1),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.form.email), 1)
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_varma_button, {
                    disabled: _ctx.sending,
                    onClick: _ctx.submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]),
                  _createVNode(_component_varma_button, {
                    ghost: "",
                    disabled: _ctx.sending,
                    onClick: _ctx.back
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/editform')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ], 64))
            : (_ctx.state == 'Sent')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_varma_icon, {
                      name: "check-circle",
                      size: "L",
                      color: "status-success",
                      weight: "bold"
                    })
                  ]),
                  _createElementVNode("h2", _hoisted_22, [
                    _renderSlot(_ctx.$slots, "sent-title", {}, undefined, true)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _renderSlot(_ctx.$slots, "sent-body", {}, undefined, true),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('/blocks/accessibilityfeedbackform/return')), 1)
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true)
      ])
    ])
  ]))
}