
import { defineComponent } from 'vue';

type RippleState = 'None' | 'Expand' | 'Active' | 'Fade';

export default defineComponent({    
    props: {
        modelValue: Boolean,
        disabled: { type: Boolean, required: false },
        allowTextWrap: { type: Boolean, required: false },
        ariaDescribedby: { type: String, required: false },
        inline:  { type: Boolean, required: false },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            internalValue: false,
            hadKeyboardEvent: true,
            keyboardFocus: false,
            animateCheck: false,
            animateUncheck: false,
            rippleState: 'None',
            targetRippleState: 'None'
        };
    },
    computed: {
        rippleStyle(): any {
            return this.rippleState == 'None'
                ? { transform: 'scale(0)', 'transition-duration': '0ms' }
                : this.rippleState == 'Expand'
                    ? { transform: 'scale(1)', 'transition-duration': '150ms' }
                    : this.rippleState == 'Active'
                        ? { transform: 'scale(1)', opacity: '0.16', 'transition-duration': '400ms' }
                        : { transform: 'scale(1)', opacity: '0', 'transition-duration': '400ms' };
        }
    },
    mounted(): void {
        this.internalValue = this.modelValue;

        this.$watch(
            () => this.internalValue,
            () => {
                this.$emit('update:modelValue', this.internalValue);
            });

        this.$watch(
            () => this.modelValue,
            () => {
                this.internalValue = this.modelValue;

                if (this.internalValue) {
                    this.animateCheck = true;
                } else {
                    this.animateUncheck = true;
                }

                setTimeout(this.clearAnimation, 500);
            });
    },
    methods: {
        changed(value: boolean): void {
            console.log(value);
        },
        clearAnimation(): void {
            this.animateCheck = false;
            this.animateUncheck = false;
        },

        // Keyboard focus is detected if the input is focused immediately after a keyboard event without any mouse events between.
        onmousedown(): void {
            this.hadKeyboardEvent = false;
            this.startRipple();
        },
        onmouseup(): void {
            this.hadKeyboardEvent = false;
            this.endRipple();
        },
        onkeydown(): void {
            this.hadKeyboardEvent = true;
        },
        inputFocus(): void {
            if (this.hadKeyboardEvent) {
                this.keyboardFocus = true;
            }
        },

        startRipple(): void {
            this.rippleState = 'Expand';
            this.targetRippleState = 'Active';

            setTimeout(this.updateRipple, 150);
        },
        endRipple(): void {
            if (this.rippleState == 'None') {
                return;
            }
            
            this.rippleState = 'Fade';
            this.targetRippleState = 'None';

            setTimeout(this.updateRipple, 400);
        },
        updateRipple(): void {
            this.rippleState = this.targetRippleState;
        },
    }
});
