
import { defineComponent, PropType } from 'vue';
import * as GA from '@/utils/GA';
import { language } from '@/utils/language';
import axios from 'axios';
import { validateFormFields, validateField } from '../../common/forms/validators';
import VarmaFeedbackType from '../../common/feedback/VarmaFeedbackType';

interface Result {
    total: number;
    employerTyelPayment: number;
    employeeSalary: number;
    otherPayments: number;
    sicknessInsurancePayment: number;
    unemployementInsurancePayment: number;
    accidentInsurance: number;
}

export default defineComponent({
    props: {
        ageOptionsOriginal: { type: Array as PropType<Array<{label: string; value: string}>>, required: true }
    },
    data() {
        return {
            monthlySalary: null,
            ageValue: null,
            result: null,
            loading: false,
            ageOptions: this.ageOptionsOriginal.map((option: any) => ({ 'label': option.label , 'value': option.label })),
            serverError: false,
            feedbackType: VarmaFeedbackType.Error
        } as {
            monthlySalary: null | number,
            ageValue: string | null,
            result: Result | null,
            loading: boolean,
            ageOptions: Array<{label: string; value: string}>,
            serverError: boolean,
            feedbackType: VarmaFeedbackType
        };
    },
    methods: {
        getResultText(result: number | undefined): string {
            return result ? result.toLocaleString(language) : '-';
        },
        getFullResultText(result: number | undefined): string {
            return  this.getResultText(result) + ' ' + this.$t('/unit/eurpermonth');
        },
        validateSalary(originalSalary: string) : string | boolean {
            if (!originalSalary) {
                return this.$t('/forms/error/requiredfield');
            }

            let salary: string = originalSalary.replaceAll(' ', '');

            if (!(/^\d+$/.test(salary)) || !parseInt(salary) || parseInt(salary) <= 0) {
                return this.$t('/forms/error/invalidnumber');
            }

            return false;
        },
        validateField(v: string): boolean | string {
            return validateField(v);
        },
        async submit(): Promise<void> {
            this.serverError = false;

            let hasError = validateFormFields(this.$refs, ['monthlySalaryRef', 'ageValueRef']);

            if (!hasError) {
                this.loading = true;
                GA.pushDataLayer({ 'name':'TyEL-calculator', 'action':'calculate', 'event': 'cCalculator' });

                try {
                    const response = await axios.post(
                        '/api/tyel/calculate', 
                        { 
                            monthlySalary: this.monthlySalary, 
                            ageValue: this.ageOptionsOriginal.find((option:any) => option.label === this.ageValue)?.value
                        }, 
                        { withCredentials: true});

                    this.result = response.data;
                } catch (error) {
                    this.serverError = true;
                    this.loading = false;
                }
                this.loading = false;
            }
        }
    }
});
