
import { defineComponent } from 'vue';
import axios from 'axios';
import VarmaButton from '../../common/buttons/varma-button/VarmaButton.vue';
import VarmaInput from '../../common/forms/VarmaInput.vue';
import VarmaHeading from '@/components/common/headings/VarmaHeading.vue';
import { validateField, validateEmail, validatePhoneNumber, validateSsn, validateDate } from '../../common/forms/validators';

interface FormData {
    name: string;
    ssn: string;
    email: string;
    phoneNumber: string;
    issuedOnBehalf: boolean;
    issuedByName: string;
    issuedByPhoneNumber: string;
    issuedByDetails: string;
    previousAddress: string;
    newAddress: string;
    validFrom: string;
}

function newData(): FormData {
    return {
        name: '',
        ssn: '',
        email: '',
        phoneNumber: '',
        issuedOnBehalf: false,
        issuedByName: '',
        issuedByPhoneNumber: '',
        issuedByDetails: '',
        previousAddress: '',
        newAddress: '',
        validFrom: '',
    };
}

export default defineComponent({    
    components: {
        VarmaButton,
        VarmaInput,
        VarmaHeading
    },
    data() {
        return {
            state: 'Start' as ('Start' | 'Confirm' | 'Sent'),
            form: newData()
        };
    },
    setup() {
        return { validateField, validateEmail, validateSsn, validateDate };
    },
    methods: {
        validatePhoneNumber(v: string): boolean | string {
            return validatePhoneNumber(v)
                ? this.$t('/forms/error/invalidphonenumber')
                : false;
        },
        reset(): void {
            this.state = 'Start';
            this.form = newData();
        },
        next(): void {
            let error = false;

            const fields = ['name', 'ssn', 'phoneNumber', 'previousAddress', 'newAddress', 'validFrom'].concat(this.form.issuedOnBehalf ? ['issuedByName'] : []);
            for (const key of fields) {
                error = (this.$refs[key] as any).validate() || error;
            }
            
            if (error) {
                return;
            }

            this.state = 'Confirm';
        },
        back(): void {
            this.state = 'Start';
        },
        async submit(): Promise<void> {
            const dateParts = this.form.validFrom.split('.');
            const date = `${parseInt(dateParts[2], 10)}, ${parseInt(dateParts[1], 10)}, ${parseInt(dateParts[0], 10)}`;

            await axios.post('/api/forms/address-change/', { ...this.form, validFrom: date });
            this.state = 'Sent';
        },
    }
});

