import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d72e7f46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-contain grid-contain--full-width-on-narrow" }
const _hoisted_2 = { class: "search-page-functions__inputs-wrap grid-content prefer-left col7-width" }
const _hoisted_3 = ["aria-label", "disabled"]
const _hoisted_4 = { class: "options" }
const _hoisted_5 = {
  role: "group",
  "aria-labelledby": "type-filters",
  class: "options__group"
}
const _hoisted_6 = {
  id: "type-filters",
  class: "options__heading"
}
const _hoisted_7 = ["aria-labelledby"]
const _hoisted_8 = ["id"]
const _hoisted_9 = {
  key: 0,
  class: "options__year-select"
}
const _hoisted_10 = { class: "options__group options__group--radio" }
const _hoisted_11 = { class: "grid-contain" }
const _hoisted_12 = {
  "aria-live": "polite",
  class: "search-page-functions__results-heading grid-content prefer-left col6-width"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "search-page-functions__results grid-content prefer-left col7-width" }
const _hoisted_15 = ["href", "innerHTML"]
const _hoisted_16 = { class: "search-result__details" }
const _hoisted_17 = {
  key: 0,
  class: "search-result__type"
}
const _hoisted_18 = {
  key: 1,
  class: "search-result__published"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "search-result__breadcrumb" }
const _hoisted_21 = { class: "search-page-functions__bottom-buttons grid-content prefer-left col7-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_checkbox = _resolveComponent("varma-checkbox")!
  const _component_varma_select = _resolveComponent("varma-select")!
  const _component_varma_radio_group = _resolveComponent("varma-radio-group")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_varma_input, {
            modelValue: _ctx.queryText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryText) = $event)),
            required: "",
            rules: [_ctx.requiredField],
            "label-text": _ctx.$t('/search/search-placeholder'),
            onEnter: _ctx.onSearchClick
          }, {
            "input-button": _withCtx(() => [
              _createElementVNode("button", {
                class: "search-button",
                "aria-label": _ctx.$t('/search/search-placeholder'),
                disabled: !_ctx.queryText,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSearchClick && _ctx.onSearchClick(...args)))
              }, [
                _createVNode(_component_varma_icon, {
                  name: "search",
                  weight: "regular",
                  size: "XS",
                  color: "white"
                })
              ], 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["modelValue", "rules", "label-text", "onEnter"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('/search/filters-main-types')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageFilterTypes, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.key
                }, [
                  _createVNode(_component_varma_checkbox, {
                    modelValue: item.checked,
                    "onUpdate:modelValue": ($event: any) => ((item.checked) = $event),
                    "allow-text-wrap": "",
                    onChange: ($event: any) => (_ctx.onFilterClick(index))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(item.labelKey)), 1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"]),
                  (item.subtypes && item.checked)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "options__subgroup",
                        role: "group",
                        "aria-labelledby": 'subtypes' + index
                      }, [
                        _createElementVNode("p", {
                          id: 'subtypes' + index,
                          class: "options__heading"
                        }, _toDisplayString(_ctx.$t(item.subtypesHeadingKey)), 9, _hoisted_8),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subtypes, (subtypeItem) => {
                          return (_openBlock(), _createBlock(_component_varma_checkbox, {
                            key: subtypeItem.key,
                            modelValue: subtypeItem.checked,
                            "onUpdate:modelValue": ($event: any) => ((subtypeItem.checked) = $event),
                            "allow-text-wrap": "",
                            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFilterClick()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(subtypeItem.labelKey)), 1)
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]))
                        }), 128)),
                        (item.hasYearFilter)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_varma_select, {
                                modelValue: _ctx.yearFilter,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.yearFilter) = $event)),
                                options: _ctx.yearOptions,
                                type: "number",
                                "label-text": _ctx.$t('/search/filters-years')
                              }, null, 8, ["modelValue", "options", "label-text"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_7))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_varma_radio_group, {
                id: "sort-options",
                modelValue: _ctx.sortType,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sortType) = $event)),
                "group-name": "sort-options",
                "group-label": _ctx.$t('/search/sort-heading'),
                options: _ctx.sortOptions
              }, null, 8, ["modelValue", "group-label", "options"])
            ])
          ]),
          _createVNode(_component_varma_button, {
            ghost: "",
            small: "",
            class: "options-clear-button",
            onClick: _ctx.clearFilters
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('/search/filters-clear-button')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        (!_ctx.isLoading && _ctx.searchesMade && _ctx.currentSearchResult.query)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_13, _toDisplayString(_ctx.currentSearchResult && _ctx.currentSearchResult.totalCount > 0 ? _ctx.resultsFoundText : _ctx.noResultsFoundText), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentSearchResult.hits, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.contentId,
            class: "search-result"
          }, [
            _createElementVNode("a", {
              href: item.url,
              class: "search-result__title",
              innerHTML: item.title
            }, null, 8, _hoisted_15),
            _createElementVNode("div", _hoisted_16, [
              (item.typeText)
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(item.typeText), 1))
                : _createCommentVNode("", true),
              (item.dateText)
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(item.dateText), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("p", {
              class: "search-result__description",
              innerHTML: item.description
            }, null, 8, _hoisted_19),
            _createElementVNode("p", _hoisted_20, _toDisplayString(item.breadcrumbs), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_21, [
        (_ctx.currentSearchResult.hasMore)
          ? (_openBlock(), _createBlock(_component_varma_button, {
              key: 0,
              ghost: "",
              onClick: _ctx.onShowMoreClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('/search/showmoreresults')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}