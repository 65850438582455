
import { defineComponent, PropType } from 'vue';

import VarmaImageGalleryModal from './VarmaImageGalleryModal.vue';
import { isSmallScreen } from '@/utils/viewport';

interface Image {
    url: string;
    alt: string;
}

export default defineComponent({
    components: {
        VarmaImageGalleryModal,
    },
    props: {
        title: { type: String, default: null },
        images: { type: Array as PropType<Image[]>, default: (): Image[] => [] },
    },
    data() {
        return {
            modalOpen: false,
            modalIndex: 0,
            smallCount: 3,
            returnFocus: null,
        } as {
            modalOpen: boolean;
            modalIndex: number;
            smallCount: number;
            returnFocus: HTMLElement | null;
        };
    },
    computed: {
        displayCount(): number {
            let count = isSmallScreen.value
                ? this.smallCount
                : 7;
            return Math.min(this.images.length, count);
        },
        displayImages(): Image[] {
            return this.images.slice(0, this.displayCount);
        },
        moreVisible(): boolean {
            return !isSmallScreen.value || this.smallCount < this.images.length;
        }
    },
    methods: {
        getImageUrl(url: string): string {
            return `${url}?preset=image-gallery`;
        },
        openImage(index: number) {
            if (isSmallScreen.value) {
                return;
            }

            this.returnFocus = (this.$refs.image as HTMLElement[])[index];
            this.modalIndex = index;
            this.modalOpen = true;
        },
        more() {
            if (isSmallScreen.value) {
                this.smallCount += 3;
            } else {
                this.returnFocus = (this.$refs.more as HTMLElement[])[0];
                this.modalIndex = 0;
                this.modalOpen = true;
            }
        }
    }
});
