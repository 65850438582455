import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e49d1fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "consent-form" }
const _hoisted_2 = { class: "grid-contain" }
const _hoisted_3 = { class: "content grid-content prefer-center col6-m-width" }
const _hoisted_4 = { class: "title heading-l" }
const _hoisted_5 = { class: "content-text content-text--M" }
const _hoisted_6 = { class: "approve-label" }
const _hoisted_7 = { class: "form-actions" }
const _hoisted_8 = { class: "success-icon" }
const _hoisted_9 = { class: "title heading-l" }
const _hoisted_10 = { class: "content-text content-text--M" }
const _hoisted_11 = { class: "return-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_form_auth = _resolveComponent("varma-form-auth")!
  const _component_varma_checkbox = _resolveComponent("varma-checkbox")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.state != 'Sent')
          ? (_openBlock(), _createBlock(_component_varma_form_auth, {
              key: 0,
              identity: _ctx.state != 'Start' ? _ctx.identity : null,
              "is-gdpr-admin": _ctx.isGdprAdmin
            }, {
              title: _withCtx(() => [
                _renderSlot(_ctx.$slots, "title", {}, undefined, true)
              ]),
              body: _withCtx(() => [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["identity", "is-gdpr-admin"]))
          : _createCommentVNode("", true),
        (_ctx.state == 'Form' || _ctx.state == 'Sending')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h3", _hoisted_4, [
                _renderSlot(_ctx.$slots, "consent-title", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "consent-body", {}, undefined, true)
              ]),
              _createVNode(_component_varma_checkbox, {
                modelValue: _ctx.approved,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.approved) = $event)),
                class: "approve-checkbox"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.approveText), 1)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_varma_button, {
                  disabled: !_ctx.approved || _ctx.sending,
                  onClick: _ctx.submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/consentform/submit')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                _createVNode(_component_varma_button, {
                  ghost: "",
                  disabled: _ctx.sending,
                  onClick: _ctx.reset
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/consentform/abort')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ], 64))
          : (_ctx.state == 'Sent')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_varma_icon, {
                    name: "check-circle",
                    size: "L",
                    color: "status-success",
                    weight: "bold"
                  })
                ]),
                _createElementVNode("h2", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "sent-title", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _renderSlot(_ctx.$slots, "sent-body", {}, undefined, true),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)), ["prevent"]))
                    }, _toDisplayString(_ctx.$t('/blocks/consentform/return')), 1)
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}