

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        modelValue: Boolean,
        disabled: Boolean,
        hideFromScreenReader: Boolean,
        selected: Boolean,
        role: { type: String, default: 'option' },
        ariaDescribedby: { type: String, default: null }
    },
    emits: ['update:modelValue'],
    methods: {
        toggle(): void {
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
});
