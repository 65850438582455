
import { defineComponent, nextTick } from 'vue';

import { scrollElement } from '@/utils/scroll';

import VarmaIcon from '../../common/icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon,
    },
    data() {
        return {
            items: 0,
            index: 0,
            timeout: undefined
        } as {
            items: number;
            index: number;
            timeout: any;
        };
    },
    computed: {
        hasControls(): boolean {
            return this.items > 1;
        }
    },
    mounted() {
        this.items = this.getTrackEl()!.children.length;
        this.setup();
        window.addEventListener('resize', this.setup);
    },
    methods: {
        getInnerEl(): HTMLDivElement | null {
            return this.$refs['innerEl'] as HTMLDivElement;
        },

        getTrackEl(): HTMLDivElement | null {
            return this.$refs['trackEl'] as HTMLDivElement;
        },

        async setup(): Promise<void> {
            await nextTick();
            this.setIndex(this.index);
        },

        setIndex(index: number): void {
            const left = this.getInnerEl()!.offsetWidth * index;

            scrollElement(this.getInnerEl()!, {
                left: left,
                behavior: 'smooth'
            });

            this.index = index;
        },

        setIndexRelative(rel: number): void {
            let index = this.index + rel;

            if (index < 0) {
                index = this.items - 1;
            } else if (index >= this.items) {
                index = 0;
            }

            this.setIndex(index);
        },

        startSwipe(touchEvent: TouchEvent) {
            if (touchEvent.changedTouches.length !== 1) {
                return;
            }

            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },

        touchEnd (touchEvent: TouchEvent, posXStart: number) {
            if (touchEvent.changedTouches.length !== 1) {
                return;
            }

            const posXEnd = touchEvent.changedTouches[0].clientX;

            if (posXStart < posXEnd) {
                this.setIndexRelative(-1);
            } else if (posXStart > posXEnd) {
                this.setIndexRelative(1);
            }
        }
    }
});
