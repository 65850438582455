
import { defineComponent, ref } from 'vue';
import VarmaButton from '../../common/buttons/varma-button/VarmaButton.vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';

interface VarmaQuizElement {
    groupname: string;
    text: string;
    option: QuizOption[]; 
    isFirst: boolean;  
    link: string;
    linkText: string;
}

interface VarmaQuiz {
  quizTitle: string;
  quizIntro: string;
  radioName: string;
  quizTable: VarmaQuizElement[];
}

interface QuizOption {
    text: string;
    goto: string;
    id: string;    
}

export default defineComponent({
    components: {
        VarmaButton,
        VarmaIcon
    },
    props: {
        data: { type: String, required: true }
    },
    setup(props) {
        const model: VarmaQuiz = JSON.parse(props.data);        
        const firstgroup = model.quizTable.find(x => x.isFirst);
        if(!firstgroup) throw new Error('first group is undefined.');

        const currentGroup = ref(firstgroup);

        const previousGroups = ref<[VarmaQuizElement, string][]>([]);
        const checkedOption = ref(''); 
        const start = ref(false);
        const showStartButton = ref(true);
        const showPreviousButton = ref(false);
        const showNextButton = ref(false);
        const showStartOverButton = ref(false);
        const errortext = ref('');
        

        return {
            checkedOption,
            currentGroup,
            previousGroups,
            start,
            showStartButton,
            showPreviousButton,
            showNextButton,
            showStartOverButton,
            errortext,
            model,
        };
    },
    computed: {
        groupId(): string {
            const id: string = this.currentGroup.groupname.replaceAll(' ', '');

            return id;
        }
    },
    methods: {
        startQuiz(): void {
            this.start = !this.start;
            this.showNextButton = true;
            this.showStartButton = false;
            this.$nextTick(() => {
                (this.$refs.firstFocus as HTMLSpanElement).focus();
            });                        
        },
        setFocus(hideCallback?: Function): void {
            //reset focus on question change
            if (hideCallback) {
                hideCallback();
            }

            this.$nextTick(() => {
                (this.$refs.focusOnChange as HTMLParagraphElement).focus();
            });
        },
        previousQuiz(): void {
            if (this.previousGroups && this.previousGroups.length > 0) {
                const prev = this.previousGroups.pop();
                if (prev && prev[0]){
                    this.currentGroup = prev[0];
                    this.checkedOption = prev[1];
                    this.errortext = '';
                    
                }
            }
            this.showNextButton = true;
            this.showStartOverButton = false;
            this.setFocus(() => this.showPreviousButton = (this.previousGroups && this.previousGroups.length > 0));
        },
        IsQuestion(): boolean {
            if (!this.currentGroup) return false;
            return this.currentGroup.option.length > 0;
        },
        goToStart(): void {
            if (this.previousGroups.length > 0) {
                const first = this.previousGroups[0];
                if (first && first[0]) {
                    this.currentGroup = first[0];
                    this.checkedOption = '';
                    this.errortext = '';
                    this.previousGroups = [];
                    
                }
                this.showPreviousButton = false;
                this.showNextButton = this.IsQuestion();
            }    
            this.setFocus(() => this.showStartOverButton = !this.IsQuestion());
        },
        nextQuiz(): void {
            if (this.checkedOption && this.checkedOption !== '') {
                this.errortext = '';

                if (!this.currentGroup) {
                    return;
                }
                
                const option = this.currentGroup.option.find(x => x.text === this.checkedOption);
                if (!option) {
                    return;
                }
                
                const next = this.model.quizTable.find(x => x.groupname === option.goto);
                if (!next) {
                    return;
                }

                this.previousGroups.push([this.currentGroup, this.checkedOption]);
                this.currentGroup = next;
                this.checkedOption = '';
                this.errortext = '';
                
                this.showPreviousButton = true;         
                this.setFocus(() => this.showNextButton = this.IsQuestion());
                this.showStartOverButton = !this.IsQuestion();                
            } else if (this.checkedOption === '') {
                this.errortext = this.$t('/blocks/quiz/selectionerror');
            }
        }
    }
});
