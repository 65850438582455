import axios from 'axios';

const varmaDomain = 'varma.fi';

export enum VarmaCookies {
    Mandatory = 'varmafi_mandatory',
    Analytics = 'varmafi_analytics',
    Marketing = 'varmafi_marketing'
}

export const hasCookie = (cookieName: string): boolean => {
    return document.cookie.includes(cookieName);
};

const getDomainString = (domainName: string): string => {
    return 'domain=' + domainName;
};

const getCookieDomain = (): string => {
    return getDomainString(document.domain.includes(varmaDomain) ? varmaDomain : document.domain);
};

export const setSitewideBooleanCookie = (cookieName: string): void => {
    document.cookie = cookieName + '=true;' + getCookieDomain() + ';path=/;max-age=7889400'; //persists cookie for three months

    // axios.post('/api/usage/cookie', { cookie: cookieName });
};

export const removeSitewideBooleanCookie = (cookieName: string): void => {
    document.cookie = cookieName + '=true;' + getDomainString('.' + varmaDomain) + ';path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = cookieName + '=true;' + getDomainString(varmaDomain) + ';path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = cookieName + '=true;' + getDomainString(document.domain) + ';path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
};