
import { defineComponent, PropType } from 'vue';
import { HeaderNaviTopicModel } from './model';

export default defineComponent({
    components: {
    },
    props: {
        topic: { type: Object as PropType<HeaderNaviTopicModel>, required: true },
    },
});
