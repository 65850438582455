
import { defineComponent } from 'vue';
import VarmaCookieCheck from '../cookies/VarmaCookieCheck.vue';

export default defineComponent({
    components: {
        VarmaCookieCheck
    },
    props: {
        iframeSrc: { type: String, required: true },
        manageCookiesUrl: { type: String, required: true },
        previewImageUrl: { type: String, required: true }
    },
    data() {
        return {
            enabled: false,
            embedVisible: !this.previewImageUrl
        } as { 
            enabled: boolean;
            embedVisible: boolean;
        };
    },
});
