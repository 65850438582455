
import { defineComponent, PropType, ref, onBeforeUpdate } from 'vue';
import VarmaArticleCard from '../../common/card/VarmaArticleCard.vue';
import SearchRequest from '../search/VarmaSearchRequestModel';
import { SearchResult } from '../search/VarmaSearchResponseModel';
import { VarmaSearchApiService } from '../../features/search/VarmaSearchApiService';

enum SearchResultHitAction {
    PUSH,
    REPLACE
}

export default defineComponent({
    components: {
        VarmaArticleCard
    },
    props: {
        preloadedResult: { type: Object as PropType<SearchResult>, required: true },
        searchRequest: { type: Object as PropType<SearchRequest>, required: true},
        pageSize: { type: Number, required: true },
        loadMoreButtonText: { type: String, default: '' },
        title: { type: String, default: '' },
        displayCount: Boolean
    },
    setup() {
        const cards = ref<Array<typeof VarmaArticleCard>>([]);

        onBeforeUpdate(() => {
            cards.value = [];
        });

        return {
            cards
        };
    },
    data() {
        return {
            nextSkip: 0,
            isLoading: false,
            searchResult: this.preloadedResult,
            searchApiService: new VarmaSearchApiService()
        } as {
            nextSkip: number;
            isLoading: boolean;
            searchResult: SearchResult;
            searchApiService: VarmaSearchApiService;
        };
    },
    mounted() {
        this.nextSkip = this.preloadedResult.hits.length;
        this.searchResult = this.preloadedResult;

        this.$watch(
            () => this.searchRequest,
            () => {
                this.nextSkip = 0;
                this.searchNext(SearchResultHitAction.REPLACE);
            });
    },
    methods: {
        showMore(): void {
            const lastIndex: number = this.searchResult.hits.length - 1;
            this.searchNext(SearchResultHitAction.PUSH, lastIndex);
        },
        async searchNext(operation: SearchResultHitAction, lastIndex?: number): Promise<void> {
            this.isLoading = true;

            let skip = this.nextSkip;

            // First results count may deviate from the page size as configured in searchRequest.take
            let take = skip == 0
                ? this.searchRequest.take || this.pageSize
                : this.pageSize;

            try {
                const result = await this.searchApiService.searchContent({
                    ...this.searchRequest,
                    skip: skip,
                    take: take,
                });

                this.nextSkip += result.hits.length;

                if (operation == SearchResultHitAction.PUSH) {
                    this.searchResult = {
                        ...result,
                        hits: [
                            ...this.searchResult.hits,
                            ...result.hits
                        ]
                    };                                         
                }
                else {
                    this.searchResult = result;
                }
            }
            finally {
                this.isLoading = false;

                this.$nextTick( () => {
                    if (lastIndex !== undefined && this.cards && this.cards.length > lastIndex + 1) {
                        this.cards[lastIndex + 1].focus();
                    }
                });
            }
        }
    }
});
