import { translate } from "@/utils/i18n";

export function validateField(v: string): boolean | string {
    return !v
        ? translate('/forms/error/requiredfield')
        : false;
}

export function validateEmail(v: string): boolean | string {
    if (!v) {
        return false;
    }
    
    v = v || '';
    const index = v.indexOf('@');
    return index === -1 || index === 0 || index === v.length - 1
        ? translate('/forms/error/invalidemail')
        : false;
}

export function validatePhoneNumber(v: string): boolean | string {
    if (!v) {
        return false;
    }
    
    return !/^\d+$/.test(v) || v.length < 5
        ? translate('/forms/error/invalidphonenumber')
        : false;
}

export function validateSsn(v: string): boolean | string {
    return !v || v.length != 11;
}

export function validateDate(v: string): boolean | string {
    return !/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(v);
}

export function validatePostalCode(v: string): boolean | string {
    v = v || '';
    return !/^\d+$/.test(v) || v.length < 4 || v.length > 6
        ? translate('/forms/error/invalidpostalcode')
        : false;
}

export function validateFormFields(refs: Record<string, any>, fields: string[]): boolean {
    let error = false;
    let firstField = null;

    for (const key of fields) {
        error = refs[key].validate() || error;
        firstField = firstField || (error ? refs[key] : null);
    }

    if (error) {
        firstField.focus();
    }

    return error;
}