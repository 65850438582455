
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        searchUrl: { type: String, default: null },
        grow: Boolean,
    },
    methods: {
        focus(): void {
            (this.$refs['input'] as HTMLInputElement).focus();
        }
    }
});
