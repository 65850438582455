
import { defineComponent, PropType } from 'vue';
import axios from 'axios';
import VarmaComboBox, { SimpleOption } from '../../common/forms/VarmaComboBox.vue';
import VarmaFeedbackType from '../../common/feedback/VarmaFeedbackType';
import { validateFormFields } from '../../common/forms/validators';
import { language } from '@/utils/language';

interface Result {
    min: number;
    max: number;
    incomeRecommendation: number;
}

export default defineComponent({
    components: {
        VarmaComboBox,
    },
    props: {
        industryOptions: { type: Array as PropType<SimpleOption[]>, required: true }
    },
    data() {
        return {
            incomeEstimate: null,
            industry: null,
            result: null,
            loading: false,
            feedbackType: VarmaFeedbackType.Error,
            error: false,
        } as {
            incomeEstimate: string | null,
            industry: SimpleOption | null,
            result: Result | null,
            loading: boolean,
            feedbackType: VarmaFeedbackType,
            error: boolean,
        };
    },
    computed: {
        resultInfo(): string {
            let resultText = this.$t('/blocks/yelincomecalculator/resultInfo');

            if (this.result && this.result.min && this.result.max) {
                resultText = resultText.replace('{min}', this.result.min.toLocaleString(language));
                resultText = resultText.replace('{max}', this.result.max.toLocaleString(language));
            }

            return resultText;
        },
        incomeRecommendation() {
            return this.result?.incomeRecommendation?.toLocaleString(language);
        },
    },
    watch: {
        incomeEstimate(): void {
            this.result = null;
        },
        industry(): void {
            this.result = null;
        }
    },
    methods: {
        async submit(): Promise<void> {
            const error = validateFormFields(this.$refs, ['incomeField','industryField']);

            if (!error) {
                this.loading = true;
                try {
                    const response = await axios.post('/api/yel/income', { incomeEstimate: parseInt(this.incomeEstimate!), industry: this.industry?.value });
                    this.result = response.data;
                    this.loading = false;
                    this.$nextTick(() => (this.$refs.resultHeading as HTMLElement).focus());
                } catch (error) {
                    this.error = true;
                    this.loading = false;
                }
            }
        },
        validateIndustry(): string | boolean {
            if (!this.industry || !this.industryOptions?.find(option => option.value === this.industry?.value)) {
                return this.$t('/blocks/yelincomecalculator/industryError');
            }
            return false;
        },
        validateIncome(): string | boolean {
            if (!this.incomeEstimate || this.incomeEstimate.length > 9 || !/^[0-9]+$/.test(this.incomeEstimate)) {
                return this.$t('/blocks/yelincomecalculator/incomeError');
            }
            return false;
        },
    }
});
