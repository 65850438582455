import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "video-block__video"
}
const _hoisted_2 = ["id", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_cookie_check = _resolveComponent("varma-cookie-check")!

  return (!_ctx.marketingCookiesAccepted)
    ? (_openBlock(), _createBlock(_component_varma_cookie_check, {
        key: 0,
        "manage-url": _ctx.manageCookiesUrl,
        "title-text": _ctx.$t('/blocks/video/missingcookieheading'),
        onAccepted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.marketingCookiesAccepted = true))
      }, null, 8, ["manage-url", "title-text"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("iframe", {
          id: _ctx.id,
          class: "yt-iframe",
          src: _ctx.iframeSrc,
          frameborder: "0",
          allowfullscreen: ""
        }, null, 8, _hoisted_2)
      ]))
}