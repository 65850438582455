
import { defineComponent, nextTick, reactive, watch, ref } from 'vue';
import axios from 'axios';
import * as GA from '@/utils/GA';
import { language } from '@/utils/language';

interface ResultType {
    newEntrepreneurYearlyPayment: number;
    oldEntrepreneurYearlyPayment: number;
    newEntrepreneurMonthlyPayment: number;
    oldEntrepreneurMonthlyPayment: number;
    pensionMinRetirementAge: number | null;
    minRetirementAgePassed: number | null;
    minRetirementAgeYears: number;
    minRetirementAgeMonths: number;
    targetRetirementAgePassed: number | null;
    targetRetirementAgeYears: number | null;
    targetRetirementAgeMonths: number | null;
    pensionTargetRetirementAge: number | null;
    sicknessAllowance: number;
    parentalAllowance: number;
    basicDailyAllowance: number;
    earningsAdjustedDailyAllowance: number;
}

export default defineComponent({
    props: {
        minIncome: { type: Number, required: true },
        maxIncome: { type: Number, required: true },
        defaultYearlyIncome: { type: Number, default: 0 },
        defaultAccruedPension: { type: Number, default: 0 },
        defaultBirthYear: { type: Number, default: 0 },
        infoTextResults: { type: String, required: false, default: ''},
        infoTextAllowance: { type: String, required: false, default: ''},
        infoTextIncrement: { type: String, required: false, default: ''},
        link1Text: { type: String, required: false, default: ''},
        link1: { type: String, required: false, default: ''},
        link2Text: { type: String, required: false, default: ''},
        link2: { type: String, required: false, default: ''},
        onlineServiceLink: { type: String, required: false, default: ''},
        onlineServiceLinkText: { type: String, required: false, default: ''}
    },
    setup(props) {
        const inputs = reactive({
            yearlyIncome: props.defaultYearlyIncome ? props.defaultYearlyIncome.toString() : '',
            accruedPension: props.defaultAccruedPension ? props.defaultAccruedPension.toString() : '',
            birthYear: props.defaultBirthYear ? props.defaultBirthYear.toString() : '',
        });

        const showAccruedPension = ref(false);

        const birthMonth = ref<HTMLSelectElement>();
        const installments = ref<HTMLSelectElement>();
        const dueMonth = ref<HTMLSelectElement>();

        const result = ref(null as null | undefined | ResultType);
        const selectedInstallment = ref(12);
        const selectedBirthMonth = ref(1);
        const selectedDueMonth = ref(1);
        const selectedWageIncrement = ref('Future');
        const allowedInstallments = [[1,2,3,4,6,12], [1,2,3,4,6], [1,2,3,4], [1,2], [1,2], [1,2], [1]];
        
        watch([inputs, selectedInstallment, selectedBirthMonth, selectedDueMonth],
            () => {
                result.value = undefined;
            },
            { deep: true });


        return {
            inputs: inputs,
            showAccruedPension,
            birthMonth,
            installments,
            dueMonth,
            result,
            selectedInstallment,
            allowedInstallments,
            selectedBirthMonth,
            selectedDueMonth,
            selectedWageIncrement,
        };         
    },
    computed: {
        hasResult(): boolean {
            return this.result !== null;
        },
        incomeLimitsHint(): string {
            return this.$t('/blocks/yelcalculator/incomelimits')
                .toString()
                .replace('{minlimit}', this.formatNumber(this.minIncome))
                .replace('{maxlimit}', this.formatNumber(this.maxIncome));
        },
        wageIncrementOptions() {
            return [{
                label: this.$t('/blocks/yelcalculator/wageincrementfuture').toString(),
                value: 'Future',
            }, {
                label: this.$t('/blocks/yelcalculator/wageincrementcurrent').toString(),
                value: 'Current',
            }];
        }
    },
    async mounted() {
        if (this.inputs.yearlyIncome && this.inputs.birthYear) {
            // Required fields were pre-filled, attempt to submit
            await nextTick();
            this.submit();
        }

        this.$watch(() => this.selectedWageIncrement, () => {
            if (this.result) {
                this.submit();
            }
        });
    },
    methods: {
        dueDateReset(): void
        {
            if(!this.allowedInstallments[this.selectedDueMonth - 1].some(x => x == this.selectedInstallment)) {
                this.selectedDueMonth = 1;
            }
        },
        async toggleAccruedPension(): Promise<void> {
            this.showAccruedPension = !this.showAccruedPension;

            if (this.showAccruedPension) {
                await nextTick();
                (this.$refs['accruedPension'] as HTMLElement).focus();
            }
        },
        validateNumber(value: string): boolean | string {
            if (!Number.isFinite(Number(value.replace(',','.')))) {
                return this.$t('/blocks/yelcalculator/error/notanumber');
            }

            const numberValue: number =+value;
            if(numberValue < 0) {
                return this.$t('/blocks/yelcalculator/error/greaterthanzero');
            }

            return false;
        },
        requiredField(value: string): boolean | string {
            if (!value) {
                return this.$t('/blocks/yelcalculator/error/giveamount');
            }

            return false;
        },
        validateIncomeLimits(value: string): boolean | string {
            if (value) {
                const numberValue: number =+(value.replace(',','.'));
                if(numberValue > this.maxIncome) {
                    return this.$t('/blocks/yelcalculator/error/equalorlessthanmax').replace('{value}', this.maxIncome.toString());                
                }

                if(numberValue < this.minIncome) {
                    return this.$t('/blocks/yelcalculator/error/equalormorethanmin').replace('{value}', this.minIncome.toString());
                }
            }

            return false;
        },
        formatNumber(value: number): string {
            return value.toLocaleString(language);
        },
        validateBirthYear(value: string): boolean | string {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1;

            const birthYear: number=+value;
            const birthmonth: number=+(this.birthMonth ? this.birthMonth.value : 1);

            const ageInMonths =  ((currentYear - birthYear) * 12) + currentMonth - birthmonth;            

            if(ageInMonths < (18 * 12)) {
                return this.$t('/blocks/yelcalculator/error/18y');
            } else if(birthYear <= 1957) {
                if (ageInMonths > (68 * 12)) {
                    return this.$t('/blocks/yelcalculator/error/1957y');
                }
            }  else if ((birthYear >= 1958 && birthYear <= 1961)) {
                if (ageInMonths > (69 * 12)) {
                    return this.$t('/blocks/yelcalculator/error/1958-1961y');
                }
            }
            else if (birthYear > 1961) {
                if (ageInMonths > (70 * 12)) {
                    return this.$t('/blocks/yelcalculator/error/1961y');
                }
            }

            if(!Number.isInteger(birthYear)) {
                return this.$t('/blocks/yelcalculator/error/integer');
            }

            if(birthYear < 1800 || birthYear > 9999) {
                return this.$t('/blocks/yelcalculator/error/birthyear');
            }

            return false;
        },
        async submit(): Promise<void> {
            let error = false;
            for (const key of Object.keys(this.inputs)) {
                error = (this.$refs[key] as any).validate() || error;
            }

            if (error) {
                return;
            }
           
            if(!this.dueMonth || !this.installments || !this.birthMonth) return;

            this.result = null;

            GA.pushDataLayer({ 'name':'YEL-calculator', 'action':'calculate', 'event': 'cCalculator' });

            let accruedPensionValue = this.inputs.accruedPension?.replace(',','.');
            if(!accruedPensionValue) accruedPensionValue = '0';

            const { data } = await axios.post('/api/yel/calculate', {
                YearlyIncome: this.inputs.yearlyIncome.replace(',','.'),
                AccruedPension: accruedPensionValue,
                BirthYear: this.inputs.birthYear,
                BirthMonth: this.birthMonth.value,
                InstallmentCount: this.installments.value,
                FirstDueMonth: this.dueMonth.value,
                CalculationDate: new Date().toISOString(),
                WageIncrement: this.selectedWageIncrement,
            }, {
                withCredentials: true
            });
            
            this.result = data as ResultType;            
            
            return;
          
        }
    }
});
