
import { defineComponent, PropType } from 'vue';
import { HeaderModel, HeaderNaviCategoryModel, HeaderNaviTopicModel } from './model';

export default defineComponent({
    components: {
    },
    props: {
        model: { type: Object as PropType<HeaderModel>, required: true },
    },
    data() {
        return {
            path: [],
        } as {
            path: [] | [HeaderNaviTopicModel] | [HeaderNaviTopicModel, HeaderNaviCategoryModel]
        };
    },
    computed: {
        topic(): null | HeaderNaviTopicModel {
            return this.path[0] || null;
        },
        category(): null | HeaderNaviCategoryModel {
            return this.path[1] || null;
        },
    },
    mounted() {
        const topic = this.model.topics.find(t => t.active);
        const category = topic?.categories.find(c => c.active);
        this.path = [topic, category].filter(i => i != null) as any;
    },
    methods: {
        ascend(): void {
            this.path = this.path.slice(0, this.path.length - 1) as any;
        },
        setTopic(topic: HeaderNaviTopicModel) {
            if (topic.categories.length != 1) {
                this.path = [topic];
            } else {
                this.setCategory(topic, topic.categories[0]);
            }
        },
        setCategory(topic: HeaderNaviTopicModel, category: HeaderNaviCategoryModel) {
            this.path = [topic, category];
        }
    }
});
