
import { defineComponent } from 'vue';
import { VarmaCookies, hasCookie, setSitewideBooleanCookie, removeSitewideBooleanCookie } from './VarmaCookies';
import VarmaFeedbackType from '../../common/feedback/VarmaFeedbackType';
import * as GA from '../../../utils/GA';

export default defineComponent({
    props: {
        darkDivider: { type: Boolean, required: false, default: false },
        title: { type: String, required: false, default: undefined },
        saveButton: { type: String, required: true },
        mandatoryHeading: { type: String, required: true },
        mandatoryText: { type: String, required: true },
        analyticsHeading: { type: String, required: true },
        analyticsText: { type: String, required: true },
        marketingHeading: { type: String, required: true },
        marketingText: { type: String, required: true },
        cookiesSavedMessage: { type: String, default: '' },
        updateGa: { type: Boolean, default: false}
    },
    emits: ['save'],
    setup() {
        const hadMarketing: boolean = hasCookie(VarmaCookies.Marketing);
        const hadAnalytics: boolean = hasCookie(VarmaCookies.Analytics);

        return {
            hadMarketing,
            hadAnalytics
        };
    },
    data() {
        return { 
            mandatory: true,
            marketing: false,
            analytics: false,
            feedbackVisible: false,
            feedbackType: VarmaFeedbackType.Success
        };
    },
    mounted() {
        this.marketing = this.hadMarketing;
        this.analytics = this.hadAnalytics;
    },
    methods: {
        saveCookies(): void {
            if (this.analytics !== this.hadAnalytics) {
                this.setOrRemoveCookie(this.analytics, VarmaCookies.Analytics);
                this.hadAnalytics = this.analytics;
            }

            if (this.marketing !== this.hadMarketing) {
                this.setOrRemoveCookie(this.marketing, VarmaCookies.Marketing);
                this.hadMarketing = this.marketing;
            }

            if (this.cookiesSavedMessage) {
                this.feedbackVisible = true;
            }

            if (this.updateGa) {
                GA.pushDataLayer({'event':'changeConsent'});
            }

            this.$emit('save');
        },
        executeScripts(): void {
            if (this.marketing) {
                if ((window as any).youtubeIframeApi) {
                    (window as any).youtubeIframeApi();
                }             
            }
        },
        setOrRemoveCookie(set: boolean, cookieName: string): void {
            if (set) {
                setSitewideBooleanCookie(cookieName);
            } else {
                removeSitewideBooleanCookie(cookieName);
            }
        }
    }
});

