import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02ff7f0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "varma-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_header_desktop = _resolveComponent("varma-header-desktop")!
  const _component_varma_header_mobile = _resolveComponent("varma-header-mobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.version == 'Desktop')
      ? (_openBlock(), _createBlock(_component_varma_header_desktop, {
          key: 0,
          model: _ctx.model
        }, null, 8, ["model"]))
      : _createCommentVNode("", true),
    (_ctx.version == 'Mobile')
      ? (_openBlock(), _createBlock(_component_varma_header_mobile, {
          key: 1,
          model: _ctx.model
        }, null, 8, ["model"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "alert", {}, undefined, true)
  ]))
}