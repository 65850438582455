
import { defineComponent } from 'vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';
import * as GA from '../../../utils/GA';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        modelValue: Boolean,
        visual: String,
        uppercase: Boolean,
        hasPopup: Boolean,
        modal: Boolean,
        href: { type: String, default: null },
        icon: { type: String, default: null },
        color: { type: String, default: null },
        text: { type: String, default: null },
        iconActive: { type: String, default: null },
        colorActive: { type: String, default: null },
        textActive: { type: String, default: null },
        colorHover: { type: String, default: null },
        iconWeight: { type: String, default: 'regular' },
        gaEventObj: { type: Object, default: null },
        screenreaderText: { type: String, default: null }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            hover: false
        };
    },
    mounted() {
        document.addEventListener('focusout', this.onfocusout);
        document.addEventListener('touchstart', this.ontouchstart);
    },
    computed: {
        cssClass(): string {
            return `item item--${this.visual} ${this.modelValue ? 'item--active' : ''} ${this.uppercase ? 'item--uppercase' : ''}`;
        },
        colorKey(): string {
            return (this.hover ? this.colorHover : null) || (this.modelValue ? this.colorActive : null) || this.color || 'black';
        },
        iconSize(): string {
            return this.visual == 'top' ? 'S' : 'M';
        }
    },
    methods: {
        toggle(): void {
            if(this.gaEventObj != null) {
                GA.pushDataLayer(this.gaEventObj);
            }
            this.$emit('update:modelValue', !this.modelValue);
        },
        onfocusout(ev: FocusEvent): void {
            if (this.modal) {
                if (!ev.relatedTarget || this.isElementOutside(ev.relatedTarget as HTMLElement)) {
                    this.$emit('update:modelValue', false);
                }
            }
        },
        ontouchstart(ev: TouchEvent): void {
            if (this.modal) {
                if (this.isElementOutside(ev.target as HTMLElement)) {
                    this.$emit('update:modelValue', false);
                }
            }
        },
        isElementOutside(element: Node): boolean {
            while (element !== null) {
                if (element == this.$refs.root) {
                    return false;
                }
                element = element.parentElement as Node;
            }

            return true;
        },
    }
});
