import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe5de992"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "card-search-options__filter-groups" }
const _hoisted_3 = {
  key: 0,
  class: "filter-group"
}
const _hoisted_4 = { class: "filter-group__label varma-label" }
const _hoisted_5 = { class: "filter-group__items" }
const _hoisted_6 = { class: "filter-group" }
const _hoisted_7 = { class: "filter-group__label varma-label" }
const _hoisted_8 = { class: "filter-group__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_chip = _resolveComponent("varma-chip")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock("section", {
    class: "card-search-options",
    "aria-label": _ctx.title
  }, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hideTypes)
        ? (_openBlock(), _createElementBlock("fieldset", _hoisted_3, [
            _createElementVNode("legend", _hoisted_4, _toDisplayString(_ctx.$t('/search/filterbytype')), 1),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeFilters, (f, index) => {
                return (_openBlock(), _createBlock(_component_varma_chip, {
                  key: index,
                  "model-value": _ctx.typeSelected(f.id),
                  role: "checkbox",
                  "onUpdate:modelValue": ($event: any) => (_ctx.changeType(f.id, $event))
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_varma_icon, {
                      name: "check",
                      size: "XS",
                      weight: "bold",
                      color: "white"
                    }, null, 512), [
                      [_vShow, _ctx.typeSelected(f.id)]
                    ]),
                    _createElementVNode("span", null, _toDisplayString(f.name), 1)
                  ]),
                  _: 2
                }, 1032, ["model-value", "onUpdate:modelValue"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("fieldset", _hoisted_6, [
        _createElementVNode("legend", _hoisted_7, _toDisplayString(_ctx.$t('/search/filterbykeyword')), 1),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagFilters, (f, index) => {
            return (_openBlock(), _createBlock(_component_varma_chip, {
              key: index,
              "model-value": _ctx.tagSelected(f.id),
              role: "checkbox",
              "onUpdate:modelValue": ($event: any) => (_ctx.changeTag(f.id, $event))
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_varma_icon, {
                  name: "check",
                  size: "XS",
                  weight: "bold",
                  color: "white"
                }, null, 512), [
                  [_vShow, _ctx.tagSelected(f.id)]
                ]),
                _createElementVNode("span", null, _toDisplayString(f.name), 1)
              ]),
              _: 2
            }, 1032, ["model-value", "onUpdate:modelValue"]))
          }), 128))
        ])
      ])
    ]),
    (_ctx.anyFiltersSelected)
      ? (_openBlock(), _createBlock(_component_varma_button, {
          key: 0,
          class: "card-search-options__clear-filters",
          ghost: "",
          small: "",
          onClick: _ctx.clearFilters
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('/search/clearfilters')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}