
import { defineComponent } from 'vue';
import VarmaIcon from '../../../components/common/icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        title: { type: String, required: true },
        id: { type: String, required: true }
    },
    data() {
        return {
            showAlert: false
        } as { 
            showAlert: boolean;
        };
    },
    mounted() {
        if (!localStorage[this.id]) {
            this.showAlert = true;
        }
    },
    methods: {
        closeAlert(): void {
            localStorage[this.id] = true;
            this.showAlert = false;
        }
    }
});
