import { SearchType, SortType } from './VarmaSearchApiService';

export interface SearchFilter {
    checked: boolean;
    key: string;
    labelKey: string;
    subtypes: Array<SearchFilter>;
    subtypesHeadingKey: string;
    value: SearchType;
    hasYearFilter: boolean;
}

export const PageTypeFilters = [
    { checked: false, labelKey: '/search/search-type/6', key: 'filter-content', value: SearchType.Content } as SearchFilter,
    { checked: false, labelKey: '/search/filter-news', key: 'filter-news', subtypesHeadingKey: '/search/filter-news-subtypes', hasYearFilter: true,
        subtypes: [
            { checked: false, labelKey: '/search/search-type/1', key: 'filter-news-news',  value: SearchType.News } as SearchFilter,
            { checked: false, labelKey: '/search/search-type/2', key: 'filter-news-articles', value: SearchType.Article } as SearchFilter,
            { checked: false, labelKey: '/search/search-type/4', key: 'filter-news-blog', value: SearchType.Blog } as SearchFilter,
            { checked: false, labelKey: '/search/search-type/5', key: 'filter-news-podcast', value: SearchType.Podcast } as SearchFilter
        ]
    } as SearchFilter,
    { checked: false, labelKey: '/search/search-type/3', key: 'filter-events', value: SearchType.Event } as SearchFilter
];

export const SortOptions = [
    { value: SortType.RelevanceScore, labelKey: '/search/sort-0' }, 
    { value: SortType.DateDesc, labelKey: '/search/sort-1' }, 
    { value: SortType.DateAsc, labelKey: '/search/sort-2' }
];

const earliestFilterYear = 2013;

export const GetYearFilters = (): Array<{ value: number; text: number }> => {
    const currentYear: number =  new Date().getFullYear();
    return Array.from({ length: currentYear - earliestFilterYear + 1 }, (_, i) =>  currentYear - i).map(year => { 
        return { 
            text: year, 
            value: year 
        }; 
    });
};