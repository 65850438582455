
import { defineComponent, PropType } from 'vue';
import { v4 as uuid } from 'uuid';

export interface SimpleOption {
    value: string;
    label: string;
}

export default defineComponent({
    props: {
        modelValue: { type: Object as PropType<SimpleOption>, default: null },
        options: { type: Array as PropType<SimpleOption[]>, required: true },
        dropdownLabel: { type: String, required: true },
        rules: {
            type: Array as PropType<{ (v: string): boolean | string }[]>,
            default: (): { (v: string): boolean | string }[] => []
        },
    },
    emits: ['update:modelValue'],
    data() {
        return { 
            id: uuid(),
            isOpen: false,
            filterValue: '',
            activeIndex: -1,
            error: false,
        } as {
            id: string,
            isOpen: boolean,
            filterValue: string,
            activeIndex: number,
            error: string | boolean,
        };
    },
    computed: {
        filteredOptions() {
            if (!this.filterValue) {
                return this.options;
            }
            return this.options.filter(option =>  option.label.toLowerCase().includes(this.filterValue.toLowerCase()));
        },
        describedBy() {
            return this.error ? this.id + '-error' : null;
        },
    },
    watch: {
        isOpen(next: boolean): void {
            if (!next) {
                this.activeIndex = -1;
            }
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!(this.$refs.combobox as HTMLElement).contains(event.target as HTMLElement)) {
                this.isOpen = false;
            }
        });

        document.addEventListener('keydown', (event) => {
            if (this.isOpen) {
                const key: string = event.key;
        
                if (key === 'Tab' || key ===  'Escape') { //tab or esc keys should close dropdown
                    this.isOpen = false;
                }
            }
        });
    },
    methods: {
        select(option: SimpleOption | null): void {
            this.filterValue = option ? option.label : this.filterValue;
            this.$emit('update:modelValue', option);
            this.isOpen = false;

            this.$nextTick(() => this.validate());
        },
        selectActive(): void {
            this.select(this.filteredOptions[this.activeIndex]);
        },
        scrollOption(event: KeyboardEvent, up: boolean): void {
            event.preventDefault();
            this.isOpen = true;

            //if no options to scroll, do nothing
            if (!this.filteredOptions || this.filteredOptions.length === 0 
                || event.altKey) { //scrolling on alt key pressed should only open option dropdown, not select anything
                return;
            }

            //if scrolling up from first option or down from last, focus text box
            if ((this.activeIndex === 0 && up) || (this.activeIndex === (this.filteredOptions.length - 1) && !up)) {
                this.activeIndex = -1;
                this.focus();
                return;
            }

            //else focus next option
            if (this.activeIndex < 0) {
                this.activeIndex = (up ? this.filteredOptions.length - 1 : 0);
            } else {
                if (up) {
                    this.activeIndex = this.activeIndex <= 0 ? this.filteredOptions.length - 1 : this.activeIndex - 1 ;
                } else {
                    this.activeIndex = this.activeIndex >= (this.filteredOptions.length - 1) ? 0 : this.activeIndex + 1 ;
                }
            }
            
            this.$nextTick(() => {
                (this.$refs.options as HTMLElement[])[this.activeIndex].focus();
            });
        },
        focusOption(option: SimpleOption): void {
            if (option) {
                (this.$refs['option-' + option.value] as HTMLElement).focus();
            }
        },
        validate(): string | boolean {
            let error: boolean | string = false;

            const selected = this.modelValue?.value;

            for (const rule of this.rules) {
                const result = rule(selected || '');

                if (result) {
                    error = result;
                    break;
                }
            }

            this.error = error;

            return error;
        },
        focus(): void {
            (this.$refs.comboboxInput as HTMLElement).focus();
        },
        update(): void {
            this.validate();
            this.$emit('update:modelValue', this.options.find(option => option.label === this.filterValue));
        },
        getOptionId(option: SimpleOption | undefined): string | null {
            if (!option) {
                return null;
            }
            return 'option-' + option.value;
        },
        handleOptionKeypress(event: KeyboardEvent): void {
            const key: string = event.key;
            
            if (key === 'End') { //end
                event.preventDefault();
                event.stopPropagation();
                const end: number = this.filterValue.length;
                (this.$refs.comboboxInput as HTMLInputElement).setSelectionRange(end, end);
                this.activeIndex = -1;
                this.focus();
            } else if (key === 'Home') { //home
                event.preventDefault();
                event.stopPropagation();
                (this.$refs.comboboxInput as HTMLInputElement).setSelectionRange(0, 0);
                this.activeIndex = -1;
                this.focus();
            } else if (key.length === 1) { // printable characters
                this.activeIndex = -1;
                this.focus();
            }
        },
        escOnInput(): void {
            if (this.isOpen) {
                this.isOpen = false;
            } else {
                this.filterValue = '';
            }
        },
    }
});
