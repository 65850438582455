import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45c52eff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-slider-cards" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  ref: "trackEl",
  class: "image-slider-cards__track"
}
const _hoisted_4 = ["aria-label", "aria-current", "onClick"]
const _hoisted_5 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["image-slider-cards__control", { 'image-slider-cards__control--right-divider': !_ctx.leftEdge }])
    }, [
      (!_ctx.leftEdge)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "image-slider-cards__button",
            "aria-label": _ctx.$t('/common/previous'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollRelativePage(-1)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "arrow-left-1",
              size: "XS",
              weight: "bold",
              color: "black",
              "vertical-align": "middle"
            })
          ], 8, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      ref: "innerEl",
      class: "image-slider-cards__inner",
      onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateEdges && _ctx.updateEdges(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, i) => {
          return (_openBlock(), _createElementBlock("button", {
            key: i,
            ref_for: true,
            ref: "thumbnails",
            class: _normalizeClass(["image-slider-cards__card", { 'image-slider-cards__track-active-item': i == _ctx.index }]),
            style: _normalizeStyle({ 'background-image': `url(${_ctx.getImageUrl(img.url)})`}),
            "aria-label": `${_ctx.$t('/common/showImage')} ${i + 1}/${_ctx.images.length}`,
            "aria-current": i == _ctx.index,
            onClick: ($event: any) => (_ctx.setIndex(i))
          }, null, 14, _hoisted_4))
        }), 128))
      ], 512)
    ], 544),
    _createElementVNode("div", {
      class: _normalizeClass(["image-slider-cards__control", { 'image-slider-cards__control--left-divider': !_ctx.rightEdge }])
    }, [
      (!_ctx.rightEdge)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "image-slider-cards__button",
            "aria-label": _ctx.$t('/common/next'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollRelativePage(1)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "arrow-right-1",
              size: "XS",
              weight: "bold",
              color: "black",
              "vertical-align": "middle"
            })
          ], 8, _hoisted_5))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}