
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: { type: String, default: '' },
        initOpen: Boolean
    },
    data: () => ({
        active: false
    }),
    mounted() {
        (this.$parent as any).addTab(this);
        
        if (this.initOpen) {
            this.active = true;
        }
    }
});
