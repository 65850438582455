
import { defineComponent, nextTick } from 'vue';
import { v4 as uuid } from 'uuid';

export default defineComponent({
    props: {
        id: {
            type: String,
            default: (): string => {
                return String().concat('a', uuid()).replace(/-/g, '');               
            }
        },
    },
    data() {
        return {
            clickVisible: false,
            visible: false,
            width: 0,
            offsetLeft: 0,
            useDelay: true,
            hoverTimeout: null,
            focusTimeout: null,
        } as {
            clickVisible: boolean;
            visible: boolean;
            width: number;
            offsetLeft: number;
            useDelay: boolean;
            hoverTimeout: number | null;
        };
    },
    computed: {
        arrowLeft(): number {
            return -this.offsetLeft + 7.5;
        },
        popupId(): string {
            return `${this.id}--popup`;
        }
    },
    mounted() {
        document.addEventListener('click', (ev: MouseEvent) => {
            if (ev.target !== this.$refs.button) {
                this.clickVisible = false;
            }
        });
    },
    methods: {
        toggle(): void {
            this.clickVisible = !this.clickVisible;
            this.update();
        },
        show(): void {
            this.visible = true;
            this.update();
        },
        async update(): Promise<void> {
            this.width = this.getWidth();
            this.offsetLeft = this.calcOffsetLeft();

            if (this.offsetLeft == 0) {
                await nextTick();
                this.offsetLeft = this.calcOffsetLeft();
            }
        },
        getWidth(): number {
            const tooltip = this.$refs.tooltip as HTMLElement;
            if (!tooltip) {
                return 0;
            }

            const parent = tooltip.parentElement as HTMLElement;
            return parent.getBoundingClientRect().width;

        },
        calcOffsetLeft(): number {
            const MARGIN = 8;
            const width = this.getWidth();

            const tooltip = this.$refs.tooltip as HTMLElement;
            if (!tooltip) {
                return 0;
            }

            const tooltipWidth = tooltip.getBoundingClientRect().width;
            const tooltipX = tooltip.getBoundingClientRect().x;
            const center = tooltipX + tooltipWidth / 2;
            const documentWidth = document.documentElement.clientWidth;

            if (center - width / 2 - MARGIN > 0
                && center + width / 2 + MARGIN < documentWidth) {
                // The popup can fit fully centered
                return -width / 2 + tooltipWidth / 2;
            }

            if (center < documentWidth / 2) {
                // Align to left as it's closer
                return -tooltipX + MARGIN;
            } else {
                // Align to right
                return documentWidth - tooltipX - width - MARGIN;
            }
        },
        disableDelay(): void {
            this.useDelay = false;
        },
        onmouseenter(): void {
            if (this.hoverTimeout) {
                clearTimeout(this.hoverTimeout);
                this.hoverTimeout = null;
            }

            this.hoverTimeout = setTimeout(() => {
                this.show();
            }, this.useDelay ? 500 : 0) as any as number;
        },
        onmouseleave(): void {
            if (this.hoverTimeout) {
                clearTimeout(this.hoverTimeout);
                this.hoverTimeout = null;
            }
            
            this.visible = false;
        },
        onblur(): void {
            this.visible = false;
            this.clickVisible = false;
        },
        onkeydown(ev: KeyboardEvent): void {
            if (ev.key == 'Escape') {
                this.visible = false;
                this.clickVisible = false;
            }
        }
    }
});
