
import { defineComponent, nextTick } from 'vue';
import axios from 'axios';
import { validateFormFields } from '../../common/forms/validators';

export default defineComponent({
    props: {
        closable: { type: Boolean, default: false },
        trapFocus: { type: Boolean, default: false },
    },
    emits: ['close', 'success'],
    data() {
        return {
            sent: false,
            loading: false
        };
    },
    async mounted(): Promise<void> {
        if (this.trapFocus) {
            window.addEventListener('focusin', this.focusinTrapHandler);
            await nextTick();
            this.focusFirst();
        }
    },
    unmounted() {
        if (this.trapFocus) {
            window.removeEventListener('focusin', this.focusinTrapHandler);
        }
    },
    methods: {
        focusinTrapHandler(ev: FocusEvent): void {
            const root = this.$refs.root as HTMLElement;
            let el: null | HTMLElement = ev.target as HTMLElement;

            while (el) {
                if (el == root) {
                    return;
                }

                el = el.parentElement;
            }

            this.focusFirst();

        },
        focusFirst(): void {
            ((this.$refs.root as HTMLElement).querySelector('input, textarea, button') as HTMLElement)?.focus();
        },
        async onSubmit(formFieldRefs: Record<string, any>, fieldsToValidate: string[], postData: {}, endpoint: string): Promise<void> {
            let error = validateFormFields(formFieldRefs, fieldsToValidate);

            if (!error) {
                this.loading = true;
                await axios.post(endpoint, postData);
                this.success();
            }
        },
        success(): void {
            this.sent = true;
            this.loading = false;
            this.$emit('success');
        },
        reset(): void {
            this.sent = false;
        }
    }
});
