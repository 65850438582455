import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cc8feb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skipToContent" }
const _hoisted_2 = { class: "skipToContent__anchor" }
const _hoisted_3 = ["id", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        id: _ctx.id,
        class: "varma-primary varma-button varma-button-base",
        href: _ctx.query.concat('#').concat(_ctx.jumpToId),
        tabindex: "0"
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('/common/skiptocontent')) + " ", 1),
        _createVNode(_component_varma_icon, {
          name: "last",
          size: "XS",
          weight: "bold",
          color: "#FFF"
        })
      ], 8, _hoisted_3)
    ])
  ]))
}