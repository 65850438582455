import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69c90a10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pension-increase-calculator" }
const _hoisted_2 = { class: "pension-increase-calculator__icon" }
const _hoisted_3 = { class: "pension-increase-calculator__title" }
const _hoisted_4 = { class: "pension-increase-calculator__intro" }
const _hoisted_5 = { class: "pension-increase-calculator__inputs" }
const _hoisted_6 = { class: "pension-increase-calculator__inputs-inner" }
const _hoisted_7 = { class: "pension-increase-calculator__button" }
const _hoisted_8 = { class: "pension-increase-calculator__result" }
const _hoisted_9 = { class: "pension-increase-calculator__resultHeading" }
const _hoisted_10 = { class: "pension-increase-calculator__resultText" }
const _hoisted_11 = { class: "pension-increase-calculator__resultText--value" }
const _hoisted_12 = { class: "pension-increase-calculator__resultText--spacing" }
const _hoisted_13 = {
  class: "screenreader-only",
  "aria-live": "polite"
}
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_varma_icon, {
        size: "L",
        color: "primary",
        weight: "light",
        name: "accounting-calculator",
        class: "pension-increase-calculator__icon"
      })
    ]),
    _createElementVNode("h3", _hoisted_3, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "intro", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_varma_input, {
          ref: "pension",
          modelValue: _ctx.form.pension,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.pension) = $event)),
          type: "text",
          required: "",
          rules: [_ctx.validateNumber],
          "label-text": _ctx.$t('/blocks/pensionincreasecalculator/pension')
        }, null, 8, ["modelValue", "rules", "label-text"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_varma_button, { onClick: _ctx.submit }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('/blocks/pensionincreasecalculator/calculate')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.$t('/blocks/pensionincreasecalculator/result')), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.hasResult ? _ctx.result : '-'), 1),
        _withDirectives(_createElementVNode("label", _hoisted_12, " ", 512), [
          [_vShow, _ctx.hasResult]
        ]),
        _withDirectives(_createElementVNode("label", { class: "pension-increase-calculator__resultText--unit" }, _toDisplayString(_ctx.$t('/unit/eurpermonth')), 513), [
          [_vShow, _ctx.hasResult]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (_ctx.hasResult)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('/blocks/pensionincreasecalculator/result')) + " " + _toDisplayString(_ctx.result) + " " + _toDisplayString(_ctx.$t('/unit/eurpermonth')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}