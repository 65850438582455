import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a20b5a98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "combobox__container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "combobox__help" }
const _hoisted_4 = ["id", "aria-expanded", "aria-controls", "aria-activedescendant", "aria-describedby"]
const _hoisted_5 = ["id", "aria-label", "aria-controls"]
const _hoisted_6 = ["id", "aria-label", "aria-expanded", "aria-controls"]
const _hoisted_7 = ["id", "aria-label"]
const _hoisted_8 = ["id", "aria-selected", "onClick"]
const _hoisted_9 = ["id"]
const _hoisted_10 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id + '-input',
      class: "combobox__label"
    }, [
      _renderSlot(_ctx.$slots, "label", {}, undefined, true)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "help", {}, undefined, true)
    ]),
    _createElementVNode("div", {
      ref: "combobox",
      class: _normalizeClass(["combobox", { 'combobox--open': _ctx.isOpen, 'combobox--error': _ctx.error }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["combobox__input", { 'combobox__input--error': _ctx.error }])
      }, [
        _withDirectives(_createElementVNode("input", {
          id: _ctx.id + '-input',
          ref: "comboboxInput",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterValue) = $event)),
          class: "combobox__input-field",
          type: "text",
          role: "combobox",
          "aria-autocomplete": "list",
          "aria-expanded": _ctx.isOpen,
          "aria-controls": _ctx.id + '-listbox',
          "aria-activedescendant": _ctx.getOptionId(_ctx.modelValue),
          "aria-describedby": _ctx.describedBy,
          onKeydown: [
            _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.scrollOption($event, false)), ["down"])),
            _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.scrollOption($event, true)), ["up"])),
            _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.selectActive()), ["enter"])),
            _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.escOnInput()), ["esc"]))
          ],
          onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isOpen = true)),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isOpen = true)),
          onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isOpen = true)),
          onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args)))
        }, null, 40, _hoisted_4), [
          [_vModelText, _ctx.filterValue]
        ]),
        (_ctx.filterValue)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: _ctx.id + '-button-reset',
              type: "button",
              "aria-label": _ctx.$t('/common/resetfield'),
              "aria-controls": _ctx.id + '-input',
              class: "combobox__reset",
              onClick: _cache[9] || (_cache[9] = ($event: any) => {_ctx.filterValue = ''; _ctx.select(null);})
            }, [
              _createVNode(_component_varma_icon, {
                size: "S",
                weight: "bold",
                name: "close",
                color: "black"
              })
            ], 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          id: _ctx.id + '-button-dropdown',
          type: "button",
          "aria-label": _ctx.dropdownLabel,
          "aria-expanded": _ctx.isOpen,
          "aria-controls": _ctx.id + '-listbox',
          class: "combobox__dropdown",
          tabindex: "-1",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
        }, [
          _createVNode(_component_varma_icon, {
            name: "expand",
            color: "black",
            size: "XS",
            weight: "bold",
            class: _normalizeClass({ 'combobox__dropdown--open': _ctx.isOpen })
          }, null, 8, ["class"])
        ], 8, _hoisted_6)
      ], 2),
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("ul", {
            id: _ctx.id + '-listbox',
            key: _ctx.filterValue,
            role: "listbox",
            "aria-label": _ctx.dropdownLabel,
            class: "combobox__options"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                id: _ctx.getOptionId(option),
                ref_for: true,
                ref: "options",
                key: option.value,
                role: "option",
                class: _normalizeClass(["combobox__option", { 'combobox__option--selected': option === _ctx.modelValue, 'combobox__option--active': _ctx.activeIndex === index }]),
                "aria-selected": _ctx.activeIndex === index,
                tabindex: "0",
                onClick: ($event: any) => (_ctx.select(option)),
                onKeydown: [
                  _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.scrollOption($event, false)), ["down"])),
                  _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.scrollOption($event, true)), ["up"])),
                  _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.selectActive()), ["enter"])),
                  _cache[14] || (_cache[14] = ($event: any) => (_ctx.handleOptionKeypress($event)))
                ]
              }, _toDisplayString(option.label), 43, _hoisted_8))
            }), 128)),
            (_ctx.filteredOptions.length === 0)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  id: _ctx.id + '-option-notfound',
                  ref: "noResultsOption",
                  class: "combobox__option",
                  role: "option",
                  tabindex: "-1"
                }, _toDisplayString(_ctx.$t('/common/noresults')), 9, _hoisted_9))
              : _createCommentVNode("", true)
          ], 8, _hoisted_7))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: _ctx.id + '-error',
          class: "combobox__error",
          role: "alert"
        }, _toDisplayString((typeof(_ctx.error) === 'boolean' ? _ctx.$t('/forms/error/requiredfield') : _ctx.error)), 9, _hoisted_10))
      : _createCommentVNode("", true)
  ]))
}