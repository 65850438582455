import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10cf56d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid-contain"
}
const _hoisted_2 = {
  key: 0,
  class: "controls-buttons"
}
const _hoisted_3 = ["aria-label", "aria-current", "onClick"]
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "card-slider-track-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "rootEl",
    class: _normalizeClass(["varma-card-slider", { 'mode-slider': _ctx.state.mode == 'Slider', 'mode-list': _ctx.state.mode == 'List' }])
  }, [
    (_ctx.isMounted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            ref: "fillRef",
            class: _normalizeClass(["card-slider-dash", [_ctx.headingClasses]])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass('title level-' + _ctx.titleLevel)
            }, [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true)
            ], 2),
            (_ctx.settings.pages[1] > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settings.pages.length, (i) => {
                    return (_openBlock(), _createElementBlock("button", {
                      key: i,
                      class: _normalizeClass([{ active: i == _ctx.state.page }, "page-button"]),
                      "aria-label": _ctx.$t('/common/showCard') + ' ' + i,
                      "aria-current": i == _ctx.state.page,
                      onClick: ($event: any) => (_ctx.setPage(i - 1))
                    }, null, 10, _hoisted_3))
                  }), 128)),
                  _createElementVNode("button", {
                    class: "relative-button",
                    "aria-label": _ctx.$t('/common/previous'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPageRelative(-1)))
                  }, [
                    _createVNode(_component_varma_icon, {
                      name: "arrow-left-1",
                      size: "S",
                      weight: "bold",
                      color: "black",
                      "vertical-align": "middle"
                    })
                  ], 8, _hoisted_4),
                  _createElementVNode("button", {
                    class: "relative-button",
                    "aria-label": _ctx.$t('/common/next'),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPageRelative(1)))
                  }, [
                    _createVNode(_component_varma_icon, {
                      name: "arrow-right-1",
                      size: "S",
                      weight: "bold",
                      color: "black",
                      "vertical-align": "middle"
                    })
                  ], 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass('controls-toggle-list level-' + _ctx.titleLevel)
            }, [
              _createElementVNode("button", {
                class: "controls-toggle-list__button",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleMode && _ctx.toggleMode(...args)))
              }, _toDisplayString(_ctx.state.mode == 'Slider' ? _ctx.$t('/common/show-more') : _ctx.$t('/common/show-less')), 1)
            ], 2)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "innerEl",
      class: _normalizeClass([{ 'scrollable': _ctx.settings.pages.length > 1 }, "card-slider-inner"]),
      onScroll: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onscroll && _ctx.onscroll(...args)))
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle({ width: _ctx.settings.fillWidth + 'px' }),
        class: "track-fill"
      }, null, 4),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", {
          ref: "trackEl",
          class: "card-slider-track",
          style: _normalizeStyle({ width: _ctx.state.mode == 'Slider' ? _ctx.settings.trackWidth + 'px' : null }),
          onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onmousedown && _ctx.onmousedown(...args))),
          onMousemove: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onmousemove && _ctx.onmousemove(...args))),
          onMouseup: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onmouseup && _ctx.onmouseup(...args))),
          onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onmouseup && _ctx.onmouseup(...args))),
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onclick && _ctx.onclick(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 36)
      ]),
      _createElementVNode("div", {
        style: _normalizeStyle({ width: _ctx.settings.fillWidth + 'px' }),
        class: "track-fill"
      }, null, 4)
    ], 34)
  ], 2))
}