import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1d577d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "root",
  class: "item-root"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.href ? 'a' : 'button'), {
      href: _ctx.href,
      class: _normalizeClass(_ctx.cssClass),
      "aria-haspopup": _ctx.hasPopup ? true : null,
      "aria-expanded": !_ctx.href ? _ctx.modelValue : null,
      "aria-label": _ctx.screenreaderText,
      onClick: _ctx.toggle,
      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
    }, {
      default: _withCtx(() => [
        (_ctx.hover && !_ctx.modelValue)
          ? (_openBlock(), _createBlock(_component_varma_icon, {
              key: 0,
              size: _ctx.iconSize,
              name: _ctx.icon,
              color: _ctx.colorHover || _ctx.color || 'black',
              weight: _ctx.iconWeight,
              "vertical-align": "middle"
            }, null, 8, ["size", "name", "color", "weight"]))
          : (_ctx.hover && _ctx.modelValue)
            ? (_openBlock(), _createBlock(_component_varma_icon, {
                key: 1,
                size: _ctx.iconSize,
                name: _ctx.iconActive || _ctx.icon,
                color: _ctx.colorHover || _ctx.colorActive || _ctx.color || 'black',
                weight: _ctx.iconWeight,
                "vertical-align": "middle"
              }, null, 8, ["size", "name", "color", "weight"]))
            : (!_ctx.hover && !_ctx.modelValue)
              ? (_openBlock(), _createBlock(_component_varma_icon, {
                  key: 2,
                  size: _ctx.iconSize,
                  name: _ctx.icon,
                  color: _ctx.color || 'black',
                  weight: _ctx.iconWeight,
                  "vertical-align": "middle"
                }, null, 8, ["size", "name", "color", "weight"]))
              : (!_ctx.hover && _ctx.modelValue)
                ? (_openBlock(), _createBlock(_component_varma_icon, {
                    key: 3,
                    size: _ctx.iconSize,
                    name: _ctx.iconActive || _ctx.icon,
                    color: _ctx.colorActive || _ctx.color || 'black',
                    weight: _ctx.iconWeight,
                    "vertical-align": "middle"
                  }, null, 8, ["size", "name", "color", "weight"]))
                : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass('text color-' + _ctx.colorKey)
        }, _toDisplayString((_ctx.modelValue && _ctx.textActive) ? _ctx.textActive : _ctx.text), 3),
        (_ctx.visual == 'top')
          ? (_openBlock(), _createBlock(_component_varma_icon, {
              key: 4,
              name: "arrow-down",
              size: "XS",
              weight: "bold",
              color: "black",
              "vertical-align": "middle",
              class: "item-popup-arrow"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["href", "class", "aria-haspopup", "aria-expanded", "aria-label", "onClick"])),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 512))
}