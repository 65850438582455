
import { defineComponent } from 'vue';

import VarmaCardSlider from '../card-slider/VarmaCardSlider.vue';
import VarmaDialog from '../../common/dialog/VarmaDialog.vue';

interface Item {
    title: string;
    imageUrlCard: string;
    imageUrlMain: string;
    dataArea: string;
    dataRent: string;
    dataFloors: string;
    expandText: string;
    linkUrl: string;
    linkText: string;
    linkTarget: string;
    description: string;
}

export default defineComponent({
    components: {
        VarmaCardSlider
    },
    props: {
        title: { type: String, required: true },
        itemsJson: { type: String, required: true },
    },
    data() {
        return {
            items: JSON.parse(this.itemsJson),
            activeItemIndex: null,
            lastClicked: null
        } as {
            items: Item[];
            activeItemIndex: number | null;
            lastClicked: HTMLInputElement | null;
        };
    },
    computed: {
        activeItem(): Item | null {
            if (this.activeItemIndex === null) {
                return null;
            }

            return this.items[this.activeItemIndex];
        },
        hasControls(): boolean {
            return this.items.length > 1;
        }
    },
    methods: {
        setIndexRelative(rel: number): void {
            let index = this.activeItemIndex! + rel;

            if (index < 0) {
                index = this.items.length - 1;
            }

            if (index >= this.items.length) {
                index = 0;
            }

            this.activeItemIndex = index;
        },
        setLastClicked(index: number): void {
            this.lastClicked = (this.$refs.productCard as HTMLInputElement[])[index];
        },
        closeDialog(): void {
            (this.$refs.dialog as typeof VarmaDialog).close();
        }
    }
});
