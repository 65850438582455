
import { defineComponent } from 'vue';
import VarmaFeedbackType from './VarmaFeedbackType';

export default defineComponent({
    props: {
        visible: { type: Boolean, default: false },
        timeout: { type: Boolean, default: false },
        toast: { type: Boolean, default: false },
        type: { type: Number, default: VarmaFeedbackType.Info },
        title: { type: String, default: '' }
    },
    emits: ['hidden'],
    data() {
        return {
            show: this.$props.visible,
            VarmaFeedbackType
        };
    },
    computed: {
        icon(): string {
            if (this.type === VarmaFeedbackType.Success) {
                return 'check-circle';
            }

            if (this.type === VarmaFeedbackType.Error) {
                return 'alert-triangle';
            } 

            return 'information-circle';
        },
        iconColor(): string {
            if (this.type === VarmaFeedbackType.Success) {
                return 'status-success';
            }

            if (this.type === VarmaFeedbackType.Error) {
                return 'status-error';
            } 

            return 'primary';
        }
    },
    watch: {
        visible(): void {
            this.show = this.visible;
            if (this.visible && this.timeout) {
                setTimeout(() => { 
                    this.show = false;
                    this.$emit('hidden');
                }, 2500);
            }
        }
    }
});
