
import { defineComponent, reactive, watch, ref } from 'vue';

import { validateFormFields } from '@/components/common/forms/validators';
import * as GA from '@/utils/GA';
import { language } from '@/utils/language';

export default defineComponent({
    props: {
        factor: { type: Number, default: 0 },
    },
    setup() {
        const form = reactive({
            pension: ''
        });
        
        const result = ref(null as null | string);

        watch(form,
            () => {
                result.value = null;
            },
            { deep: true });

        return {
            form,
            result
        };
    },
    computed: {
        hasResult(): boolean {
            return this.result !== null;
        }
    },
    methods: {
        validateNumber(value: string): boolean | string {
            if (!value) {
                return this.$t('/blocks/pensionincreasecalculator/inputerror');
            }

            if (!Number.isFinite(Number(value.replace(',','.')))) {
                return this.$t('/blocks/pensionincreasecalculator/inputerror');
            }

            return false;
        },
        async submit(): Promise<void> {
            if (validateFormFields(this.$refs, Object.keys(this.form))) {
                return;
            }

            GA.pushDataLayer({'name':'PensionIncreaseCalculator', 'action':'calculate', 'event': 'cCalculator' });

            const pension = parseFloat(this.form.pension.replace(',', '.'));
            const result = Math.round(pension * this.factor * 100) / 100.0;
            this.result = result.toLocaleString(language);
        },
    }
});

