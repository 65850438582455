import axios from 'axios';
import { SearchResult } from './VarmaSearchResponseModel';
import SearchRequest from './VarmaSearchRequestModel';

export class VarmaSearchApiService {
    private RESOURCE_URL = '/api/search/content';

    public async searchContent(req: SearchRequest): Promise<SearchResult> {
        return new Promise((resolve, reject) => {
            axios
                .get<SearchResult>(`${this.RESOURCE_URL}`, {params: req})
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }
}

export enum SortType
{
    RelevanceScore = 0,
    DateDesc = 1,
    DateAsc = 2,
    SortDateDesc = 3,
    SortDateAsc = 4
}

export enum SearchType
{
    News = 1,
    Article = 2,
    Event = 3,
    Blog = 4,
    Podcast = 5,
    Content = 6,
    Tag = 7,
    Image = 8,
    File = 9
}