import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { ref: "slotContainer" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ], 512),
    _createElementVNode("div", {
      id: _ctx.anchorId,
      class: "linkable-anchor"
    }, null, 8, _hoisted_2),
    (_ctx.linkVisible && _ctx.slotContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("a", {
            href: _ctx.getAnchorLink()
          }, "Linkki tähän", 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}