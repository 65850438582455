import { renderSlot as _renderSlot, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4326f8cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-hidden", "disabled", "aria-checked", "role", "aria-describedby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    "aria-hidden": _ctx.hideFromScreenReader,
    class: _normalizeClass(["varma-chip varma-primary varma-standard-chip varma-chip-selectable", {
            'varma-chip-selected': _ctx.modelValue,
        }]),
    disabled: _ctx.disabled,
    "aria-checked": _ctx.modelValue,
    role: _ctx.role,
    "aria-describedby": _ctx.ariaDescribedby,
    tabindex: "0",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
    onKeydown: [
      _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["enter"])),
      _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]), ["space"]))
    ]
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 42, _hoisted_1))
}