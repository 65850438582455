import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ab53db5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["action"]
const _hoisted_2 = ["placeholder", "aria-label"]
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("form", {
    action: _ctx.searchUrl,
    method: "get",
    class: _normalizeClass(["search", { 'search--grow': _ctx.grow }])
  }, [
    _createElementVNode("input", {
      ref: "input",
      type: "text",
      name: "q",
      placeholder: _ctx.$t('/search/search-placeholder'),
      "aria-label": _ctx.$t('/search/search-placeholder')
    }, null, 8, _hoisted_2),
    _createElementVNode("button", {
      type: "submit",
      "aria-label": _ctx.$t('/search/search-placeholder')
    }, [
      _createVNode(_component_varma_icon, {
        name: "search",
        weight: "regular",
        size: "S",
        color: "ui-darkest"
      })
    ], 8, _hoisted_3)
  ], 10, _hoisted_1))
}