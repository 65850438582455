
import { defineComponent } from 'vue';

import VarmaCardSlider from '../card-slider/VarmaCardSlider.vue';
import VarmaVideo from '../video-block/VarmaVideo.vue';

interface Item {
    title: string;
    id: string;
    iframeSrc: string;
    previewImageUrl: string;
    description: string;
}

export default defineComponent({
    components: {
        VarmaCardSlider,
        VarmaVideo
    },
    props: {
        manageCookiesUrl: { type: String, required: true },
        itemsJson: { type: String, required: true },
    },
    data() {
        return {
            items: JSON.parse(this.itemsJson),
            activeItemIndex: 0,
        } as {
            items: Item[];
            activeItemIndex: number;
        };
    },
    computed: {
        activeItem(): Item | null {
            return this.items[this.activeItemIndex];
        },
    }
});
