
import { defineComponent } from 'vue';
import VarmaIcon from '../../../components/common/icons/VarmaIcon.vue';
import { VarmaCookies, hasCookie, setSitewideBooleanCookie } from './VarmaCookies';

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        titleText: { type: String, required: true },
        linkText: { type: String, required: true },
        manageUrl: { type: String, required: true }
    },
    emits: ['accepted'],
    mounted() {
        if (hasCookie(VarmaCookies.Marketing)) {
            this.$emit('accepted');
        } else {
            this.checkTimeout();
        }
    },
    methods: {
        accept(): void {
            setSitewideBooleanCookie(VarmaCookies.Marketing);

            if ((window as any).youtubeIframeApi) {
                (window as any).youtubeIframeApi();
            }

            this.$emit('accepted');
        },
        checkTimeout(): void {
            setTimeout(() => {                                                             
                if (hasCookie(VarmaCookies.Marketing)) {           
                    this.$emit('accepted');
                    return;
                }

                this.checkTimeout();
            }, 1000);
        }
    }
});
