
import { defineComponent } from 'vue';
import VarmaVerticalCollapse from '@/components/common/accordions/varma-vertical-collapse/VarmaVerticalCollapse.vue';
import { language } from '@/utils/language';

interface Result {
    calculation: CalculationResult;
    distribution: ResultDistribution;
    options: ResultOption[];

    chartValues: CalculationResult[];
}

interface CalculationResult {
    absenceFactor: number;
    annualTotalDays: number;
    annualDaysPerPerson: number;

    personDaySalary: number;
    personDayDirect: number;
    personDayIndirect: number;
    personDayTotal: number;

    annualSalary: number;
    annualDirect: number;
    annualIndirect: number;
    annualTotal: number;
}

interface ResultDistribution {
    indirect: number;
    direct: number;
    salary: number;
}

interface ResultOption {
    reductionPercentage: number;
    annualCostSavings: number;
    calculation: CalculationResult;
}

const DIRECT_FACTOR = 0.3;
const INDIRECT_FACTOR = 1.3;
const ANNUAL_WORKDAYS = 226;

export default defineComponent({    
    components: {
        VarmaVerticalCollapse
    },
    props: {
        defaultPersonCount: { type: String, default: '' },
        defaultAverageGrossSalary: { type: String, default: '' },
        defaultAnnualAbsencePercentage: { type: String, default: '' },
        defaultAnnualAbsenceDays: { type: String, default: '' },
    },
    data() {
        return {
            personCount: '200',
            averageGrossSalary: '3000',
            annualAbsencePercentage: null,
            annualAbsenceDays: null,
            result: null
        } as {
            personCount: null | string;
            averageGrossSalary: null | string;
            annualAbsencePercentage: null | string;
            annualAbsenceDays: null | string;
            result: null | Result;
        };
    },
    computed: {
        chartOptions(): any {
            if (!this.result) {
                return null;
            }

            return this.generateChartOptions(this.result.chartValues);
        }
    },
    mounted() {
        this.$watch(
            () => this.annualAbsencePercentage,
            (v: string) => {
                if (v) {
                    this.annualAbsenceDays = null;
                }
            }
        );

        this.$watch(
            () => this.annualAbsenceDays,
            (v: string) => {
                if (v) {
                    this.annualAbsencePercentage = null;
                }
            }
        );

        this.personCount = this.defaultPersonCount;
        this.averageGrossSalary = this.defaultAverageGrossSalary;
        this.annualAbsencePercentage = this.defaultAnnualAbsencePercentage;
        this.annualAbsenceDays = this.defaultAnnualAbsenceDays;

        if (this.personCount && this.averageGrossSalary && (this.annualAbsencePercentage || this.annualAbsenceDays)) {
            this.calculate();
        }
    },
    methods: {
        validateField(v: string): boolean | string {
            return !v
                ? this.$t('/forms/error/requiredfield')
                : false;
        },
        validateNumber(v: string): boolean | string {
            if (!v) {
                return false;
            }

            if (!Number.isFinite(Number(v.replace(',','.')))) {
                return this.$t('/forms/error/invalidnumber');
            }

            return false;
        },
        formatNumber(num: number, decimals: number): string {
            return (Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)).toLocaleString(language);
        },
        calculateResult(absenceFactor: number, personCount: number, averageGrossSalary: number): CalculationResult {
            const annualDaysPerPerson = absenceFactor * ANNUAL_WORKDAYS;
            const annualTotalDays = annualDaysPerPerson * personCount;

            const personDaySalary = averageGrossSalary * 12 / ANNUAL_WORKDAYS;
            const personDayDirect = personDaySalary * DIRECT_FACTOR;
            const personDayIndirect = (personDaySalary + personDayDirect) * INDIRECT_FACTOR;
            const personDayTotal = personDaySalary + personDayDirect + personDayIndirect;

            const annualSalary = personDaySalary * annualTotalDays;
            const annualDirect = personDayDirect * annualTotalDays;
            const annualIndirect = personDayIndirect * annualTotalDays;
            const annualTotal = personDayTotal * annualTotalDays;
        
            return {
                absenceFactor,
                annualTotalDays,
                annualDaysPerPerson,

                personDaySalary,
                personDayDirect,
                personDayIndirect,
                personDayTotal,
                
                annualSalary,
                annualDirect,
                annualIndirect,
                annualTotal,
            };
        },
        calculate(): void {
            let error = false;
            for (const key of ['personCount', 'averageGrossSalary', 'annualAbsencePercentage', 'annualAbsenceDays']) {
                error = (this.$refs[key] as any).validate() || error;
            }

            if (error) {
                return;
            }

            const inPersonCount = parseFloat((this.personCount || '').replace(',', '.'));
            const inAverageGrossSalary = parseFloat((this.averageGrossSalary || '').replace(',', '.'));
            const inAnnualAbsencePercentage = parseFloat((this.annualAbsencePercentage || '').replace(',', '.'));
            const inAnnualAbsenceDays = parseFloat((this.annualAbsenceDays || '').replace(',', '.'));

            if (!inAnnualAbsencePercentage && !inAnnualAbsenceDays) {
                return;
            }

            const absenceFactor = inAnnualAbsenceDays
                ? inAnnualAbsenceDays / (inPersonCount * ANNUAL_WORKDAYS)
                : inAnnualAbsencePercentage / 100;

            const mainCalculation = this.calculateResult(absenceFactor, inPersonCount, inAverageGrossSalary);

            const distribution = {
                indirect: mainCalculation.annualIndirect / mainCalculation.annualTotal * 100,
                direct: mainCalculation.annualDirect / mainCalculation.annualTotal * 100,
                salary: mainCalculation.annualSalary / mainCalculation.annualTotal * 100,
            };

            const options = [0.1, 0.2, 0.3].map(r => {
                const calculation = this.calculateResult(absenceFactor * (1 - r), inPersonCount, inAverageGrossSalary);

                return {
                    reductionPercentage: r * 100,
                    annualCostSavings: mainCalculation.annualTotal - calculation.annualTotal,
                    calculation
                };
            });

            const chartValues: CalculationResult[] = [];
            for (let i = 0; i < 160; ++i) {
                chartValues.push(this.calculateResult(i / 10 / 100, inPersonCount, inAverageGrossSalary));
            }

            this.result = {
                calculation: mainCalculation,
                distribution,
                options,
                chartValues
            };
        },
        generateChartOptions(chartValues: CalculationResult[]): any {
            const formatNumber = this.formatNumber;
            const annualAbsenceCount = this.$t('/blocks/absencecalculator/annualabsencecount');

            return {
                chart: {
                    type: 'area',
                    spacingTop: 10
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: chartValues.map(v => v.absenceFactor * 100),
                    tickmarkPlacement: 'on',
                    minTickInterval: 10,
                    title: {
                        text: this.$t('/blocks/absencecalculator/absencepercentage')
                    },
                    labels: {
                        formatter: function (): string {
                            return formatNumber((this as any).value, 0) + '%';
                        },
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: this.$t('/blocks/absencecalculator/annualtotalabsencecost')
                    },
                    labels: {
                        formatter: function (): string {
                            return formatNumber((this as any).value, 0) + ' €';
                        },
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
                tooltip: {
                    shared: true,
                    formatter: function(): string {
                        let r = `<span style="font-size: 12px;font-weight:700;">${annualAbsenceCount} ${formatNumber((this as any).points[0].key, 1)} %</span><br/>`;

                        for (const point of (this as any).points) {
                            r += `<span style="color:${point.series.color};font-size: 20px;">\u25CF</span> ${point.series.name} <b>${formatNumber(point.y, 0)} €</b><br/>`;
                        }

                        return r;
                    },
                    positioner: (): { x: number; y: number } => ({ x: 80, y: 0 })
                },
                legend: {
                    itemStyle: {
                        fontSize: '14px'
                    },
                    symbolHeight: 12,
                    symbolWidth: 12,
                    symbolRadius: 6
                },
                plotOptions: {
                    series: {
                        fillOpacity: 1
                    },
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 0,
                        marker: {
                            lineWidth: 1,
                            lineColor: '#ddd',
                            symbol: 'circle'
                        }
                    }
                },
                series: [{
                    name: this.$t('/blocks/absencecalculator/indirectcosts'),
                    color: '#c1d3e8',
                    data: chartValues.map(v => ({
                        y: Math.round(v.annualIndirect),
                        marker: { enabled: false }
                    }))
                }, {
                    name: this.$t('/blocks/absencecalculator/directcosts'),
                    color: '#094fa3',
                    data: chartValues.map(v => ({
                        y: Math.round(v.annualDirect),
                        marker: { enabled: false }
                    }))
                }, {
                    name: this.$t('/blocks/absencecalculator/salarycosts'),
                    color: '#052b5a',
                    data: chartValues.map(v => ({
                        y: Math.round(v.annualSalary),
                        marker: { enabled: false }
                    }))
                }], 
            };
        }
    }
});

