import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53b4fef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topic-navi" }
const _hoisted_2 = { class: "grid-contain topic-navi__content" }
const _hoisted_3 = { class: "menu-group menu-group--main" }
const _hoisted_4 = { class: "menu-group__items" }
const _hoisted_5 = { class: "menu-group__item" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "menu-group__title" }
const _hoisted_8 = { class: "menu-group__items" }
const _hoisted_9 = ["href", "target"]
const _hoisted_10 = {
  key: 0,
  class: "menu-group"
}
const _hoisted_11 = { class: "menu-group__items" }
const _hoisted_12 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: _ctx.topic.url,
              class: "vafi-button vafi-ghost topic-navi__main-button"
            }, [
              _createVNode(_component_varma_icon, {
                name: "house",
                size: "S",
                weight: "regular",
                "vertical-align": "middle"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.topic.title), 1)
            ], 8, _hoisted_6)
          ])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topic.categories, (section) => {
        return (_openBlock(), _createElementBlock("section", {
          key: section.title,
          class: "menu-group"
        }, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(section.title), 1),
          _createElementVNode("nav", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.items, (item, itemIndex) => {
              return (_openBlock(), _createElementBlock("a", {
                key: itemIndex,
                href: item.url,
                target: item.targetFrame,
                class: "menu-group__item"
              }, [
                _createElementVNode("span", null, _toDisplayString(item.name), 1),
                _createVNode(_component_varma_icon, {
                  name: "arrow-right",
                  size: "XS",
                  weight: "bold",
                  color: "black",
                  "vertical-align": "middle"
                })
              ], 8, _hoisted_9))
            }), 128))
          ])
        ]))
      }), 128)),
      (_ctx.topic.topicLinks && _ctx.topic.topicLinks.length)
        ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
            _createElementVNode("nav", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topic.topicLinks, (item) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: item.name,
                  href: item.url,
                  target: item.targetFrame,
                  class: "menu-group__item menu-group__item--link"
                }, [
                  _createElementVNode("span", null, _toDisplayString(item.name), 1),
                  _createVNode(_component_varma_icon, {
                    name: "arrow-right",
                    size: "XS",
                    weight: "bold",
                    color: "brand",
                    "vertical-align": "middle"
                  })
                ], 8, _hoisted_12))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}