
import { defineComponent, reactive, ref } from 'vue';
import axios from 'axios';
import { validateField, validateEmail, validatePostalCode, validateFormFields } from '../../common/forms/validators';
import VarmaFormAuth from '../../common/auth/VarmaFormAuth.vue';

export default defineComponent({
    components: {
        VarmaFormAuth
    },
    props: {
        roleOptions: { type: String, default: '' },
        isGdprAdmin: Boolean,
        identity: { type: Object, default: null }
    },
    setup(props) {
        const roleOptions: string[] = JSON.parse(props.roleOptions);
        
        const state = ref('Start' as ('Start' | 'Form' | 'Confirm' | 'Sending' | 'Sent'));
        if (props.identity) {
            state.value = 'Form';
        }

        return {
            state: state,
            roles: roleOptions.map(r => ({ value: r, text: r })),
            form: reactive({
                email: '',
                emailAgain: '',
                streetAddress: '',
                postalCode: '',
                city: '',
                role: ''
            })
        };
    },
    computed: {
        sending(): boolean {
            return this.state == 'Sending';
        }
    },
    methods: {
        validateField(v: string): boolean | string {
            return validateField(v);
        },
        validateEmail(v: string): boolean | string {
            return validateEmail(v);
        },
        validateEmailAgain(v: string): boolean | string {
            if (!v) {
                return false;
            }

            if (v != this.form.email) {
                return this.$t('/forms/error/emailagainerror');
            }

            return false;
        },
        validatePostalCode(v: string): boolean | string {
            return validatePostalCode(v);
        },
        async reset(): Promise<void> {
            await axios.post('/api/forms/auth/sign-out');
            this.state = 'Start';
        },        
        next(): void {
            let error = validateFormFields(this.$refs, Object.keys(this.form));

            if (error) {
                return;
            }

            this.state = 'Confirm';
        },
        editForm(): void {
            this.state = 'Form';
        },
        async submit(): Promise<void> {
            this.state = 'Sending';
            await axios.post('/api/forms/gdpr/', this.form);
            this.state = 'Sent';
        }
    }
});

