import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-691161d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quiz" }
const _hoisted_2 = { class: "quiz__intro" }
const _hoisted_3 = { class: "quiz__icon" }
const _hoisted_4 = { class: "quiz__heading" }
const _hoisted_5 = { class: "quiz__introduction" }
const _hoisted_6 = ["aria-labelledby"]
const _hoisted_7 = {
  ref: "focusOnChange",
  align: "center",
  tabindex: "0",
  class: "quiz__question quiz__focus-on-changes"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = {
  ref: "firstFocus",
  class: "quiz__focuselement",
  "aria-hidden": "true",
  tabindex: "-1"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "quiz__radio" }
const _hoisted_12 = ["for"]
const _hoisted_13 = ["id", "name", "value"]
const _hoisted_14 = { class: "quiz__actions" }
const _hoisted_15 = { class: "quiz__actions--back quiz__actions--button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_heading = _resolveComponent("varma-heading")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_varma_icon, {
          size: "L",
          color: "primary",
          name: "question-circle",
          weight: "light"
        })
      ]),
      _createVNode(_component_varma_heading, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.model.quizTitle), 1)
        ]),
        _: 1
      }),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.model.quizIntro), 1),
      _withDirectives(_createVNode(_component_varma_button, {
        class: "quiz__start",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.startQuiz();})
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('/blocks/quiz/startbutton')), 1)
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.showStartButton]
      ])
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "quiz__details",
      role: "radiogroup",
      "aria-labelledby": _ctx.groupId
    }, [
      _createElementVNode("p", _hoisted_7, [
        _createElementVNode("span", {
          id: _ctx.groupId,
          class: _normalizeClass(["quiz__question--text", { 'quiz__question--bold': _ctx.IsQuestion() }])
        }, _toDisplayString(_ctx.currentGroup.text), 11, _hoisted_8)
      ], 512),
      _createElementVNode("span", _hoisted_9, null, 512),
      _withDirectives(_createElementVNode("a", {
        class: "quiz__question--link",
        href: _ctx.currentGroup.link
      }, _toDisplayString(_ctx.currentGroup.linkText), 9, _hoisted_10), [
        [_vShow, !_ctx.IsQuestion() && _ctx.currentGroup.linkText && _ctx.currentGroup.link]
      ]),
      _withDirectives(_createElementVNode("span", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentGroup.option, (option, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            _createElementVNode("label", {
              class: _normalizeClass(["quiz__radio--label", { 'quiz__radio--selected': option.text === _ctx.checkedOption }]),
              for: option.id
            }, [
              _createTextVNode(_toDisplayString(option.text) + " ", 1),
              _withDirectives(_createElementVNode("input", {
                id: option.id,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedOption) = $event)),
                class: "quiz__radio--input",
                type: "radio",
                name: _ctx.model.radioName,
                value: option.text,
                role: "radio"
              }, null, 8, _hoisted_13), [
                [_vModelRadio, _ctx.checkedOption]
              ])
            ], 10, _hoisted_12)
          ]))
        }), 128))
      ], 512), [
        [_vShow, _ctx.currentGroup.option.length > 0]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "quiz__radio--error",
        "aria-live": "assertive"
      }, _toDisplayString(_ctx.errortext), 513), [
        [_vShow, _ctx.errortext.length > 0]
      ])
    ], 8, _hoisted_6), [
      [_vShow, _ctx.start]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_14, [
      _withDirectives(_createVNode(_component_varma_button, {
        class: "quiz__actions--button",
        onClick: _ctx.nextQuiz
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('/blocks/quiz/nextbutton')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]), [
        [_vShow, _ctx.showNextButton]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_15, [
        _createVNode(_component_varma_button, {
          class: "quiz__actions--button",
          "text-only": "",
          onClick: _ctx.previousQuiz
        }, {
          default: _withCtx(() => [
            _createVNode(_component_varma_icon, {
              "vertical-align": "middle",
              size: "S",
              name: "arrow-left",
              weight: "regular"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('/blocks/quiz/previousbutton')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ], 512), [
        [_vShow, _ctx.showPreviousButton]
      ]),
      _withDirectives(_createVNode(_component_varma_button, {
        class: "quiz__actions--button",
        "text-only": "",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToStart()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('/blocks/quiz/startoverbutton')), 1)
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.showStartOverButton]
      ])
    ], 512), [
      [_vShow, _ctx.start]
    ])
  ]))
}