
import { defineComponent } from 'vue';
import axios from 'axios';

import { validateField } from '@/components/common/forms/validators';
import * as GA from '@/utils/GA';

type Step = 'Start' | 'Form' | 'Done';

export default defineComponent({
    props: {
        title: { type: String, required: true },
    },
    data() {
        return {
            step: 'Start' as Step,
            email: '',
            body: '',
            submiting: false,
        };
    },
    methods: {
        validateField,
        selectYes(): void {
            GA.pushDataLayer({
                'event': 'feedback_button',
                'eventDetails': 'yes',
            });

            this.step = 'Done';
        },
        selectNo(): void {
            GA.pushDataLayer({
                'event': 'feedback_button',
                'eventDetails': 'no',
            });

            this.step = 'Form';
            this.$nextTick(() => {
                (this.$refs['body'] as any).focus();
            });
        },
        async submitForm(): Promise<void> {
            if ((this.$refs['body'] as any).validate()) {
                return;
            }

            this.submiting = true;

            GA.pushDataLayer({
                'event': 'feedback_button',
                'eventDetails': 'comment',
            });

            await axios.post('/api/forms/react/', {
                url: window.location.href,
                email: this.email,
                body: this.body,
            });

            this.step = 'Done';
        }
    }
});
