
import { defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
    props: {
        allowDismiss: { type: Boolean, default: true },
        show: { type: Boolean, required: true, default: false },
        openDialogButton: { type: HTMLElement, default: null },
        trapFocus: { type: Boolean, default: true }
    },
    emits: ['onClose'],
    setup() {
        const dialogElement = ref();
        return {
            dialogElement
        };
    },
    data() {
        return {
            innerShow: (this.show ? true : false)
        } as 
        {
            innerShow: boolean;
        };
    },
    watch: {
        show(nextShowValue: boolean): void {
            this.innerShow = nextShowValue;
            if (!this.innerShow) {
                this.onClose();
            }
            this.handleBodyScroll();
        }
    },
    mounted() {
        this.handleBodyScroll();
    },
    methods: {
        handleBodyScroll(): void {
            if (!this.dialogElement) {
                return;
            }

            if (this.innerShow) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
        close(): void {
            this.innerShow = false;
            this.onClose();
        },
        async onClose(): Promise<void> {
            this.$emit('onClose');
            document.body.style.overflow = 'auto';

            if (this.openDialogButton) {
                await nextTick();
                (this.openDialogButton as HTMLElement).focus();
            }
        }
    }
});
