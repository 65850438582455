import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-842bd586"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "tooltip",
  class: "tooltip"
}
const _hoisted_2 = ["aria-label", "aria-describedby"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      ref: "button",
      class: "tooltip-button",
      "aria-label": _ctx.visible || _ctx.clickVisible ? _ctx.$t('/common/tooltiphide') : _ctx.$t('/common/tooltipshow'),
      "aria-describedby": _ctx.popupId,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
      onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.disableDelay && _ctx.disableDelay(...args))),
      onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onmouseenter && _ctx.onmouseenter(...args))),
      onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onmouseleave && _ctx.onmouseleave(...args))),
      onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onblur && _ctx.onblur(...args))),
      onKeydown: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onkeydown && _ctx.onkeydown(...args)))
    }, [
      _createVNode(_component_varma_icon, {
        size: "XS",
        weight: "bold",
        name: "question-circle"
      })
    ], 40, _hoisted_2),
    _createElementVNode("div", {
      id: _ctx.popupId,
      ref: "popup",
      role: "tooltip",
      class: _normalizeClass([{ visible: _ctx.visible || _ctx.clickVisible }, "tooltip-popup"]),
      style: _normalizeStyle({ 'width': _ctx.width + 'px', 'margin-left': _ctx.offsetLeft + 'px' })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", {
        class: "arrow-before",
        style: _normalizeStyle({ 'left': _ctx.arrowLeft + 'px' })
      }, null, 4),
      _createElementVNode("div", {
        class: "arrow-after",
        style: _normalizeStyle({ 'left': _ctx.arrowLeft + 'px' })
      }, null, 4)
    ], 14, _hoisted_3)
  ], 512))
}