
import { defineComponent, PropType } from 'vue';
import axios from 'axios';
import VarmaPremisesLocationCard from '@/components/common/card/VarmaPremisesLocationCard.vue';
import { VarmaPremisesLocationCardModel } from '@/components/common/card/VarmaCardModel';

interface RentalAreaType {
    key: string;
    text: string;
}

interface Locality {
    localityId: number;
    text: string;
}

interface Service {
    serviceId: number;
    text: string;
}

interface Items {
    results: VarmaPremisesLocationCardModel[];
    related: VarmaPremisesLocationCardModel[];
}

export default defineComponent({
    components: {
        VarmaPremisesLocationCard
    },
    props: {
        title: { type: String, required: true },
        intro: { type: String },
        contactUrl: { type: String, required: true },
        types: { type: Array as PropType<Array<RentalAreaType>>, required: true },
        localities: { type: Array as PropType<Array<{ locality: Locality; selected: boolean; }>>, required: true },
        services: { type: Array as PropType<Array<{ service: Service; selected: boolean; }>>, required: true },
        hideLocalities: { type: Boolean, default: false },
        initItems: { type: Object as PropType<Items>, required: true }
    },
    data() {
        return {
            model: {
                types: this.types.map(t => ({
                    type: t,
                    selected: false
                })),
                localities: this.localities.map(l => ({
                    locality: l.locality,
                    selected: l.selected
                })),
                services: this.services.map(s => ({
                    service: s.service,
                    selected: s.selected
                })),
                minArea: null,
                maxArea: null
            },
            cancel: null,
            areaError: false,
            items: null,
            resultsTitle: null,
            extraOptions: this.services.some(s => s.selected),
        } as {
            model: {
                types: { type: RentalAreaType; selected: boolean; }[];
                localities: { locality: Locality; selected: boolean; }[];
                services: { service: Service; selected: boolean; }[];
                minArea: null | string;
                maxArea: null | string;
            };
            cancel: any;
            areaError: boolean;
            items: null | Items;
            resultsTitle: null | string;
            extraOptions: boolean;
        };
    },
    computed: {
        anyOptions(): boolean {
            return this.model.types.find(t => t.selected) != null ||
                this.model.localities.find(t => t.selected) != null ||
                this.model.minArea != null ||
                this.model.maxArea != null;
        },
        areaRange(): [number, number] {
            return [
                parseInt(this.model.minArea || '0', 10) || 0,
                parseInt(this.model.maxArea || '0', 10) || 0
            ];
        }
    },
    mounted(): void {
        this.$watch(() => this.model, this.updateQuery, { deep: true });
        this.$watch(() => this.model, this.search, { deep: true });

        this.items = this.initItems;

        this.applyUrlParams(new URLSearchParams(window.location.search));
    },
    methods: {
        validateNumber(v: string): boolean | string {
            if (!v) {
                return false;
            }

            if (!Number.isFinite(Number(v.replace(',','.')))) {
                return this.$t('/forms/error/invalidnumber');
            }

            return false;
        },
        validateAreas(): boolean {
            const areas = this.areaRange;

            const result = areas[0] > 0 && areas[1] > 0 && areas[0] > areas[1];
            this.areaError = result;

            return result;
        },
        applyUrlParams(params: URLSearchParams): void {
            const model = this.model;

            const types = (params.get('type') || '').split(',');
            for (const type of model.types) {
                type.selected = types.includes(type.type.key);
            }

            if (!this.hideLocalities) {
                const localities = (params.get('localityid') || '')
                    .split(',')
                    .map(id => parseInt(id, 10));                
                for (const locality of model.localities) {
                    locality.selected = localities.includes(locality.locality.localityId);
                }
            }

            const services = (params.get('serviceid') || '')
                .split(',')
                .map(id => parseInt(id, 10));
            for (const service of model.services) {
                service.selected = services.includes(service.service.serviceId);
            }

            model.minArea = params.get('minarea');
            model.maxArea = params.get('maxarea');
        },
        updateQuery(): void {
            const model = this.model;
            const query: { [key: string]: string } = {};

            query['type'] = model.types
                .filter(t => t.selected)
                .map(t => t.type.key)
                .join(',');

            if (!this.hideLocalities) {
                query['localityid'] = model.localities
                    .filter(l => l.selected)
                    .map(l => l.locality.localityId.toString())
                    .join(',');
            }

            query['serviceid'] = model.services
                .filter(s => s.selected)
                .map(s => s.service.serviceId.toString())
                .join(',');

            query['minarea'] = model.minArea || '';
            query['maxarea'] = model.maxArea || '';

            const queryString = Object.keys(query)
                .filter(k => query[k])
                .map(k => `${k}=${query[k]}`)
                .join('&');

            history.replaceState(null, '', '?' + queryString);
        },
        async search(): Promise<void> {
            if (this.cancel) {
                this.cancel();
                this.cancel = null;
            }

            const model = this.model;
            const query = [];

            for (const type of model.types.filter(t => t.selected)) {
                query.push(`type[]=${type.type.key}`);
            }

            for (const locality of model.localities.filter(l => l.selected)) {
                query.push(`localityId[]=${locality.locality.localityId}`);
            }

            for (const service of model.services.filter(l => l.selected)) {
                query.push(`serviceId[]=${service.service.serviceId}`);
            }

            const areas = this.areaRange;

            if (areas[0] > 0) {
                query.push(`minArea=${areas[0]}`);
            }

            if (areas[1] > 0) {
                query.push(`maxArea=${areas[1]}`);
            }

            const url = '/api/premises/search/?' + query.join('&');

            try {
                const { data } = await axios.get(
                    url,
                    {
                        cancelToken: new axios.CancelToken((c) => {
                            this.cancel = c;
                        })
                    });

                this.items = data;

                this.resultsTitle = this.items && this.anyOptions
                    ? this.$t('/blocks/premisessearch/resultstitle')
                        .toString()
                        .replace('{count}', this.items.results.length.toString())
                    : null;
                    
            } catch (e) {
                if (!axios.isCancel(e))
                {
                    throw e;
                }
            }
        }
    }
});
