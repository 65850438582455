
import { defineComponent } from 'vue';
import { validateField, validateEmail, validateFormFields } from '../../common/forms/validators';
import axios from 'axios';

export default defineComponent({    
    data() {
        return {
            state: 'Start' as ('Start' | 'Confirm' | 'Sending' | 'Sent'),
            requireReply: false,
            form: {                
                feedback: '',
                name: '',
                email: '',
                email2: '',
            }
        };
    },
    computed: {
        sending(): boolean {
            return this.state == 'Sending';
        }
    },
    methods: {
        validateField(v: string): boolean | string {
            return validateField(v);
        },
        validateEmail(v: string): boolean | string {
            return validateEmail(v);
        },
        reset(): void {
            this.state = 'Start';
            this.requireReply = false;
            this.form = {
                name: '',
                email: '',
                email2: '',
                feedback: '',
            };
        },
        next(): void {
            let error = validateFormFields(this.$refs, (!this.requireReply ? ['feedback'] : ['name', 'email', 'feedback']));

            if (error) {
                return;
            }

            this.state = 'Confirm';
        },
        back(): void {
            this.state = 'Start';
        },
        async submit(): Promise<void> {
            this.state = 'Sending';
            await axios.post('/api/forms/accessibility-feedback/', this.form);
            this.state = 'Sent';
        },
    }
});

