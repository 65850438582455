
import { defineComponent } from 'vue';
import VarmaPremisesContactForm from '../premises-contact-form/VarmaPremisesContactForm.vue';

export default defineComponent({
    components: {
        VarmaPremisesContactForm
    },
    props: {
        email: { type: String, required: true },
        phone: { type: String, required: true },
        photo: { type: String, default: null },
        openFunctionName: { type: String, default: null },
        toId: { type: String, required: true }
    },
    data() {
        return {
            active: false,
        };
    },
    mounted() {
        if (this.openFunctionName) {
            (window[this.openFunctionName as any] as any) = (text?: string) => {
                if (typeof text == 'string') {
                    const form = this.$refs['form'] as any;
                    form.setMessage(text || '');
                }

                this.active = true;
            };
        }
    },
    methods: {
        toggle(): void {
            this.active = !this.active;
        }
    }
});
