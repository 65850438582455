
import { defineComponent, ref } from 'vue';
import axios from 'axios';

import VarmaFormAuth from '../../common/auth/VarmaFormAuth.vue';

export default defineComponent({
    components: {
        VarmaFormAuth
    },
    props: {
        approveText: { type: String, default: '' },
        isGdprAdmin: Boolean,
        identity: { type: Object, default: null }
    },
    setup(props) {
        const state = ref('Start' as ('Start' | 'Form' | 'Sending' | 'Sent'));
        if (props.identity) {
            state.value = 'Form';
        }

        return {
            state: state,
            approved: ref(false)
        };
    },
    computed: {
        sending(): boolean {
            return this.state == 'Sending';
        }
    },
    methods: {
        async reset(): Promise<void> {
            await axios.post('/api/forms/auth/sign-out');
            this.state = 'Start';
        },
        async submit(): Promise<void> {
            this.state = 'Sending';
            await axios.post('/api/forms/consent/', { approved: true });
            this.state = 'Sent';
        }
    }
});

