import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_card_search_options = _resolveComponent("varma-card-search-options")!
  const _component_varma_card_search_results = _resolveComponent("varma-card-search-results")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_varma_card_search_options, {
      typeIds: _ctx.typeIds,
      "onUpdate:typeIds": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeIds) = $event)),
      tagIds: _ctx.tagIds,
      "onUpdate:tagIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tagIds) = $event)),
      title: _ctx.filtersTitle,
      "type-filters": _ctx.typeFilters,
      "tag-filters": _ctx.tagFilters
    }, null, 8, ["typeIds", "tagIds", "title", "type-filters", "tag-filters"]),
    _createVNode(_component_varma_card_search_results, {
      "preloaded-result": _ctx.preloadedResult,
      "search-request": _ctx.searchRequest,
      "page-size": _ctx.pageSize,
      "display-count": _ctx.anySelections,
      "load-more-button-text": _ctx.loadMoreButtonText
    }, null, 8, ["preloaded-result", "search-request", "page-size", "display-count", "load-more-button-text"])
  ]))
}