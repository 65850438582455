
import { defineComponent } from 'vue';

import { scrollElement } from '@/utils/scroll';

import VarmaIcon from '../../common/icons/VarmaIcon.vue';

interface State {
    mode: 'Slider' | 'List';
    mouseDown: null | number;
    dragging: boolean;
    preventClick: boolean;
    scrollPosition: number;
    page: number;
}

interface Settings {
    fillWidth: number;
    trackWidth: number;
    pages: number[];
}

const SCROLL_VELOCITY = 3;

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        titleLevel: { type: String, default: 'l' },
        headingClasses: { type: String, default: null },
    },
    data(): {
        isMounted: boolean;
        state: State;
        settings: Settings;
        } {
        return {
            isMounted: false,
            state: {
                mode: 'Slider',
                mouseDown: null,
                dragging: false,
                preventClick: false,
                scrollPosition: 0,
                page: 1,
            },
            settings: {
                fillWidth: 0,
                trackWidth: 0,
                pages: [0]
            }
        };
    },
    mounted() {
        this.isMounted = true;

        this.$nextTick(this.setup);
        window.addEventListener('resize', this.setup);
    },
    methods: {
        getInnerEl(): HTMLDivElement | null {
            return this.$refs['innerEl'] as HTMLDivElement;
        },
        getTrackEl(): HTMLDivElement | null {
            return this.$refs['trackEl'] as HTMLDivElement;
        },
        getFillWidth(): number {
            const fillEl = this.$refs['fillRef'] as HTMLDivElement;
            return fillEl ? fillEl.offsetLeft : 0;
        },

        setup(): void {
            const fillWidth = this.getFillWidth();
            const trackWidth = this.calcTrackWidth();
            const pages = this.calcPages(fillWidth, trackWidth);

            this.settings = {
                fillWidth, trackWidth, pages
            };
        },
        
        calcTrackWidth(): number {
            const items = Array.from(this.getTrackEl()!.children) as HTMLElement[];

            // Track width needs to be calculated manually because flex box doesn't grow a parent that's inside an overflow container
            return items
                .filter(el => !el.classList.contains('track-fill'))
                .map(el => {
                    const styles = window.getComputedStyle(el);

                    const res = el.getBoundingClientRect().width +
                        (parseFloat(styles.getPropertyValue('margin-left')) || 0) +
                        (parseFloat(styles.getPropertyValue('margin-right')) || 0);

                    return res;
                })
                .reduce((res, v) => res + v, 0);
        },
        calcPages(fillWidth: number, trackWidth: number): number[] {
            const innerWidth = this.getInnerEl()!.offsetWidth,
                presentationWidth = innerWidth - fillWidth * 2;

            const items = Array.from(this.getTrackEl()!.children) as HTMLElement[];

            // If all content can fit on the screen starting from left fill position, disable pages and scrolling
            if (!items.length || trackWidth < presentationWidth + fillWidth) {
                return [0];
            }

            // First item offset might not be 0 after resize
            const offset = items[0].offsetLeft;

            let lastPage = 0;
            const pages = [0];

            for (const child of items) {
                if (child.offsetLeft - offset + child.offsetWidth > lastPage + presentationWidth) {
                    // The element doesn't completely fit in the presentation area on the current page -> new page
                    pages.push(child.offsetLeft - offset + fillWidth);
                    lastPage = child.offsetLeft - offset;
                }
            }

            if (pages.length > 1) {
                if (pages[pages.length - 1] > trackWidth - presentationWidth + fillWidth) {
                    // The last page is incomplete, calculate its starting position
                    pages[pages.length - 1] = trackWidth - presentationWidth + fillWidth;
                }
            }

            return pages;
        },

        toggleMode(): void {
            this.state.mode = (this.state.mode == 'Slider' ? 'List' : 'Slider');
        },

        // Page changes
        setPage(page: number): void {
            this.scroll(this.settings.pages[page] - this.settings.fillWidth, true);
        },
        setPageRelative(relative: number): void {
            // this.state.page returns current page starting from index 1
            const pageIndex = Math.max(0, Math.min(this.settings.pages.length - 1, this.state.page - 1 + relative));
            this.setPage(pageIndex);
        },

        // Track scrolling
        scroll(position: number, smooth?: boolean): void {
            // Clamp position to track dimensions
            const maxPosition = this.settings.trackWidth - this.getInnerEl()!.offsetWidth + this.settings.fillWidth * 2;

            this.state.scrollPosition = Math.max(0, Math.min(maxPosition, position));

            scrollElement(this.getInnerEl()!, {
                left: this.state.scrollPosition,
                behavior: smooth ? 'smooth' : 'auto'
            });
        },
        scrollRelative(relative: number): void {
            this.scroll(this.state.scrollPosition + relative);
        },

        // Update page from current scroll
        onscroll(e: Event): void {
            const pages = this.settings.pages;

            let page = pages.findIndex(p => p > (e.target! as HTMLElement).scrollLeft + this.settings.fillWidth);
            if (page === -1) {
                page = pages.length;
            }

            this.state.page = Math.max(1, page);
        },

        // Dragging
        onmousedown(e: MouseEvent): void {
            e.preventDefault();
            e.stopPropagation();
            this.state.mouseDown = e.clientX;
        },
        onmousemove(e: MouseEvent): void {
            if (!this.state.mouseDown) {
                return;
            }

            const diff = (this.state.mouseDown - e.clientX) * SCROLL_VELOCITY;
            this.state.mouseDown = e.clientX;

            if (diff != 0) {
                this.scrollRelative(diff);
                this.state.dragging = true;
            }
        },
        onmouseup(): void {
            if (this.state.dragging) {
                this.state.preventClick = true;
            }

            this.state.dragging = false;
            this.state.mouseDown = null;
        },
        onclick(e: MouseEvent): void {
            if (!this.state.preventClick) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
            this.state.preventClick = false;
        },
    }
});
