import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, withKeys as _withKeys, vModelText as _vModelText, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27903f23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "varma-form-field-wrapper" }
const _hoisted_2 = { class: "varma-form-field-label-wrapper" }
const _hoisted_3 = ["id", "for", "aria-owns"]
const _hoisted_4 = { class: "varma-label" }
const _hoisted_5 = {
  key: 0,
  "aria-hidden": "true",
  class: "varma-placeholder-required varma-form-field-required-marker"
}
const _hoisted_6 = { class: "varma-form-field-label-help-text" }
const _hoisted_7 = { class: "varma-form-field-input-wrapper" }
const _hoisted_8 = { class: "varma-form-field-flex" }
const _hoisted_9 = ["id", "type", "placeholder", "autocomplete", "aria-invalid", "aria-required", "aria-label", "aria-labelledby", "aria-describedby", "maxlength"]
const _hoisted_10 = ["id", "placeholder", "autocomplete", "aria-invalid", "aria-required", "aria-label", "aria-labelledby", "aria-describedby", "maxlength"]
const _hoisted_11 = ["id"]
const _hoisted_12 = {
  key: 0,
  class: "varma-form-field-subscript-wrapper"
}
const _hoisted_13 = ["id"]
const _hoisted_14 = { class: "varma-help-text" }
const _hoisted_15 = { class: "varma-error" }
const _hoisted_16 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'varma-form-field-invalid': !!_ctx.validationError }, "varma-form-field varma-form-field-type-varma-input varma-form-field-appearance-standard"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("span", _hoisted_2, [
        _createElementVNode("label", {
          id: _ctx.labelId,
          for: _ctx.id,
          "aria-owns": _ctx.id
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.labelText), 1),
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " *"))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.helpText), 1)
        ], 8, _hoisted_3)
      ], 512), [
        [_vShow, _ctx.showLabel]
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "varma-form-field-infix",
            style: _normalizeStyle({ 'max-width': _ctx.maxWidth })
          }, [
            (_ctx.type != 'textarea')
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  id: _ctx.id,
                  ref: "input",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
                  class: "varma-input-element",
                  type: _ctx.type,
                  placeholder: _ctx.placeholder,
                  autocomplete: _ctx.autocomplete,
                  "aria-invalid": !!_ctx.validationError,
                  "aria-required": _ctx.required,
                  "aria-label": _ctx.ariaLabel,
                  "aria-labelledby": _ctx.ariaLabelledBy,
                  "aria-describedby": _ctx.displayAriaDescribedBy,
                  maxlength: _ctx.maxLength,
                  onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
                  onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"]))
                }, null, 40, _hoisted_9)), [
                  [_vModelDynamic, _ctx.internalValue]
                ])
              : (_ctx.type == 'textarea')
                ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                    key: 1,
                    id: _ctx.id,
                    ref: "input",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalValue) = $event)),
                    class: "varma-input-element",
                    placeholder: _ctx.placeholder,
                    autocomplete: _ctx.autocomplete,
                    "aria-invalid": !!_ctx.validationError,
                    "aria-required": _ctx.required,
                    "aria-label": _ctx.ariaLabel,
                    "aria-labelledby": _ctx.ariaLabelledBy,
                    "aria-describedby": _ctx.displayAriaDescribedBy,
                    style: _normalizeStyle({ 'min-height': _ctx.minHeight }),
                    onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
                    onKeyup: _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
                    maxlength: _ctx.maxLength
                  }, null, 44, _hoisted_10)), [
                    [_vModelText, _ctx.internalValue]
                  ])
                : _createCommentVNode("", true)
          ], 4),
          (_ctx.unitText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "varma-form-field-unit",
                style: _normalizeStyle({ 'width': _ctx.unitWidth }),
                id: _ctx.unitId
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.unitText), 1)
              ], 12, _hoisted_11))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "input-button", {}, undefined, true)
        ])
      ]),
      (_ctx.hintVisible || _ctx.errorVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_ctx.hintVisible)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  id: _ctx.hintId,
                  class: "varma-form-field-hint-wrapper"
                }, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.hintText), 1)
                ], 8, _hoisted_13))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_15, [
              (_ctx.errorVisible)
                ? (_openBlock(), _createElementBlock("label", {
                    key: 0,
                    id: _ctx.errorId,
                    class: "varma-error",
                    role: "alert"
                  }, _toDisplayString(_ctx.validationError), 9, _hoisted_16))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}