
const translations = (window.VarmaI18n || {})[window.VarmaI18nLocale || ''] as unknown as { [key: string]: string };

export function translate(key: string): string {
    if (!(key in translations)) {
        console.info(`varma-i18n: unknown translation '${key}'`);
        return key;
    }

    return translations[key];
}
