import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47dfbc26"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_heading = _resolveComponent("varma-heading")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["varma-form", {'varma-form--loading': _ctx.loading}])
  }, [
    (_ctx.closable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "varma-form__close",
          alt: _ctx.$t('/common/close'),
          "aria-label": _ctx.$t('/common/close'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_varma_icon, {
            name: "close",
            size: "M",
            color: "black",
            weight: "bold"
          })
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.sent)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_varma_heading, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true)
            ]),
            _: 3
          }),
          _renderSlot(_ctx.$slots, "body", { onSubmit: _ctx.onSubmit }, undefined, true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_varma_heading, {
            ref: "successTitle",
            tabindex: "-1"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "success-title", {}, undefined, true)
            ]),
            _: 3
          }, 512),
          _renderSlot(_ctx.$slots, "success-body", { reset: _ctx.reset }, undefined, true)
        ], 64))
  ], 2))
}