
import { defineComponent, ref } from 'vue';

declare const window: any;

export default defineComponent({
    setup() {
        const slotContainer = ref<InstanceType<typeof HTMLButtonElement>>();
        const linkVisible = !!window.epi;

        return {
            slotContainer,
            linkVisible
        };
    },
    computed: {
        anchorId(): string {
            return (this.slotContent || '')
                .trim()
                .toLowerCase()
                .replace(/ä/g, 'a')
                .replace(/ö/g, 'o')
                .replace(/\s+/g, '-')
                .toLowerCase()
                .replace(/[^0-9A-Za-z_-]/g, '');
        },
        slotContent(): string | undefined {
            const slot: HTMLElement = (this.slotContainer as HTMLElement);
            if (!slot) {
                return undefined;
            }

            return (slot.firstElementChild?.innerHTML || slot.innerHTML);
        }
    },
    methods: {
        getAnchorLink(): string {
            return ''.concat('#', this.anchorId);
        }
    }
});
