
import { defineComponent } from 'vue';

export default defineComponent({
    props: {   
        isActive: { type: Boolean, default: false },
        focusId: { type: String, default: '' },
    },
    data() {
        return {
            isOpen: this.isActive
        };
    },
    computed: {
        active(): boolean {
            return this.isOpen;
        }
    },
    methods: {
        show(): void {
            this.set(true);
            if (this.focusId) {
                this.$nextTick(() => {
                    const focusable = document.getElementById(this.focusId);
                    if (focusable) {
                        focusable.focus();
                    }
                });
            }
        },
        hide(): void {
            this.set(false);
        },
        toggle(): void {
            this.set(!this.active);
        },
        set(state: boolean): void {
            this.isOpen = state;
        }
    }
});
