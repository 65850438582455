
import { defineComponent, ref, PropType, watch } from 'vue';
import { v4 as uuid } from 'uuid';

export type InputType = 'text' | 'number' | 'textarea';

export default defineComponent({    
    props: {
        modelValue: { type: String, default: '' },
        id: {
            type: String,
            default: (): string => {
                return String().concat('a',uuid()).replace(/-/g, '');               
            }
        },
        required: Boolean,
        rules: {
            type: Array as PropType<{ (v: string): boolean | string }[]>,
            default: (): { (v: string): boolean | string }[] => []
        },
        options: { type: Array, default: (): { value: object; text: string }[] => { return []; } },
        labelText: { type: String, default: '' },
        helpText: { type: String, default: '' },
        hintText: { type: String, default: '' },
        type: { type:  String as PropType<InputType>, default: 'text' },
        minHeight: { type: String, default: null }
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const internalValue = ref(props.modelValue);
        const validationError = ref(false as boolean | string);

        function validate(): boolean | string {
            let error: boolean | string = false;

            for (const rule of props.rules) {
                const result = rule(internalValue.value);

                if (result) {
                    error = result;
                    break;
                }
            }

            validationError.value = error;

            return error;
        }

        watch(
            () => internalValue.value,
            () => {
                context.emit('update:modelValue', internalValue.value);
            }
        );

        watch(
            () => props.modelValue,
            () => {
                internalValue.value = props.modelValue;
                validate();
            }
        );

        return {
            internalValue,
            validationError,

            validate,
        };
    },
    computed: {
        labelId(): string {
            return ''.concat(this.id, '--label');
        },
        errorId(): string {
            return ''.concat(this.id, '--error');
        },
        hintId(): string {
            return ''.concat(this.id, '--hint');
        },
        ariaLabelledBy(): string {
            return this.labelId;
        },
        ariaDescribedBy(): null | string {
            if (this.errorVisible) {
                return this.errorId;
            }

            if (this.hintVisible) {
                return this.hintId;
            }

            return null;
        },
        errorVisible(): boolean {
            return !!this.validationError && typeof this.validationError == 'string';
        },
        hintVisible(): boolean {
            return !this.errorVisible && this.hintText != '';
        }
    },
    methods: {
        focus(): void {
            (this.$refs['input'] as HTMLInputElement).focus();
        }
    }
});
