import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed6d446c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "varma-form__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_form_grey = _resolveComponent("varma-form-grey")!

  return (_openBlock(), _createBlock(_component_varma_form_grey, {
    ref: "varmaForm",
    closable: _ctx.isFloatingVersion,
    "trap-focus": _ctx.isFloatingVersion,
    onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close'))),
    onSuccess: _cache[8] || (_cache[8] = ($event: any) => (_ctx.focusOnSuccessTitle()))
  }, {
    title: _withCtx(() => [
      _createElementVNode("h2", {
        ref: "title",
        class: "varma-form__title varma-form__item",
        tabindex: "-1"
      }, _toDisplayString(_ctx.$t('/blocks/premisescontactform/title')), 513)
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        class: "premises-contact-form",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_varma_input, {
          ref: "name",
          modelValue: _ctx.form.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
          autocomplete: "name",
          "label-text": _ctx.$t('/blocks/premisescontactform/name'),
          required: "",
          rules: [_ctx.validateField],
          class: "premises-contact-form__item"
        }, null, 8, ["modelValue", "label-text", "rules"]),
        _createVNode(_component_varma_input, {
          ref: "company",
          modelValue: _ctx.form.company,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.company) = $event)),
          "label-text": _ctx.$t('/blocks/premisescontactform/company'),
          required: "",
          rules: [_ctx.validateField],
          class: "premises-contact-form__item"
        }, null, 8, ["modelValue", "label-text", "rules"]),
        _createVNode(_component_varma_input, {
          ref: "email",
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
          type: "email",
          autocomplete: "email",
          "label-text": _ctx.$t('/blocks/premisescontactform/email'),
          required: "",
          rules: [_ctx.validateEmail, _ctx.validateField],
          class: "premises-contact-form__item"
        }, null, 8, ["modelValue", "label-text", "rules"]),
        _createVNode(_component_varma_input, {
          ref: "phone",
          modelValue: _ctx.form.phone,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.phone) = $event)),
          type: "tel",
          autocomplete: "tel",
          "label-text": _ctx.$t('/blocks/premisescontactform/phone'),
          rules: [_ctx.validatePhoneNumber],
          class: "premises-contact-form__item"
        }, null, 8, ["modelValue", "label-text", "rules"]),
        _createVNode(_component_varma_input, {
          ref: "message",
          modelValue: _ctx.form.message,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.message) = $event)),
          type: "textarea",
          "label-text": _ctx.$t('/blocks/premisescontactform/message'),
          class: "premises-contact-form__item",
          "min-height": "64px"
        }, null, 8, ["modelValue", "label-text"]),
        _createVNode(_component_varma_button, { class: "premises-contact-form__button" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('/blocks/premisescontactform/submit')), 1)
          ]),
          _: 1
        })
      ], 32)
    ]),
    "success-title": _withCtx(() => [
      _createElementVNode("h2", {
        ref: "successTitle",
        class: "varma-form__title varma-form__item",
        tabindex: "-1"
      }, _toDisplayString(_ctx.$t('/blocks/premisescontactform/success-title')), 513)
    ]),
    "success-body": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('/blocks/premisescontactform/success-body')), 1),
      (!_ctx.isFloatingVersion)
        ? (_openBlock(), _createBlock(_component_varma_button, {
            key: 0,
            ghost: "",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.reset()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('/blocks/premisescontactform/reset')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["closable", "trap-focus"]))
}