import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-472f763b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "langs" }
const _hoisted_2 = { class: "item icon-item" }
const _hoisted_3 = ["href", "target", "title", "aria-current"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_varma_icon, {
        name: "earth",
        size: "M",
        color: "ui-darkest",
        "vertical-align": "middle"
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.languages, (lang) => {
      return (_openBlock(), _createElementBlock("div", {
        key: lang.name,
        class: "item lang-item"
      }, [
        _createElementVNode("a", {
          href: lang.url,
          target: lang.targetFrame,
          title: _ctx.$t('/common/languagemenu' + lang.name),
          class: _normalizeClass(["lang-link", { active: _ctx.isCurrent(lang.name) }]),
          "aria-current": _ctx.isCurrent(lang.name) ? 'page' : false
        }, _toDisplayString(lang.name), 11, _hoisted_3)
      ]))
    }), 128))
  ]))
}