
import { defineComponent, reactive, watch, ref } from 'vue';
import axios from 'axios';
import * as GA from '../../../utils/GA';

export default defineComponent({    
    setup() {
        const form = reactive({
            widowPension: '',
            deceasedPension: ''
        });
        const result = ref(null as null | string);

        watch(form,
            () => {
                result.value = null;
            },
            { deep: true });

        return {
            form: form,
            result
        };
    },
    computed: {
        hasResult(): boolean {
            return this.result !== null;
        }
    },
    methods: {
        validateNumber(value: string): boolean | string {
            if (!value) {
                return this.$t('/blocks/widowpension/error/inputamount');
            }

            if (!Number.isFinite(Number(value.replace(',','.')))) {
                return this.$t('/blocks/widowpension/error/inputformat');
            }

            return false;
        },
        async submit(): Promise<void> {
            let error = false;
            for (const key of Object.keys(this.form)) {
                error = (this.$refs[key] as any).validate() || error;
            }

            if (error) {
                return;
            }

            this.result = null;

            GA.pushDataLayer({'name':'WidowPensionCalculator', 'action':'calculate', 'event': 'cCalculator' });

            const { data } = await axios.post('/api/widowpension/calculate', {
                WidowPension: this.form.widowPension.replace(',','.'),
                DeceasedPension: this.form.deceasedPension.replace(',','.')
            }, {
                withCredentials: true
            });

            this.result = data.result;
        },
    }
});

