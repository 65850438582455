
import { defineComponent, PropType } from 'vue';
import { HeaderModel } from './model';

export default defineComponent({
    props: {
        model: { type: Object as PropType<HeaderModel>, required: true },
    },
    methods: {
        isCurrent(lang: string): boolean {
            return this.model.languageName == lang;
        }
    }
});
