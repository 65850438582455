
import { defineComponent, ref } from 'vue';
import VarmaDialog from '../../common/dialog/VarmaDialog.vue';
import VarmaCookieSelect from './VarmaCookieSelect.vue';
import { VarmaCookies, hasCookie, setSitewideBooleanCookie } from './VarmaCookies';
import * as GA from '../../../utils/GA';

export default defineComponent({
    components: {
        VarmaCookieSelect
    },
    props: {
        title: { type: String, required: true },
        mandatoryButton: { type: String, required: true },
        allButton: { type: String, required: true },
        showCookiesButton: { type: String, required: true },
        saveButton: { type: String, required: true },
        mandatoryHeading: { type: String, required: true },
        mandatoryText: { type: String, required: true },
        analyticsHeading: { type: String, required: true },
        analyticsText: { type: String, required: true },
        marketingHeading: { type: String, required: true },
        marketingText: { type: String, required: true },
        buttonsStyle: { type: String, default: 'v1' }
    },
    setup() {
        const hasCookies = ref(hasCookie(VarmaCookies.Mandatory));
        const cookieDialog = ref<InstanceType<typeof VarmaDialog>>();
        return {
            cookieDialog,
            hasCookies
        };
    },
    data() {
        return { 
            viewCookies: false,
            mandatory: true,
            marketing: false,
            analytics: false,
            dialogKey: 'small'
        };
    },
    methods: {
        acceptMandatoryCookies(): void {
            setSitewideBooleanCookie(VarmaCookies.Mandatory);
            this.hasCookies = true;
            this.alertGA();
        },
        acceptAllCookies(): void {
            setSitewideBooleanCookie(VarmaCookies.Analytics);
            setSitewideBooleanCookie(VarmaCookies.Marketing);
            this.acceptMandatoryCookies();     
        },
        executeScripts(): void {            
            if((window as any).youtubeIframeApi) {
                (window as any).youtubeIframeApi();
            }
        },
        showDetails(): void {
            this.viewCookies = true;
            this.dialogKey = 'expanded'; //force re-render of dialog window when focusable contents have changed (fix for focus-trap not working)
        },
        saveCookies(): void {
            this.acceptMandatoryCookies();
        },
        alertGA(): void {
            GA.pushDataLayer({'event':'changeConsent'});
        }
    }
});
