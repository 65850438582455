
import { defineComponent } from 'vue';
import { SearchResult } from '../search/VarmaSearchResponseModel';
import { SearchFilter, PageTypeFilters, SortOptions, GetYearFilters } from '../search/SearchFilters';
import VarmaButton from '../../common/buttons/varma-button/VarmaButton.vue';
import { VarmaSearchApiService, SortType, SearchType } from '../../features/search/VarmaSearchApiService';
import VarmaInput from '../../common/forms/VarmaInput.vue';
import VarmaSelect from '../../common/forms/VarmaSelect.vue';
import VarmaRadioGroup from '../../common/forms/VarmaRadioGroup.vue';
import VarmaIcon from '../../common/icons/VarmaIcon.vue';

enum SearchResultHitAction {
    PUSH,
    REPLACE
}

export default defineComponent({
    components: {
        VarmaButton,
        VarmaInput,
        VarmaIcon,
        VarmaSelect,
        VarmaRadioGroup
    },
    props: {
        siteLanguageCode: { type: String, required: true },
        pageSize: { type: Number, required: true },
        onLoadQuery: { type: String, required: false, default: '' }
    },
    data() {
        return {
            currentPage: 1,
            isLoading: false,
            queryText: this.onLoadQuery,
            currentSearchResult: {} as SearchResult,
            searchApiService: new VarmaSearchApiService(),
            previousQuery: '',
            searchesMade: false,
            pageFilterTypes: JSON.parse(JSON.stringify(PageTypeFilters)) as Array<SearchFilter>,
            yearOptions: GetYearFilters(),
            yearFilter: undefined,
            sortType: SortType.RelevanceScore,
            sortOptions: SortOptions.map(option => { 
                return { 
                    value: option.value, 
                    label: this.$t(option.labelKey) 
                }; 
            })
        } as {
            currentPage: number;
            isLoading: boolean;
            queryText: string;
            currentSearchResult: SearchResult;
            searchApiService: VarmaSearchApiService;
            previousQuery: string;
            searchesMade: boolean;
            pageFilterTypes: Array<SearchFilter>;
            yearOptions: [];
            yearFilter: number | undefined;
            sortType: SortType;
            sortOptions: Array<{value: SortType; label: string}>;
        };
    },
    computed: {
        nextPage(): number {
            return this.currentPage + 1;
        },
        resultsFoundText(): string {
            let text = this.$t('/search/resultswerefoundwithquery');
            if (text.includes('{query}')) { 
                text = text.replace('{query}', this.currentSearchResult.query ? this.currentSearchResult.query : ''); 
            }
            if (text.includes('{count}')) { 
                text = text.replace('{count}', this.currentSearchResult.totalCount ? this.currentSearchResult.totalCount.toString() : ''); 
            }

            return text;
        },
        noResultsFoundText(): string {
            let text = this.$t('/search/noresultswerefoundwithquery');
            if (text.includes('{query}')) { 
                text= text.replace('{query}', this.currentSearchResult.query ? this.currentSearchResult.query : ''); 
            }

            return text;
        }
    },
    mounted() {
        if (this.onLoadQuery && this.onLoadQuery.length > 0) {
            this.searchContent(1, SearchResultHitAction.REPLACE);
        } 
            
        this.$watch(
            () => this.yearFilter,
            () => this.onSearchClick());

        this.$watch(
            () => this.sortType,
            () => this.onSearchClick());
    },
    methods: {
        requiredField(value: string): boolean | string {
            if (!value) {
                return this.$t('/search/error/queryempty');
            }

            return false;
        },
        onSearchClick(): void {
            this.searchContent(1, SearchResultHitAction.REPLACE);
        },
        onShowMoreClick(): void {
            this.searchContent(this.nextPage, SearchResultHitAction.PUSH);
        },
        searchContent(page: number, operation: SearchResultHitAction): void {
            const url = new URL(window.location.toString());
            if (url.searchParams.get('q') != this.queryText) {
                url.searchParams.set('q', this.queryText);
                window.history.replaceState({}, '', url.toString());
            }

            if (this.queryText.length < 1) {
                this.currentSearchResult = {} as SearchResult;

                return;
            }
            this.isLoading = true;

            this.searchApiService.searchContent({
                query: this.queryText,
                skip: (page - 1) * this.pageSize,
                take: this.pageSize,
                sort: this.sortType,
                types: this.getTypes(),
                tags: undefined,
                language: this.siteLanguageCode,
                year: this.yearFilter
            })
                .then((res: SearchResult) => {
                    this.currentPage = page;

                    if(operation == SearchResultHitAction.PUSH) {
                        this.currentSearchResult = {
                            ...res,
                            hits: [
                                ...this.currentSearchResult.hits,
                                ...res.hits
                            ]
                        };                                         
                    }
                    else {
                        this.currentSearchResult = res;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                    this.searchesMade = true;
                });
        },
        clearFilters(): void {
            this.pageFilterTypes.forEach(type => {
                type.checked = false;
                if (type.subtypes) {
                    type.subtypes.forEach(subtype => subtype.checked = false);
                }
            });
            this.yearFilter = undefined;

            this.onSearchClick();
        },
        getTypes(): SearchType[] {
            const selectedFilters: SearchType[] = [];
            this.pageFilterTypes.filter((typeFilter: SearchFilter) => typeFilter.checked).forEach((typeFilter: SearchFilter) => {
                if (typeFilter.subtypes) {
                    const checkedSubtypes: SearchFilter[] = typeFilter.subtypes.filter((subtype: SearchFilter) => subtype.checked);
                    if (checkedSubtypes.length === 0) {
                        typeFilter.subtypes.forEach(subType => selectedFilters.push(subType.value));
                    } else {
                        checkedSubtypes.forEach(subType => selectedFilters.push(subType.value));
                    }
                } else if (typeFilter.value) {
                    selectedFilters.push(typeFilter.value);
                }
            });
            return selectedFilters;
        },
        onFilterClick(mainTypeIndex?: number): void {
            if (mainTypeIndex && this.pageFilterTypes[mainTypeIndex].subtypes) {
                const parentFilter: SearchFilter = this.pageFilterTypes[mainTypeIndex];
                if (!parentFilter.checked && parentFilter.subtypes && parentFilter.subtypes.some(subtype => subtype.checked)) {
                    parentFilter.subtypes.forEach(subtype => subtype.checked = false);
                }
                if (parentFilter.hasYearFilter) {
                    this.yearFilter = undefined;
                }
            } 
            this.onSearchClick();
        }
    }
});
