
import { defineComponent, PropType } from 'vue';
import { viewportWidth } from '@/utils/viewport';
import { HeaderModel } from './model';
import VarmaHeaderDesktop from './VarmaHeaderDesktop.vue';
import VarmaHeaderMobile from './VarmaHeaderMobile.vue';

// Keep in sync with styles/blocks/_footer.scss
const DESKTOP_MIN_WIDTH = 1030;

export default defineComponent({
    components: {
        VarmaHeaderDesktop,
        VarmaHeaderMobile,
    },
    props: {
        model: { type: Object as PropType<HeaderModel>, required: true },
    },
    data() {
        return {
        } as {
        };
    },
    computed: {
        version(): 'Desktop' | 'Mobile' { 
            return viewportWidth.value < DESKTOP_MIN_WIDTH
                ? 'Mobile'
                : 'Desktop';
        }
        // allLanguagesLabel(): string {	
        //     if (!window.VarmaI18n) {	
        //         return '';	
        //     }	

        //     return Object.keys(window.VarmaI18n).map(lang => {	
        //         return (window.VarmaI18n as any)[lang]['/common/language'];	
        //     }).join(', ');	
        // },
    },
    created() {
        //
    },
    mounted() {
        // setTimeout(() => {
        //     this.initialized = true;
        // }, 1000);
    },
    methods: {
        // handleSearchOpen(): void {
        //     this.$nextTick(() => {
        //         const mainHeight = (this.$refs.mainContainer as HTMLElement).clientHeight;
        //         const itemTop = (this.$refs.itemContainer as HTMLElement).offsetTop;

        //         // Ensure the search input is visible.
        //         window.scrollTo({
        //             top: itemTop - mainHeight
        //         });

        //         // Focus handling needs extra care to not break it on iOS.
        //         (this.$refs.itemSearch as any).focus();
        //     });
        // },
        // handleLanguageFocus(ev: FocusEvent): void {
        //     if (this.languageOpen) {
        //         let element = ev.target as Node;

        //         while (element !== null) {
        //             if (element == this.$refs.languageMenu) {
        //                 return;
        //             }
        //             element = element.parentElement as Node;
        //         }
                
        //         this.languageOpen = false;
        //     }
        // },

    }
});
