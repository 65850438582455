
import { defineComponent, nextTick, PropType } from 'vue';

import { scrollElement } from '@/utils/scroll';

import VarmaIcon from '../../common/icons/VarmaIcon.vue';

interface Image {
    url: string;
    alt: string;
}

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        images: { type: Array as PropType<Image[]>, default: (): Image[] => [] },
        index: { type: Number, required: true },
    },
    emits: ['update:index'],
    data() {
        return {
            leftEdge: false,
            rightEdge: false
        } as {
            leftEdge: boolean;
            rightEdge: boolean;
        };
    },
    async mounted(): Promise<void> {
        await nextTick();

        this.updateEdges();

        this.$watch(() => this.index, this.indexChanged);
        window.addEventListener('resize', this.updateEdges);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateEdges); 
    },
    methods: {
        getInnerEl(): HTMLDivElement | null {
            return this.$refs['innerEl'] as HTMLDivElement;
        },

        getTrackEl(): HTMLDivElement | null {
            return this.$refs['trackEl'] as HTMLDivElement;
        },

        getImageUrl(url: string): string {
            return `${url}?preset=image-slider-card`;
        },

        indexChanged() {
            const items = Array.from(this.getTrackEl()!.children) as HTMLElement[];                
            const offset = items[0].offsetLeft;

            const activeItem = items[this.index];

            const left = activeItem.offsetLeft - offset;
            const right = left + activeItem.offsetWidth;

            this.ensureRangeVisible(left, right); 
        },

        setIndex(index: number): void {
            this.$emit('update:index', index);
        },        

        scrollRelativePage(rel: number): void {
            const innerEl = this.getInnerEl()!;
            const maxLeft = innerEl.scrollWidth - innerEl.offsetWidth;

            let left = innerEl.scrollLeft + innerEl.offsetWidth * rel;

            if (left < 0) {
                left = 0;
            } else if (left > maxLeft) {
                left = maxLeft;
            }

            scrollElement(innerEl, {
                left: left,
                behavior: 'smooth'
            });

            this.moveFocus(); 
        },

        async moveFocus(): Promise<void> {
            await nextTick();
            
            const thumbnails = (this.$refs.thumbnails as HTMLElement[]);
            if (this.leftEdge) {
                thumbnails[thumbnails.length - 1].focus();
            } else if (this.rightEdge) {
                thumbnails[0].focus();
            }
        },

        ensureRangeVisible(minLeft: number, maxRight: number) {
            const innerEl = this.getInnerEl()!;

            const left = innerEl.scrollLeft;
            const width = innerEl.offsetWidth;

            if (left > minLeft) {
                scrollElement(innerEl, {
                    left: minLeft,
                    behavior: 'smooth'
                });
            } else if (left + width < maxRight) {
                scrollElement(innerEl, {
                    left: maxRight - width,
                    behavior: 'smooth'
                });
            }
        },

        updateEdges(): void {
            const innerEl = this.getInnerEl()!;

            this.leftEdge = innerEl.scrollLeft - 1 <= 0;
            this.rightEdge = innerEl.scrollWidth - innerEl.scrollLeft - innerEl.offsetWidth - 1 <= 0;
        }
    }
});
