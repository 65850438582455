import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7833660f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cookie-check" }
const _hoisted_2 = { class: "cookie-check__title" }
const _hoisted_3 = { class: "cookie-check__button" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_varma_icon, {
      name: "cookie",
      weight: "light",
      size: "L",
      color: "black"
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.titleText), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_varma_button, { onClick: _ctx.accept }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('/blocks/cookiecheck/acceptcookies')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_ctx.manageUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _ctx.manageUrl,
          class: "cookie-check__link"
        }, _toDisplayString(_ctx.$t('/blocks/cookiecheck/managecookieslink')), 9, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}