
import { defineComponent, PropType } from 'vue';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { HeaderModel } from './model';
import VarmaHeaderDropDown from './VarmaHeaderDropDown.vue';
import VarmaHeaderItem from './VarmaHeaderItem.vue';
import VarmaHeaderMobileLang from './VarmaHeaderMobileLang.vue';
import VarmaHeaderMobileNavi from './VarmaHeaderMobileNavi.vue';
import VarmaHeaderSearch from './VarmaHeaderSearch.vue';

type OpenKind = 'Contact' | 'Menu' | 'Search';

export default defineComponent({
    components: {
        VarmaHeaderDropDown,
        VarmaHeaderItem,
        VarmaHeaderMobileLang,
        VarmaHeaderMobileNavi,
        VarmaHeaderSearch,
    },
    props: {
        model: { type: Object as PropType<HeaderModel>, required: true },
    },
    data() {
        return {
            open: null,
            tabbedBack: false,
        } as {
            open: null | OpenKind;
            tabbedBack: boolean;
        };
    },
    mounted() {
        this.$watch(() => this.open, (open: null | OpenKind) => {
            if (open == 'Menu') {
                this.$nextTick(() => disableBodyScroll(this.$refs.menu as HTMLElement));
            } else {
                enableBodyScroll(this.$refs.menu as HTMLElement);
            }
        });

        document.addEventListener('keyup', this.onkeyup);
        document.addEventListener('keydown', this.onkeydown);
        document.addEventListener('focusin', this.onfocusin);
    },
    unmounted() {
        clearAllBodyScrollLocks();
    },
    computed: {
        lastElement(): HTMLElement {
            const els = (this.$refs.menu as HTMLElement).querySelectorAll('a, button');
            return (els[els.length - 1] as HTMLElement);
        }
    },
    methods: {
        isOpen(key: OpenKind): boolean {
            return this.open == key;
        },
        setOpen(key: OpenKind, value: boolean): void {
            if (this.open == key && !value) {
                this.open = null;
            } else if (this.open != key && value) {
                this.open = key;
            }
        },
        onkeyup(ev: KeyboardEvent): void {
            if (ev.code === 'Escape') {
                this.open = null;
            }
        },
        onkeydown(ev: KeyboardEvent): void {
            if (ev.code === 'Tab') {
                this.tabbedBack = ev.shiftKey;
            }
        },
        onfocusin(ev: FocusEvent): void {
            if (this.open == 'Menu') {
                let element = ev.target as Node;

                while (element !== null) {
                    if (element == this.$refs.header ||
                        element == this.$refs.menu) {
                        return;
                    }
                    element = element.parentElement as Node;
                }

                if (this.tabbedBack && (!ev.relatedTarget || (ev.relatedTarget as HTMLElement).id === (this.$refs.logo as HTMLElement).id)) {
                    this.lastElement.focus();
                } else {
                    (this.$refs.logo as HTMLElement).focus();
                }
            }
        },
    }
});
