import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ff9c5b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-hidden", "disabled"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    ref: "button",
    "aria-hidden": _ctx.hideFromScreenReader,
    class: _normalizeClass(["varma-button", {
            'varma-selected': _ctx.isSelected(),
            'varma-accent': _ctx.isAccent(),
            'varma-primary': _ctx.isPrimary(),
            'varma-text-only': _ctx.textOnly,
            'varma-ghost': _ctx.ghost,
            'varma-small': _ctx.small
        }]),
    disabled: _ctx.isDisabled(),
    "data-cy": "varma-button__button"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.hasButtonText())
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getButtonText()), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}