import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20532a0b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-change-form" }
const _hoisted_2 = { class: "grid-contain" }
const _hoisted_3 = { class: "content col6-m-width" }
const _hoisted_4 = { class: "title heading-l" }
const _hoisted_5 = { class: "heading-s" }
const _hoisted_6 = { class: "heading-s" }
const _hoisted_7 = {
  class: "heading-s",
  style: {"margin-top":"40px"}
}
const _hoisted_8 = { class: "form-actions" }
const _hoisted_9 = { class: "title heading-l" }
const _hoisted_10 = { class: "heading-s" }
const _hoisted_11 = { class: "confirm-field" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = { class: "confirm-field" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "value" }
const _hoisted_17 = { class: "confirm-field" }
const _hoisted_18 = { class: "label" }
const _hoisted_19 = { class: "value" }
const _hoisted_20 = { class: "confirm-field" }
const _hoisted_21 = { class: "label" }
const _hoisted_22 = { class: "value" }
const _hoisted_23 = { class: "heading-s" }
const _hoisted_24 = { class: "confirm-field" }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "value" }
const _hoisted_27 = { class: "confirm-field" }
const _hoisted_28 = { class: "label" }
const _hoisted_29 = { class: "value" }
const _hoisted_30 = { class: "confirm-field" }
const _hoisted_31 = { class: "label" }
const _hoisted_32 = { class: "value" }
const _hoisted_33 = { class: "heading-s" }
const _hoisted_34 = { class: "confirm-field" }
const _hoisted_35 = { class: "label" }
const _hoisted_36 = { class: "value" }
const _hoisted_37 = { class: "confirm-field" }
const _hoisted_38 = { class: "label" }
const _hoisted_39 = { class: "confirm-field" }
const _hoisted_40 = { class: "label" }
const _hoisted_41 = { class: "value" }
const _hoisted_42 = { class: "form-actions" }
const _hoisted_43 = { class: "success-icon" }
const _hoisted_44 = { class: "title heading-l" }
const _hoisted_45 = { class: "content-text content-text--M" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_heading = _resolveComponent("varma-heading")!
  const _component_varma_input = _resolveComponent("varma-input")!
  const _component_varma_checkbox = _resolveComponent("varma-checkbox")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.state == 'Start')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                  ])
                ]),
                _: 3
              }),
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('/blocks/addresschangeform/personinformation')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_varma_input, {
                ref: "name",
                modelValue: _ctx.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/name'),
                required: "",
                rules: [_ctx.validateField]
              }, null, 8, ["modelValue", "label-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "ssn",
                modelValue: _ctx.form.ssn,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.ssn) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/ssn'),
                "max-width": "121px",
                required: "",
                rules: [_ctx.validateField, _ctx.validateSsn]
              }, null, 8, ["modelValue", "label-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "email",
                modelValue: _ctx.form.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/email'),
                rules: [_ctx.validateEmail]
              }, null, 8, ["modelValue", "label-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "phoneNumber",
                modelValue: _ctx.form.phoneNumber,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.phoneNumber) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/phonenumber'),
                rules: [_ctx.validatePhoneNumber]
              }, null, 8, ["modelValue", "label-text", "rules"]),
              _createVNode(_component_varma_checkbox, {
                modelValue: _ctx.form.issuedOnBehalf,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.issuedOnBehalf) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('/blocks/addresschangeform/issuedonbehalf')), 1)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.form.issuedOnBehalf)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_varma_heading, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('/blocks/addresschangeform/issuerinformation')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_varma_input, {
                      ref: "issuedByName",
                      modelValue: _ctx.form.issuedByName,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.issuedByName) = $event)),
                      "label-text": _ctx.$t('/blocks/addresschangeform/name'),
                      required: "",
                      rules: [_ctx.validateField]
                    }, null, 8, ["modelValue", "label-text", "rules"]),
                    _createVNode(_component_varma_input, {
                      ref: "issuedByPhoneNumber",
                      modelValue: _ctx.form.issuedByPhoneNumber,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.issuedByPhoneNumber) = $event)),
                      "label-text": _ctx.$t('/blocks/addresschangeform/phonenumber')
                    }, null, 8, ["modelValue", "label-text"]),
                    _createVNode(_component_varma_input, {
                      ref: "issuedByDetails",
                      modelValue: _ctx.form.issuedByDetails,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.issuedByDetails) = $event)),
                      "label-text": _ctx.$t('/blocks/addresschangeform/details'),
                      type: "textarea",
                      "min-height": "120px"
                    }, null, 8, ["modelValue", "label-text"])
                  ], 64))
                : _createCommentVNode("", true),
              _createVNode(_component_varma_heading, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('/blocks/addresschangeform/addressinformation')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_varma_input, {
                ref: "previousAddress",
                modelValue: _ctx.form.previousAddress,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.previousAddress) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/previousaddress'),
                type: "textarea",
                "min-height": "96px",
                required: "",
                rules: [_ctx.validateField]
              }, null, 8, ["modelValue", "label-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "newAddress",
                modelValue: _ctx.form.newAddress,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.newAddress) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/newaddress'),
                type: "textarea",
                "min-height": "96px",
                required: "",
                rules: [_ctx.validateField]
              }, null, 8, ["modelValue", "label-text", "rules"]),
              _createVNode(_component_varma_input, {
                ref: "validFrom",
                modelValue: _ctx.form.validFrom,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.validFrom) = $event)),
                "label-text": _ctx.$t('/blocks/addresschangeform/validfrom'),
                placeholder: _ctx.$t('/common/dateplaceholder'),
                "max-width": "136px",
                required: "",
                rules: [_ctx.validateField, _ctx.validateDate]
              }, null, 8, ["modelValue", "label-text", "placeholder", "rules"]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_varma_button, { onClick: _ctx.next }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/addresschangeform/continue')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_varma_button, {
                  ghost: "",
                  onClick: _ctx.reset
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/addresschangeform/resetform')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ], 64))
          : (_ctx.state == 'Confirm')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t('/blocks/addresschangeform/confirmtitle')), 1),
                _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('/blocks/addresschangeform/personinformation')), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('/blocks/addresschangeform/name')), 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.form.name), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('/blocks/addresschangeform/ssn')), 1),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.form.ssn), 1)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('/blocks/addresschangeform/email')), 1),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.form.email), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('/blocks/addresschangeform/phonenumber')), 1),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.form.phoneNumber), 1)
                ]),
                (_ctx.form.issuedOnBehalf)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("h3", _hoisted_23, _toDisplayString(_ctx.$t('/blocks/addresschangeform/issuerinformation')), 1),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('/blocks/addresschangeform/name')), 1),
                        _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.form.issuedByName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('/blocks/addresschangeform/phonenumber')), 1),
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.form.issuedByPhonenumber), 1)
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('/blocks/addresschangeform/details')), 1),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.form.issuedByDetails), 1)
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("h3", _hoisted_33, _toDisplayString(_ctx.$t('/blocks/addresschangeform/addressinformation')), 1),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('/blocks/addresschangeform/previousaddress')), 1),
                  _createElementVNode("div", _hoisted_36, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.previousAddress.split('\n'), (l, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(l), 1))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t('/blocks/addresschangeform/newaddress')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.newAddress.split('\n'), (l, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(l), 1))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t('/blocks/addresschangeform/validfrom')), 1),
                  _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.form.validFrom), 1)
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _createVNode(_component_varma_button, { onClick: _ctx.submit }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/addresschangeform/submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_varma_button, {
                    ghost: "",
                    onClick: _ctx.back
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('/blocks/addresschangeform/editform')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ], 64))
            : (_ctx.state == 'Sent')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("div", _hoisted_43, [
                    _createVNode(_component_varma_icon, {
                      name: "check-circle",
                      size: "L",
                      color: "status-success",
                      weight: "bold"
                    })
                  ]),
                  _createElementVNode("h2", _hoisted_44, [
                    _renderSlot(_ctx.$slots, "sent-title", {}, undefined, true)
                  ]),
                  _createElementVNode("div", _hoisted_45, [
                    _renderSlot(_ctx.$slots, "sent-body", {}, undefined, true),
                    _createElementVNode("p", null, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('/blocks/addresschangeform/return')), 1)
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true)
      ])
    ])
  ]))
}