
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        isGdprAdmin: Boolean,
        identity: { type: Object, default: null }
    },
    computed: {
        authUrl(): string {
            return '/api/forms/auth/sign-in?returnUrl=' + encodeURIComponent(location.href.replace(location.origin, ''));
        },
        testAuthUrl(): string {
            return '/api/forms/auth/test-sign-in?returnUrl=' + encodeURIComponent(location.href.replace(location.origin, ''));
        },
    }
});

