
import { defineComponent, PropType } from 'vue';
import SearchRequest from '../search/VarmaSearchRequestModel';
import { SearchResult, FacetTag } from '../search/VarmaSearchResponseModel';
import VarmaCardSearchOptions from './VarmaCardSearchOptions.vue';
import VarmaCardSearchResults from './VarmaCardSearchResults.vue';
import { SortType } from '../search/VarmaSearchApiService';

export default defineComponent({
    components: {
        VarmaCardSearchOptions,
        VarmaCardSearchResults
    },
    props: {
        filtersTitle: { type: String, required: true },
        typeFilters: { type: Object as PropType<FacetTag[]>, required: true },
        tagFilters: { type: Object as PropType<FacetTag[]>, required: true },
        enabledTags: { type: Array as PropType<number[]>, required: true },
        preloadedResult: { type: Object as PropType<SearchResult>, required: true },
        language: { type: String, required: true },
        pageSize: { type: Number, required: true },
        loadMoreButtonText: { type: String, default: '' },
    },
    data() {
        return {
            typeIds: [],
            tagIds: []
        } as {
            typeIds: number[];
            tagIds: number[];
        };
    },
    computed: {
        anySelections(): boolean {
            return this.typeIds.length > 0 || this.tagIds.length > 0;
        },
        searchRequest(): SearchRequest {
            return {
                query: '',
                skip: 0,
                take: this.pageSize,
                sort: SortType.DateDesc,
                types: this.typeIds.length
                    ? this.typeIds
                    : this.typeFilters.map(f => f.id),
                tags: this.tagIds,
                tagsAny: this.enabledTags,
                language: this.language
            };
        }
    },
    watch: {
        typeIds(): void {
            this.updateQueryUrl();
        },
        tagIds(): void {
            this.updateQueryUrl();
        }
    },
    mounted() {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        if (urlParams.get('type')) {
            this.typeIds = this.parseParams(urlParams.getAll('type'), this.typeFilters);
        }

        if (urlParams.get('tag')) {
            this.tagIds = this.parseParams(urlParams.getAll('tag'), this.tagFilters);
        }
    },
    methods: {
        parseParams(params: string[], filterAgainst: FacetTag[]): number[] {
            if (!filterAgainst || !filterAgainst.length) {
                return [];
            }
            return params.map(p => parseInt(p)).filter(p => !isNaN(p)).filter(tag => filterAgainst.map(t => t.id).includes(tag));
        },
        createQueryString(ids: number[], key: string): string {
            return (ids && ids.length) ? key + '=' + ids.join('&' + key + '=')  : '';
        },
        updateQueryUrl(): void {
            const typeQuery: string = this.createQueryString(this.typeIds, 'type');
            const tagQuery: string = (typeQuery ? '&' : '') + this.createQueryString(this.tagIds, 'tag');
            history.replaceState(null, '', (typeQuery || tagQuery) ? '?' + typeQuery + tagQuery : '');
        }
    },
});
