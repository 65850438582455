
import { defineComponent, ref, PropType, watch, watchEffect } from 'vue';
import { v4 as uuid } from 'uuid';

export default defineComponent({    
    props: {
        modelValue: { type: String, default: '' },
        id: {
            type: String,
            default: (): string => {
                return String().concat('a',uuid()).replace(/-/g, '');               
            }
        },
        required: Boolean,
        rules: {
            type: Array as PropType<{ (v: string): boolean | string }[]>,
            default: (): { (v: string): boolean | string }[] => []
        },
        showLabel: { type: Boolean, default: true },
        labelText: { type: String, default: '' },
        helpText: { type: String, default: '' },
        unitText: { type: String, default: ''},
        hintText: { type: String, default: ''},
        type: { type:  String, default: 'text' },
        ariaLabel: { type: String, default: '' },
        ariaDescribedBy: { type: String, default: '' },
        placeholder: { type: String, default: ''},
        autocomplete: { type: String, default: null },
        minHeight: { type: String, default: null },
        maxWidth: { type: String, default: null },
        unitWidth: { type: String, default: null},
        additionalAriaLabelId: { type: String, default: ''},
        maxLength: { type: Number, default: null }
    },
    emits: ['update:modelValue', 'enter'],
    setup(props, context) {
        const internalValue = ref(props.modelValue);
        const validationError = ref(false as boolean | string);

        function validate(): boolean | string {
            let error: boolean | string = false;

            for (const rule of props.rules) {
                const result = rule(internalValue.value);

                if (result) {
                    error = result;
                    break;
                }
            }

            validationError.value = error;

            return error;
        }

        function update(): void {
            validate();
            context.emit('update:modelValue', internalValue);
        }

        watch(
            () => props.modelValue,
            () => {
                internalValue.value = props.modelValue;
            }
        );

        watchEffect(
            () => {
                if (validationError.value) {
                    validate();
                }
            }
        );

        function onEnter(): void {
            update();
            context.emit('enter');
        }
    
        return {
            internalValue,
            validationError,

            validate,
            update,
            onEnter
        };
    },
    computed: {
        labelId(): string {
            return ''.concat(this.id, '--label');
        },
        errorId(): string {
            return ''.concat(this.id, '--error');
        },
        hintId(): string {
            return ''.concat(this.id, '--hint');
        },
        unitId(): string {
            return ''.concat(this.id, '--unit');
        },
        ariaLabelledBy(): null | string {
            if (this.ariaLabel) {
                return null;
            }

            const ids: string[] = [];
            
            if (this.showLabel) {
                ids.push(this.labelId);
            }

            if (this.additionalAriaLabelId != '') {
                ids.push(this.additionalAriaLabelId);
            }

            if (this.unitText) {
                ids.push(this.unitId);
            }

            return ids.length > 0
                ? ids.join(' ')
                : null;
        },
        displayAriaDescribedBy(): null | string {
            const ids: string[] = [];

            if (this.ariaDescribedBy) {
                ids.push(this.ariaDescribedBy);
            }

            if (this.errorVisible) {
                ids.push(this.errorId);
            }

            if (this.hintVisible) {
                ids.push(this.hintId);
            }

            return ids.length > 0
                ? ids.join(' ')
                : null;
        },
        errorVisible(): boolean {
            return !!this.validationError && typeof this.validationError == 'string';
        },
        hintVisible(): boolean {
            return !this.errorVisible && this.hintText != '';
        }
    }, 
    methods: {
        focus(): void {
            (this.$refs['input'] as HTMLInputElement).focus();
        }
    }
});
