
import { defineComponent } from 'vue';
import { validateField, validateEmail, validatePhoneNumber } from '../../common/forms/validators';
import { PremisesContactFormData } from './PremisesContactFormData';
import VarmaFormGrey from '../../common/forms/VarmaFormGrey.vue';

export default defineComponent({
    components: { VarmaFormGrey },
    props: {
        isFloatingVersion: { type: Boolean, default: false },
        toId: { type: String, default: null }
    },
    emits: ['close'],
    setup() {
        return { validateField, validateEmail, validatePhoneNumber };
    },
    data() {
        return {
            form: {
                name: '',
                company: '',
                email: '',
                phone: '',
                message: ''
            } as PremisesContactFormData,
            handler: null
        };
    },
    methods: {
        setMessage(message: string) {
            this.form.message = message;
        },
        submit(): void {
            const data = { isFloatingVersion: this.isFloatingVersion, toId: this.toId, page: window.location.toString(), ...this.form };
            (this.$refs.varmaForm as typeof VarmaFormGrey).onSubmit(this.$refs, Object.keys(this.form), data, '/api/forms/premises-contact/');
        },
        reset(): void {
            this.form = {
                name: '',
                company: '',
                email: '',
                phone: '',
                message: ''
            } as PremisesContactFormData;

            (this.$refs.varmaForm as typeof VarmaFormGrey).reset();
            this.$nextTick(() => {
                (this.$refs.title as HTMLElement).focus();
            });
        },
        focusOnSuccessTitle(): void {
            this.$nextTick(() => {
                (this.$refs.successTitle as HTMLElement).focus();
            });
        }
    }
});
