
import { defineComponent, PropType } from 'vue';
import VarmaDialog from '../../common/dialog/VarmaDialog.vue';

interface Item {
    text: string;
    isBold: boolean;
}

export default defineComponent({
    components: {
        VarmaDialog
    },
    props: {
        imageUrl: { type: String, required: true },
        googleMapsUrl: { type: String, required: true },
        items: { type: Array as PropType<Item[]>, required: true }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        closeDialog(): void {
            (this.$refs.dialog as typeof VarmaDialog).close();
            this.isOpen = false;
        }
    }
});
