import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b07b192"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "aria-current", "lang"]
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return _withDirectives((_openBlock(), _createElementBlock("nav", {
    ref: "element",
    class: _normalizeClass(_ctx.cssClass)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("a", {
        ref_for: true,
        ref: "items",
        key: index,
        href: item.url,
        class: _normalizeClass([{ active: item.active }, "item"]),
        "aria-current": item.active ? 'true' : null,
        lang: _ctx.languageMenu ? item.name : null
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1),
        (item.active)
          ? (_openBlock(), _createBlock(_component_varma_icon, {
              key: 0,
              name: "check-circle",
              size: "S",
              weight: "bold",
              "vertical-align": "middle"
            }))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    }), 128))
  ], 2)), [
    [_vShow, _ctx.modelValue]
  ])
}