import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "dialogElement",
  class: "varma-dialog__content-inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, {
    modelValue: _ctx.innerShow,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerShow) = $event)),
    "focus-trap": _ctx.trapFocus,
    "focus-retain": true,
    "esc-to-close": _ctx.allowDismiss,
    "click-to-close": _ctx.allowDismiss,
    "lock-scroll": false,
    "overlay-class": "varma-dialog__overlay",
    "content-class": "varma-dialog__content",
    onClosed: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ], 512)
    ]),
    _: 3
  }, 8, ["modelValue", "focus-trap", "esc-to-close", "click-to-close", "onClosed"]))
}