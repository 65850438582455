
import { defineComponent, PropType } from 'vue';

interface MenuItem {
    name: string;
    url: string;
    active: boolean;
    targetFrame: string;
}

export default defineComponent({
    props: {
        modelValue: Boolean,
        items: { type: Array as PropType<MenuItem[]>, default: null },
        uppercase: Boolean,
        languageMenu: Boolean,
    },
    data() {
        return {
            focused: false
        };
    },
    mounted() {
        document.addEventListener('keydown', this.onkeydown);
    },
    computed: {
        cssClass(): string {
            return `dropdown ${this.uppercase ? 'dropdown--uppercase' : ''}`;
        }
    },
    methods: {
        onkeydown(ev: KeyboardEvent): void {
            if (!this.modelValue) {
                return;
            }

            let index = (this.$refs.items as HTMLElement[]).findIndex(i => i == document.activeElement);

            if (ev.key == 'Escape') {
                this.$emit('update:modelValue', false);
                return;
            } else if (ev.key == 'ArrowUp') {
                index -= 1;
                ev.stopPropagation();
                ev.preventDefault();
            } else if (ev.key == 'ArrowDown') {
                index += 1;
                ev.stopPropagation();
                ev.preventDefault();
            } else {
                return;
            }

            if (index < 0) {
                index = this.items.length - 1;
            } else if (index >= this.items.length) {
                index = 0;
            }

            (this.$refs.items as HTMLElement[])[index].focus();
        },
    }
});
