
import { defineComponent } from 'vue';
import axios from 'axios';

import { VarmaCardModel } from '../../common/card/VarmaCardModel';

function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
        return text;
    }

    return text.substring(0, Math.min(text.length - 3, maxLength - 3)) + '...';
}

const flocklerArticleMap: { [type: string]: (article: any) => VarmaCardModel } = {
    'tweet': (a) => {
        const bodyHtml = document.createElement('div');
        bodyHtml.innerHTML = a.attachments.tweet.oembed;
        const text = bodyHtml.getElementsByTagName('p')[0]?.innerText;

        return {
            typeText: 'Twitter',
            url: a.attachments.tweet.url,
            title: truncateText(text, 140),
            imageUrl: a.attachments.tweet.media_url,
        };
    },
    'instagram': (a) => ({
        typeText: 'Instagram',
        url: a.attachments.instagram_item.link,
        title: truncateText(a.attachments.instagram_item.caption, 140),
        imageUrl: a.cover_url,
    }),
    'facebook_post': (a) => ({
        typeText: 'Facebook',
        url: a.attachments.facebook_post.link,
        title: truncateText(a.attachments.facebook_post.message, 140),
        imageUrl: a.attachments.facebook_post.picture,
    }),
};

// Varma CSRF token on default axios instance causes problems with Flockler API
const flocklerAxios = axios.create();
delete flocklerAxios.defaults.headers.common['X-CSRF-Token'];

export default defineComponent({
    props: {
        count: { type: Number, required: true},
        siteId: { type: String, required: true },
        sectionId: { type: String, required: true },
        defaultImageUrl: { type: String, default: null },
    },
    data() {
        return {
            items: null,
            nextUrl: null,
        } as {
            items: null | VarmaCardModel[];
            nextUrl: null | string;
        };
    },
    async mounted() {
        this.nextUrl = `https://api.flockler.com/v1/sites/${this.siteId}/sections/${this.sectionId}/articles?count=${this.count}`;
        await this.load();
    },
    methods: {
        async load(): Promise<void> {
            const { data } = await flocklerAxios.get(this.nextUrl!);
            const items: VarmaCardModel[] = [];

            for (const srcArticle of data.articles) {
                const mapper = flocklerArticleMap[srcArticle.type];
                if (!mapper) {
                    continue;
                }

                const date = srcArticle.published_at
                    ? new Date(srcArticle.published_at)
                    : null;

                const dateText = date
                    ? `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
                    : null;

                const article: VarmaCardModel = {
                    dateText: dateText || undefined,
                    target: '_blank',
                    ...mapper(srcArticle)
                };

                if (!article.imageUrl) {
                    article.imageUrl = this.defaultImageUrl;
                }

                items.push(article);
            }

            this.nextUrl = data.pagination.older;

            if (!items.length) {
                return;
            }

            if (!this.items) {
                this.items = items;
            } else {
                this.items.push(...items);
            }
        }
    }
});
