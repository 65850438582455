import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title", "aria-hidden", "ariaLabel"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(["Streamline_Icon", _ctx.classes]),
    title: _ctx.title,
    "aria-hidden": _ctx.title || _ctx.ariaLabel ? false : true,
    ariaLabel: _ctx.ariaLabel
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
  ], 10, _hoisted_1))
}