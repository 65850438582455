import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1915a66a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-contain" }
const _hoisted_2 = { class: "grid-content prefer-center col8-width" }
const _hoisted_3 = { class: "career-stories-video-block__video-title" }
const _hoisted_4 = {
  key: 1,
  class: "career-stories-video-block__video-intro"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "grid-contain grid-contain--full-width-on-narrow" }
const _hoisted_7 = { class: "grid-content prefer-center col8-width career-stories-video-block__video" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "item-card__content" }
const _hoisted_10 = { class: "item-card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_heading = _resolveComponent("varma-heading")!
  const _component_varma_video = _resolveComponent("varma-video")!
  const _component_varma_card_slider = _resolveComponent("varma-card-slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.activeItem?.title)
          ? (_openBlock(), _createBlock(_component_varma_heading, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.activeItem?.title), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.activeItem?.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", {
                innerHTML: _ctx.activeItem?.description
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_varma_video, {
          id: _ctx.activeItem?.id,
          "iframe-src": _ctx.activeItem?.iframeSrc,
          "manage-cookies-url": _ctx.manageCookiesUrl
        }, null, 8, ["id", "iframe-src", "manage-cookies-url"])
      ])
    ]),
    _createVNode(_component_varma_card_slider, {
      "title-level": "m",
      "heading-classes": "grid-content prefer-center col8-width"
    }, {
      title: _withCtx(() => [
        _renderSlot(_ctx.$slots, "list-title", {}, undefined, true)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createElementVNode("button", {
              class: _normalizeClass(["item-card", { 'item-card--selected': _ctx.activeItemIndex === index }]),
              onClick: _withModifiers(($event: any) => (_ctx.activeItemIndex = index), ["prevent"])
            }, [
              _createElementVNode("div", {
                class: "item-card__image",
                style: _normalizeStyle({ 'background-image': 'url(' + item.previewImageUrl + ')' })
              }, null, 4),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1)
              ])
            ], 10, _hoisted_8)
          ]))
        }), 128))
      ]),
      _: 3
    })
  ], 64))
}