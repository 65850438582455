import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "podcast-block__embed"
}
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_cookie_check = _resolveComponent("varma-cookie-check")!
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (!_ctx.enabled)
    ? (_openBlock(), _createBlock(_component_varma_cookie_check, {
        key: 0,
        "manage-url": _ctx.manageCookiesUrl,
        "title-text": _ctx.$t('/blocks/podcast/missingcookieheading'),
        onAccepted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enabled = true))
      }, null, 8, ["manage-url", "title-text"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.embedVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "podcast-block__embed-preview",
              style: _normalizeStyle({ 'background-image': 'url(' + _ctx.previewImageUrl + ')'})
            }, [
              _createElementVNode("button", {
                "aria-label": _ctx.$t('/blocks/podcast/playpodcast'),
                class: "podcast-block__play-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.embedVisible = true))
              }, [
                _createVNode(_component_varma_icon, {
                  size: "XL",
                  color: "primary",
                  name: "play",
                  "vertical-align": "middle",
                  weight: "bold"
                })
              ], 8, _hoisted_2)
            ], 4))
          : (_openBlock(), _createElementBlock("iframe", {
              key: 1,
              scrolling: "no",
              frameborder: "no",
              allow: "autoplay",
              src: _ctx.iframeSrc,
              class: "podcast-block__embed-iframe"
            }, null, 8, _hoisted_3))
      ]))
}