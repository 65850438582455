import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-266dee62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "simple-slider__view" }
const _hoisted_2 = {
  ref: "innerEl",
  class: "simple-slider__inner"
}
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = {
  key: 0,
  class: "simple-slider__pages"
}
const _hoisted_6 = ["aria-label", "aria-current", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["simple-slider", { 'simple-slider--has-controls': _ctx.hasControls }]),
    onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startSwipe && _ctx.startSwipe(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          ref: "trackEl",
          class: "simple-slider__track",
          style: _normalizeStyle({ width: _ctx.items * 100 + '%' })
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4)
      ], 512),
      (_ctx.hasControls)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "simple-slider__button simple-slider__prev-button",
            "aria-label": _ctx.$t('/common/previous'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIndexRelative(-1)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "arrow-left-1",
              size: "S",
              weight: "bold",
              color: "black",
              "vertical-align": "middle",
              "aria-hidden": ""
            })
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.hasControls)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "simple-slider__button simple-slider__next-button",
            "aria-label": _ctx.$t('/common/next'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIndexRelative(1)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "arrow-right-1",
              size: "S",
              weight: "bold",
              color: "black",
              "vertical-align": "middle",
              "aria-hidden": ""
            })
          ], 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hasControls)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (_, i) => {
            return (_openBlock(), _createElementBlock("button", {
              key: i,
              class: _normalizeClass([{ active: i == _ctx.index }, "simple-slider__page-button"]),
              "aria-label": _ctx.$t('/common/showCard') + ' ' + (i + 1),
              "aria-current": i == _ctx.index,
              onClick: ($event: any) => (_ctx.setIndex(i))
            }, null, 10, _hoisted_6))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 34))
}