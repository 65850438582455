import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea166282"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "floating-contact-person-form__contact-form" }
const _hoisted_3 = { class: "floating-contact-person" }
const _hoisted_4 = { class: "floating-contact-person__person" }
const _hoisted_5 = { class: "floating-contact-person__title floating-contact-person__title--wide-screen" }
const _hoisted_6 = { class: "floating-contact-person__title floating-contact-person__title--narrow-screen" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "floating-contact-person__button--small-screen" }
const _hoisted_10 = ["aria-expanded"]
const _hoisted_11 = { class: "floating-contact-person__photo-container" }
const _hoisted_12 = { class: "floating-contact-person__button--wide-screen" }
const _hoisted_13 = ["aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_premises_contact_form = _resolveComponent("varma-premises-contact-form")!
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      class: "varma-dialog__overlay floating-contact-person-form__overlay",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = false))
    }, null, 512), [
      [_vShow, _ctx.active]
    ]),
    _createElementVNode("section", {
      "aria-label": _ctx.$t('/blocks/premises-floating-contact/aria-label'),
      class: "floating-contact-person-form"
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode(_component_varma_premises_contact_form, {
          ref: "form",
          "is-floating-version": true,
          "to-id": _ctx.toId,
          onClose: _ctx.toggle
        }, null, 8, ["to-id", "onClose"])
      ], 512), [
        [_vShow, _ctx.active]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('/blocks/premises-floating-contact/title-long')), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('/blocks/premises-floating-contact/title-short')), 1),
            _createElementVNode("a", {
              href: `tel:${_ctx.phone}`,
              class: "floating-contact-person__phone"
            }, _toDisplayString(_ctx.phone), 9, _hoisted_7),
            _createElementVNode("a", {
              href: `mailto:${_ctx.email}`,
              class: "floating-contact-person__email"
            }, [
              _createElementVNode("span", null, [
                _createVNode(_component_varma_icon, {
                  class: "floating-contact-person__email-icon",
                  size: "S",
                  color: "white",
                  name: "unread",
                  weight: "regular",
                  "vertical-align": "middle"
                })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.email), 1)
            ], 8, _hoisted_8),
            _createElementVNode("span", _hoisted_9, [
              _createElementVNode("button", {
                "aria-expanded": _ctx.active,
                class: "floating-contact-person__open-form-button varma-button",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
              }, [
                _createTextVNode(_toDisplayString(_ctx.$t('/blocks/premises-floating-contact/open-form')) + " ", 1),
                _createVNode(_component_varma_icon, {
                  name: "arrow-down",
                  size: "S",
                  weight: "bold",
                  color: "white",
                  "vertical-align": "middle",
                  class: _normalizeClass(["floating-contact-person__open-form-icon", { 'floating-contact-person__open-form-icon--open': _ctx.active }])
                }, null, 8, ["class"])
              ], 8, _hoisted_10)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "floating-contact-person__photo",
              style: _normalizeStyle({ 'background-image': 'url(' + _ctx.photo + ')' })
            }, null, 4)
          ])
        ]),
        _createElementVNode("span", _hoisted_12, [
          _createElementVNode("button", {
            "aria-expanded": _ctx.active,
            class: "floating-contact-person__open-form-button varma-button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('/blocks/premises-floating-contact/open-form')) + " ", 1),
            _createVNode(_component_varma_icon, {
              name: "arrow-down",
              size: "S",
              weight: "bold",
              color: "white",
              "vertical-align": "middle",
              class: _normalizeClass(["floating-contact-person__open-form-icon", { 'floating-contact-person__open-form-icon--open': _ctx.active }])
            }, null, 8, ["class"])
          ], 8, _hoisted_13)
        ])
      ])
    ], 8, _hoisted_1)
  ], 64))
}