
import { defineComponent, PropType } from 'vue';

import VarmaDialog from '@/components/common/dialog/VarmaDialog.vue';
import VarmaImageSlider from '@/components/features/images/VarmaImageSlider.vue';
import { isSmallScreen } from '@/utils/viewport';

interface Image {
    url: string;
    alt: string;
}

export default defineComponent({
    components: {
        VarmaDialog,
        VarmaImageSlider
    },
    props: {
        open: { type: Boolean, required: true },
        index: { type: Number, required: true },
        title: { type: String, default: null },
        images: { type: Array as PropType<Image[]>, default: (): Image[] => [] },
        returnFocus: { type: HTMLElement, default: null },
        premisesAreaText: { type: String, default: null },
        premisesPdfUrl: { type: String, default: null },
        premisesContact: { type: Boolean, default: false },
    },
    emits: ['close', 'contact', 'update:index'],
    data() {
        return {
            lastClicked: null,
        } as {
            lastClicked: HTMLElement | null;
        };
    },
    computed: {
        isSmallScreen(): boolean {
            return isSmallScreen.value;
        }
    },
    methods: {
        indexChange(index: number): void {
            this.$emit('update:index', index);
        },
        close(): void {
            (this.$refs.dialog as typeof VarmaDialog).close();  
        },
        onClose(): void {
            this.$emit('close');
        },
        openPremisesContact(): void {
            this.$emit('contact');
        },
    }
});
