export type VarmaIconSize = 'XS' | 'S' | 'M' | 'L' | 'XL';
export type VarmaIconWeight = 'regular' | 'bold' | 'light';
export type VarmaIconAlign = '' | 'top' | 'middle' | 'bottom';

export type IconProps = {
    regular?: any;
    bold?: any;
    light?: any;
};

export type VarmaIconName = 
'accounting-calculator' |
'add' |
'alert-triangle' |
'arrow-down' |
'arrow-left' |
'arrow-left-1' |
'arrow-right' |
'arrow-right-1' |
'business-contract-approve' |
'calendar' |
'check' |
'check-circle' |
'close' |
'cookie' |
'common-file-text' |
'earth' |
'expand' |
'expand-6' |
'facebook' |
'house' |
'information-circle' |
'instagram' |
'last' |
'laptop-user' |
'linked-in' |
'login' |
'menu' |
'messages-bubble-text' |
'mood-warning' |
'performance-increase-1' |
'pin-location-1' |
'play' |
'question-circle' |
'real-estate-dimensions-plan-1' |
'search' |
'screen' |
'shrink' |
'slideshare' |
'spotify' |
'team-meeting' |
'twitter' |
'unread' |
'youtube' |
'x';


export const ICON_MAP: { [key in VarmaIconName]: IconProps } = {
    'accounting-calculator': {
        regular: require('./streamline-icons/streamline-regular/money-payments-finance/accounting-billing/accounting-calculator.svg').default,
        bold: require('./streamline-icons/streamline-bold/money-payments-finance/accounting-billing/accounting-calculator.svg').default,
        light: require('./streamline-icons/streamline-light/accounting-calculator.svg').default,
    },
    'add': {
        regular: require('./streamline-icons/streamline-regular/interface-essential/add.svg').default,
    },
    'alert-triangle':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/alerts/alert-triangle.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/alerts/alert-triangle.svg').default
    },
    'arrow-down':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-down-1.svg').default
    },
    'arrow-left':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-left.svg').default,
        bold: require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-left.svg').default
    },
    'arrow-left-1':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-left-1.svg').default
    },
    'arrow-right':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-right.svg').default,
        bold: require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-right.svg').default
    },
    'arrow-right-1':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-right-1.svg').default
    },
    'business-contract-approve':
    {
        regular: require('./streamline-icons/streamline-regular/business-products/business-deals/business-contract-approve.svg').default,
        bold: require('./streamline-icons/streamline-bold/business-products/business-deals/business-contract-approve.svg').default,
        light: require('./streamline-icons/streamline-light/business-contract-approve.svg').default,
    },
    'calendar':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/date-calendar/calendar-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/date-calendar/calendar-3.svg').default,
        light: require('./streamline-icons/streamline-light/calendar-1.svg').default,
    },    
    'check':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/form-validation/check-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/form-validation/check-1.svg').default
    },
    'check-circle':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/form-validation/check-circle-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/form-validation/check-circle-1.svg').default
    },
    'close':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/form-validation/close.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/form-validation/close.svg').default
    },
    'cookie':
    {
        regular: require('./streamline-icons/streamline-regular/food-drinks/cakes-candies/cookie.svg').default,
        bold: require('./streamline-icons/streamline-bold/food-drinks/cakes-candies/cookie.svg').default,
        light: require('./streamline-icons/streamline-light/cookie.svg').default,
    },
    'common-file-text':
    {
        regular: require('./streamline-icons/streamline-regular/files-folders/common-files/common-file-text.svg').default,
        bold: require('./streamline-icons/streamline-bold/files-folders/common-files/common-file-text.svg').default
    },
    'earth':
    {
        regular: require('./streamline-icons/streamline-regular/maps-navigation/earth/earth-3.svg').default,
        bold: require('./streamline-icons/streamline-bold/maps-navigation/earth/earth-3.svg').default,      
    },
    'expand':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg').default,
        bold:  require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-down-1.svg').default       
    },
    'expand-6':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/expand-retract/expand-6.svg').default,
        bold:  require('./streamline-icons/streamline-bold/interface-essential/expand-retract/expand-6.svg').default     
    },
    'facebook':
    {
        regular: require('./streamline-icons/streamline-regular/logos/social-medias/social-media-facebook.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/social-medias/social-media-facebook.svg').default,      
    },
    'house':
    {
        regular: require('./icons/house-1.svg').default,
    },
    'information-circle':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/alerts/information-circle.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/alerts/information-circle.svg').default,        
    },
    'instagram':
    {
        regular: require('./streamline-icons/streamline-regular/logos/photos-images/social-instagram.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/photos-images/social-instagram.svg').default,      
    },
    'last':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-button-right-1.svg').default,
        bold:  require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-button-right-1.svg').default        
    },
    'laptop-user':
    {
        regular: require('./streamline-icons/streamline-regular/programing-apps-websites/apps/laptop-user.svg').default,
        bold: require('./streamline-icons/streamline-bold/programing-apps-websites/apps/laptop-user.svg').default,        
        light: require('./streamline-icons/streamline-light/laptop-user.svg').default,
    },
    'linked-in':
    {
        regular: require('./streamline-icons/streamline-regular/logos/professional-networks/professional-network-linkedin.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/professional-networks/professional-network-linkedin.svg').default,      
    },
    'login':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/login-logout/login-key-2.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/login-logout/login-key-2.svg').default,
    },
    'menu':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/menu/navigation-menu.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/menu/navigation-menu.svg').default        
    },
    'messages-bubble-text':
    {
        regular: require('./icons/messages-bubble-text-1.svg').default,
    },
    'mood-warning':
    {
        regular: require('./streamline-icons/streamline-regular/social-medias-rewards-rating/mood/mood-warning.svg').default,
        bold: require('./streamline-icons/streamline-bold/social-medias-rewards-rating/mood/mood-warning.svg').default,      
        light: require('./streamline-icons/streamline-light/mood-warning.svg').default,      
    },
    'performance-increase-1':
    {
        light: require('./streamline-icons/streamline-light/performance-increase-1.svg').default,
    },
    'pin-location-1':
    {
        regular: require('./streamline-icons/streamline-regular/maps-navigation/pins/pin-location-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/maps-navigation/pins/pin-location-1.svg').default,
        light: require('./streamline-icons/streamline-light/pin-location-1.svg').default,
    },
    'play':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/multimedia-controls/button-play-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/multimedia-controls/button-play-1.svg').default        
    },
    'question-circle': {
        regular: require('./streamline-icons/streamline-regular/interface-essential/alerts/question-circle.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/alerts/question-circle.svg').default,
        light: require('./streamline-icons/streamline-light/question-circle.svg').default,
    },
    'real-estate-dimensions-plan-1':
    {
        regular: require('./streamline-icons/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-plan-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/real-estate/maps-dimensions/real-estate-dimensions-plan-1.svg').default        
    },
    'search':
    {
        regular: require('./streamline-icons/streamline-regular/interface-essential/search/search.svg').default,
        bold: require('./streamline-icons/streamline-bold/interface-essential/search/search.svg').default        
    },
    'screen':
    {
        regular: require('./streamline-icons/streamline-regular/computers-devices-electronics/screens/screen-1.svg').default,
        bold: require('./streamline-icons/streamline-bold/computers-devices-electronics/screens/screen-1.svg').default,        
    },
    'shrink':
    {
        regular: require('./streamline-icons/streamline-regular/arrows-diagrams/arrows/arrow-up-1.svg').default,
        bold:  require('./streamline-icons/streamline-bold/arrows-diagrams/arrows/arrow-up-1.svg').default       
    },
    'slideshare':
    {
        regular: require('./streamline-icons/streamline-regular/logos/social-medias/social-media-slide-share.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/social-medias/social-media-slide-share.svg').default,      
    },
    'spotify': 
    {
        regular: require('./streamline-icons/streamline-regular/logos/music/social-music-spotify.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/music/social-music-spotify.svg').default,        
    },
    'team-meeting':
    {
        regular: require('./streamline-icons/streamline-regular/work-office-companies/meetings-collaboration/team-meeting.svg').default,
        bold: require('./streamline-icons/streamline-bold/work-office-companies/meetings-collaboration/team-meeting.svg').default,      
        light: require('./streamline-icons/streamline-light/team-meeting.svg').default,
    },
    'twitter':
    {
        regular: require('./streamline-icons/streamline-regular/logos/social-medias/social-media-twitter.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/social-medias/social-media-twitter.svg').default,      
    },
    'unread':
    {
        regular: require('./streamline-icons/streamline-regular/emails/email-actions/email-action-unread.svg').default,
        bold: require('./streamline-icons/streamline-bold/emails/email-actions/email-action-unread.svg').default
    },
    'youtube':
    {
        regular: require('./streamline-icons/streamline-regular/logos/videos/social-video-youtube-clip.svg').default,
        bold: require('./streamline-icons/streamline-bold/logos/videos/social-video-youtube-clip.svg').default
    },
    'x':
    {
        bold: require('./icons/x.svg').default,
    },
};
