
import { defineComponent, PropType } from 'vue';
import { language } from '@/utils/language';

interface Source {
    dataCreatedTimestamp: string;
    specialistServices: {
        operatingYear: number;
        servicesInitiated: number;
        servicesBreakdown: SourceShare[];
        actionTargetGroups: SourceShare[];
    };

    actions: {
        operatingYear: number;
        numberOfActions: number;
        totalSumEuros: number;
        actionThemes: SourceShare[];
        targetedRisks: SourceShare[];
        indicators: SourceWeight[];
    }
}

interface SourceShare {
    name: string;
    share: number;
}

interface SourceWeight {
    name: number;
    weight: number;
}

const colors = [
    '#06438E',
    '#289ECC',
    '#0B4E50',
    '#8596AB',
    '#3D474D',
    '#D28013',
    '#032140',
    '#729A9D',
    '#40261F',
    '#6E97C9',
    '#062E63',
    '#8B9498',
];

export default defineComponent({
    props: {
        model: { type: Object as PropType<Source>, required: true },
        feedbackScore: { type: Number, required: true },
        actionThemesText: { type: Object as PropType<{ [key: string]: string }>, required: true },
        indicatorsText: { type: Object as PropType<{ [key: string]: string }>, required: true },
    },
    data() {
        return {
            source: this.model
        } as {
            source: Source;
        };
    },
    computed: {
        language(): string {
            return language;
        },
        sourceDateText(): string {
            if (!this.source) {
                return '';
            }

            const date = new Date(this.source.dataCreatedTimestamp);

            const dateText = date.getDate().toString().padStart(2, '0') + '.' +
                (date.getMonth() + 1).toString().padStart(2, '0') + '.' +
                date.getFullYear().toString();

            return this.$t('/blocks/workabilityreport/datacreateddate').toString().replace('{value}', dateText);
        },
        feedbackScoreText(): null | string {
            if (!this.feedbackScore) {
                return null;
            }

            return this.feedbackScore.toLocaleString(this.language);
        },
        actionsTotalSumEurosText(): [string, string] {
            const value = this.model.actions.totalSumEuros;

            if (value < 1000000) {
                return [Math.round(value).toLocaleString(this.language), this.$t('/blocks/workabilityreport/eurossuffix')];
            } else {
                return [(Math.round((value / 1000000 + Number.EPSILON) * 100) / 100).toLocaleString(this.language), this.$t('/blocks/workabilityreport/millioneurossuffix')];
            }
        },
        servicesBreakdownChart(): any {
            return this.generateTreemapChart(this.source.specialistServices.servicesBreakdown);
        },
        targetedRisksChart(): any {
            return this.generateTreemapChart(this.source.actions.targetedRisks);
        },
        actionTargetGroupsChart(): any {
            return this.generatePieChart(this.source.specialistServices.actionTargetGroups);
        },
        actionThemesChart(): any {
            return this.generatePieChart(this.source.actions.actionThemes, this.actionThemesText);
        },
        indicatorsChart(): any {
            const indicators = this.source.actions.indicators.filter(i => i.weight > 0);
            const total = indicators.reduce((v, i) => v + i.weight, 0);

            return this.generatePieChart(
                indicators.map(i => ({ name: i.name.toString(), share: Math.round(i.weight / total * 100) })),
                this.indicatorsText);
        }
    },
    methods: {
        generateTreemapChart(source: SourceShare[]): any {
            source = [...source].sort((a, b) => b.share - a.share);

            const data = [...source]
                .sort((a, b) => b.share - a.share)
                .map((i, index) => ({
                    name: i.name,
                    value: i.share,
                    color: colors[index],
                    dataLabels: {
                        style: {
                            color: '#FFFFFF'
                        }
                    }
                }));

            return {
                chart: {
                    spacing: [12, 8, 8, 8],
                    events: {
                        load(this: any): void {
                            // Highcharts treemap data label layout generation is very broken, and
                            // in order to achieve the text layout we want, we have to set a lot of
                            // css properties manually on the labels.
                            for (const p of this.series[0].points) {
                                const pointAttrs = p.graphic.element.attributes;
                                const pointLeft = pointAttrs.x.value;
                                const pointTop = pointAttrs.y.value;
                                const pointWidth = pointAttrs.width.value;
                                const pointHeight = pointAttrs.height.value;

                                const labelDiv = p.dataLabel.div;
                                const labelSpan = labelDiv.children[0];

                                labelDiv.style.left = pointLeft + 'px';
                                labelDiv.style.top = pointTop + 'px';
                                labelDiv.style.width = pointWidth + 'px';
                                labelDiv.style.height = pointHeight + 'px';

                                labelSpan.style.boxSizing = 'border-box';
                                labelSpan.style.padding = '8px';
                                labelSpan.style.textOverflow = 'ellipsis';
                                labelSpan.style.whiteSpace = 'normal';
                                labelSpan.style.wordBreak = 'break-word';
                            }
                        }
                    }
                },
                title: {
                    text: null,
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    formatter: function (this: any): string {
                        return '<div style="background: rgba(255, 255, 255, 0.8); border: 1px solid #000; border-radius: 3px; padding: .75em .5em;">' +
                                '<span style="font-weight: bold;">' + this.point.name + '</span>: ' + this.point.value.toLocaleString(this.language) + '%' +
                            '</div>';
                    },
                    backgroundColor: 'rgba(0,0,0,0)',
                    shadow: false,
                    borderWidth: 0,
                    useHTML: true,
                },
                series: [{
                    type: 'treemap',
                    layoutAlgorithm: 'squarified',
                    data: data,
                    dataLabels: {
                        align: 'left',
                        verticalAlign: 'top',
                        padding: 0,
                        style: {
                            fontSize: '14px',
                            fontWeight: 'normal',
                            textOverflow: 'clip',
                        },
                        useHTML: true,
                    },
                    levels: [{
                        level: 1,
                        borderColor: '#fff',
                        borderWidth: 4,
                    }],
                }],
            };
        },
        generatePieChart(source: SourceShare[], text?: { [key: string]: string }): any {
            const data = [...source]
                .sort((a, b) => b.share - a.share)
                .map(s => ({
                    name: (text ? text[s.name] : null) || s.name,
                    y: s.share,
                }));

            return {
                chart: {
                    type: 'pie',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null,
                },
                colors: colors,
                tooltip: {
                    formatter: function (this: any): string {
                        return '<span style="font-weight: bold;">' + this.point.name + '</span>: ' +
                            this.point.y.toLocaleString(this.language) + '%';
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: false,
                        borderWidth: 4,
                        innerSize: '55%',
                    }
                },
                series: [{
                    data: data,
                    dataLabels: {
                        connectorColor: '#363D45',
                        padding: 0,
                        style: {
                            fontSize: '14px',
                            fontWeight: 'normal',
                            textOutline: 'none',
                            textOverflow: 'clip',
                        },
                    },
                }]
            };
        },
    }
});
