import { ref } from 'vue';

function isSmall(): boolean {
    return window.innerWidth < 520;
}

export const viewportWidth = ref<number>(window.innerWidth);
export const isSmallScreen = ref<boolean>(isSmall());

window.addEventListener('resize', () => { 
    viewportWidth.value = window.innerWidth;
    isSmallScreen.value = isSmall();
});