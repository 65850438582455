
import { defineComponent } from 'vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default defineComponent({
    props: {
        logoHref: { type: String, default: null },
        logoSrc: { type: String, default: null },
        backgroundSrc: { type: String, default: null },
    },
    data() {
        return {
            menuOpen: false,
            tabbedBack: false
        } as {
            menuOpen: boolean;
            tabbedBack: boolean;
        };
    },
    mounted() {
        this.$watch('menuOpen', (open: boolean) => {
            if (open && window.innerWidth < 680) { //change breakpoint if varma style breakpoint changes
                this.$nextTick(() => disableBodyScroll(this.$refs.menuEl as HTMLElement));
            } else {
                clearAllBodyScrollLocks();
            }
        });

        document.addEventListener('keyup', this.handleKeyUp);
        document.addEventListener('keydown', this.markTabDirection);
        document.addEventListener('focusin', this.handleFocusin);
    },
    methods: {
        toggleMenu(): void {
            this.menuOpen = !this.menuOpen;
        },
        handleKeyUp(ev: KeyboardEvent): void {
            if (ev.code === 'Escape') {
                this.menuOpen = false;
            }
        },
        markTabDirection(ev: KeyboardEvent): void {
            if (ev.code === 'Tab') {
                this.tabbedBack = ev.shiftKey;
            }
        },
        handleFocusin(ev: FocusEvent): void {
            if (this.menuOpen) {
                let element = ev.target as Node;

                while (element !== null) {
                    if (element == this.$refs.headerEl ||
                        element == this.$refs.menuEl) {
                        return;
                    }
                    element = element.parentElement as Node;
                }

                if (this.tabbedBack && (!ev.relatedTarget || (ev.relatedTarget as HTMLElement).id === (this.$refs.logo as HTMLElement).id)) {
                    ((this.$refs.menuEl as HTMLElement).lastElementChild?.firstChild as HTMLElement).focus();
                } else {
                    (this.$refs.logo as HTMLElement).focus();
                }
            }
        },
    }
});
