
import { defineComponent, PropType, reactive } from 'vue';
import { RehabPartner } from './RehabPartner';

export default defineComponent({
    props: {
        rehabPartners: { type: Array as PropType<Array<RehabPartner>>, default: (): Array<RehabPartner> => [] },
        municipalitiesLabel: { type: String, default: '' },
        languagesLabel: { type: String, default: '' },
        clearFilter: { type: String, default: '' }
    },
    setup(props) {
        const allMunicipalities = new Set();
        const allLanguages = new Set();

        if (props.rehabPartners) {
            props.rehabPartners.forEach(partner => partner.municipalities.split(',').forEach((district: string) => allMunicipalities.add(district)));
            props.rehabPartners.forEach(partner => partner.languages.split(',').forEach((language: string) => allLanguages.add(language)));
        }

        const districtOptions = [...allMunicipalities].map(district => { 
            return { 
                value: district, 
                text: (district as string).split(';')[(window.VarmaI18nLocale === 'sv' ? 1 : 0)] 
            }; 
        }).sort((a, b) => a.text.localeCompare(b.text, 'fi'));

        const languageOptions = reactive([...allLanguages].map(language => { 
            return { value: language, selected: false }; 
        }));

        return {
            districtOptions,
            languageOptions
        };
    },
    data() {
        return {
            selectedMunicipality: ''
        } as {
            selectedMunicipality: string;
        };
    },
    computed: {
        filteredPartners(): Array<RehabPartner> {
            let filteredPartners = [...this.rehabPartners];

            if (this.selectedMunicipality) {
                filteredPartners = filteredPartners
                    .filter((partner: RehabPartner) => partner.municipalities.includes(this.selectedMunicipality));
            }

            if (this.selectedLanguages.length > 0) {
                filteredPartners = filteredPartners
                    .filter((partner: RehabPartner) => this.selectedLanguages.some(language => partner.languages.includes(language.value)));
            }

            return filteredPartners;
        },
        hasFilter(): boolean {
            return !!this.selectedMunicipality || this.selectedLanguages.length > 0;
        },
        selectedLanguages(): Array<any> {
            return this.languageOptions.filter(lang => lang.selected);
        },
        hasVisiblePartners(): boolean {
            return this.filteredPartners.length > 0;
        }
    },
    methods: {
        clear(): void {
            this.selectedMunicipality = '';
            this.languageOptions.forEach(element => {
                element.selected = false;
            });
        }
    }
});

