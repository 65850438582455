import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66c68023"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "map-modal__toggle-text" }
const _hoisted_2 = { class: "premises-map-modal__container" }
const _hoisted_3 = { class: "map-modal__container" }
const _hoisted_4 = { class: "map-modal__controls" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "map-modal__title" }
const _hoisted_7 = { class: "map-modal__details" }
const _hoisted_8 = { class: "map-modal__items" }
const _hoisted_9 = { class: "map-modal__link" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "map-modal__link-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_dialog = _resolveComponent("varma-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      ref: "openDialogButton",
      class: "map-modal__toggle",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isOpen = !_ctx.isOpen), ["prevent"]))
    }, [
      _createVNode(_component_varma_icon, {
        name: "pin-location-1",
        weight: "light",
        size: "M",
        "vertical-align": "middle"
      }),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('/blocks/premisesmapmodal/showlocationonmap')), 1)
    ], 512),
    _createElementVNode("div", null, [
      _createVNode(_component_varma_dialog, {
        ref: "dialog",
        show: _ctx.isOpen,
        "allow-dismiss": true,
        "open-dialog-button": _ctx.$refs.openDialogButton,
        onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpen = false))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "map-modal__close-button",
                  "aria-label": _ctx.$t('/common/close'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog()))
                }, [
                  _createVNode(_component_varma_icon, {
                    name: "close",
                    weight: "bold",
                    size: "M",
                    color: "black"
                  })
                ], 8, _hoisted_5)
              ]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('/blocks/premisesmapmodal/title')), 1),
              _createElementVNode("div", {
                style: _normalizeStyle({ 'background-image': 'url(' + _ctx.imageUrl + ')' }),
                class: "map-modal__image"
              }, null, 4),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: _normalizeClass([{ 'map-modal__item--bold': item.isBold }, "map-modal__item"])
                      }, _toDisplayString(item.text), 3))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("a", {
                    href: _ctx.googleMapsUrl,
                    target: "_blank"
                  }, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('/blocks/premisesmapmodal/openlocationingooglemaps')), 1),
                    _createVNode(_component_varma_icon, {
                      name: "expand-6",
                      weight: "regular",
                      size: "M",
                      "vertical-align": "middle",
                      title: _ctx.$t('/common/textlink/external')
                    }, null, 8, ["title"])
                  ], 8, _hoisted_10)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["show", "open-dialog-button"])
    ])
  ], 64))
}