import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3857abac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-search-results" }
const _hoisted_2 = {
  key: 0,
  class: "card-search-results__title"
}
const _hoisted_3 = {
  key: 1,
  class: "card-search-results__count"
}
const _hoisted_4 = { class: "card-search-results__list" }
const _hoisted_5 = { class: "card-search-results__list-items" }
const _hoisted_6 = { class: "card-search-results__bottom-buttons-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_article_card = _resolveComponent("varma-article-card")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.displayCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('/search/resultsfound')) + " " + _toDisplayString(_ctx.searchResult.totalCount) + " " + _toDisplayString(_ctx.$t('/search/resultunits')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResult.hits, (item, index) => {
          return (_openBlock(), _createBlock(_component_varma_article_card, {
            key: item.contentId,
            ref_for: true,
            ref: element => { _ctx.cards[index] = element },
            article: item
          }, null, 8, ["article"]))
        }), 128))
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createVNode(_component_varma_button, {
        ghost: "",
        onClick: _ctx.showMore
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.loadMoreButtonText), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 512), [
      [_vShow, _ctx.searchResult.hasMore]
    ])
  ]))
}