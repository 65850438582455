import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "rehab-partners" }
const _hoisted_2 = { class: "rehab-partners__search-container" }
const _hoisted_3 = { class: "rehab-partners__filters" }
const _hoisted_4 = {
  for: "languages-select",
  class: "rehab-partners__label"
}
const _hoisted_5 = {
  id: "languages-select",
  class: "rehab-partners__language-selection"
}
const _hoisted_6 = {
  key: 0,
  class: "rehab-partners__clear-filter"
}
const _hoisted_7 = ["href", "title"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "rehab-partners__link-text" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_select = _resolveComponent("varma-select")!
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_chip = _resolveComponent("varma-chip")!
  const _component_varma_button = _resolveComponent("varma-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_varma_select, {
          modelValue: _ctx.selectedMunicipality,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMunicipality) = $event)),
          class: "rehab-partners__select",
          options: _ctx.districtOptions,
          "label-text": _ctx.municipalitiesLabel
        }, null, 8, ["modelValue", "options", "label-text"]),
        _createElementVNode("span", null, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.languagesLabel), 1),
          _createElementVNode("span", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageOptions, (language, index) => {
              return (_openBlock(), _createBlock(_component_varma_chip, {
                key: 'language' + index,
                modelValue: language.selected,
                "onUpdate:modelValue": ($event: any) => ((language.selected) = $event),
                class: "rehab-partners__language",
                role: "checkbox"
              }, {
                default: _withCtx(() => [
                  (language.selected)
                    ? (_openBlock(), _createBlock(_component_varma_icon, {
                        key: 0,
                        name: "check",
                        size: "XS",
                        weight: "bold",
                        color: "white"
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('/common/language/' + language.value)), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"]))
            }), 128))
          ])
        ])
      ]),
      (_ctx.hasFilter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_varma_button, {
              ghost: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clear()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.clearFilter), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({'rehab-partners__partners-container': _ctx.hasVisiblePartners})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPartners, (partner, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: 'partner' + index,
          class: "rehab-partners__partner",
          href: partner.url,
          target: "_blank",
          title: _ctx.$t('/common/textlink/external')
        }, [
          _createElementVNode("img", {
            src: partner.logoUrl,
            "aria-hidden": "true",
            class: "rehab-partners__logo"
          }, null, 8, _hoisted_8),
          _createElementVNode("div", _hoisted_9, _toDisplayString(partner.urlText), 1)
        ], 8, _hoisted_7))
      }), 128)),
      (!_ctx.hasVisiblePartners)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('/search/resultsfound') + ' 0 ' + _ctx.$t('/search/resultunits')), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}