
import { defineComponent, PropType } from 'vue';
import { FacetTag } from '../search/VarmaSearchResponseModel';

export default defineComponent({
    props: {
        title: { type: String, required: true },
        typeFilters: { type: Object as PropType<FacetTag[]>, required: true },
        tagFilters: { type: Object as PropType<FacetTag[]>, required: true },
        typeIds: { type: Array as PropType<Array<number>>, required: true },
        tagIds: { type: Array as PropType<Array<number>>, required: true },
        hideTypes: Boolean
    },
    emits: ['update:typeIds', 'update:tagIds'],
    computed: {
        anyFiltersSelected(): boolean {
            return this.typeIds.length > 0 || this.tagIds.length > 0;
        }
    },
    methods: {
        clearFilters(): void {
            this.$emit('update:typeIds', []);
            this.$emit('update:tagIds', []);
        },
        typeSelected(id: number): boolean {
            return this.typeIds.indexOf(id) !== -1;
        },
        tagSelected(id: number): boolean {
            return this.tagIds.indexOf(id) !== -1;
        },
        changeType(item: number, value: boolean): void {
            const typeIds = [ ...this.typeIds ];
            this.setItemInArray(typeIds, item, value);
            this.$emit('update:typeIds', typeIds);
        },
        changeTag(item: number, value: boolean): void {
            const tagIds = [ ...this.tagIds ];
            this.setItemInArray(tagIds, item, value);
            this.$emit('update:tagIds', tagIds);
        },
        setItemInArray(array: number[], item: number, set: boolean): void {
            const index = array.indexOf(item);

            if (index === -1 && set) {                
                array.push(item);
            } else if (index !== -1 && !set) {
                array.splice(index, 1);
            }
        }
    }
});
