
import { defineComponent, PropType } from 'vue';

import { language } from '@/utils/language';
import VarmaImageGalleryModal from '@/components/features/images/VarmaImageGalleryModal.vue';
import VarmaVerticalCollapse from '@/components/common/accordions/varma-vertical-collapse/VarmaVerticalCollapse.vue';

interface Space {
    title: string;
    description: string;
    available: null | string;
    area: number;
    pdfUrl: string;
    images: { url: string; alt: string; }[];
}

export default defineComponent({
    components: {
        VarmaImageGalleryModal,
        VarmaVerticalCollapse
    },
    props: {
        spaces: { type: Array as PropType<Space[]>, default: null },
        contactOpenFunctionName: { type: String, default: null },
        contactText: { type: String, default: null },
        contactButtonText: { type: String, default: null },
    },
    data() {
        return {
            isOpen: true,
            activeItemIndex: null,
            lastClicked: null,
            imageIndex: 0,
            tabbedBack: false,
        } as {
            isOpen: boolean;
            activeItemIndex: number | null;
            lastClicked: HTMLElement | null;
            imageIndex: number,
            tabbedBack: boolean,
        };
    },
    computed: {
        contactClass(): string {
            const remL = this.spaces.length % 3;
            const spanL = remL == 0 
                ? 3
                : 3 - remL;

            const remM = this.spaces.length % 2;
            const spanM = remM == 0
                ? 2
                : 1;

            return `free-spaces__contact free-spaces__contact--l-${spanL} free-spaces__contact--m-${spanM}`;
        },
        activeItem(): Space | null {
            if (this.activeItemIndex === null) {
                return null;
            }

            return this.spaces[this.activeItemIndex];
        },
    },
    mounted() {
        if (window.innerWidth < 520) {
            this.isOpen = false;
        }
    },
    methods: {
        formatArea(area: number): string {
            return area.toLocaleString(language) + ' m²';
        },
        openContact(text?: string): void {
            if (this.contactOpenFunctionName) {
                (window[this.contactOpenFunctionName as any] as any)(text);
            }
        },
        openDialog(index: number): void {
            this.lastClicked = (this.$refs.dialogOpener as HTMLInputElement[])[index];
            this.activeItemIndex = index;
        },
        contactActiveItem(): void {
            const item = this.activeItem;
            this.activeItemIndex = null;
            this.openContact(`${item?.title} ${item?.area.toLocaleString(language)} m²`);
        },
        contactIndex(index: number): void {
            const item = this.spaces[index];
            this.openContact(`${item?.title} ${item?.area.toLocaleString(language)} m²`);
        },
    }
});
