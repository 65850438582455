import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2264fc91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cookie-select" }
const _hoisted_2 = {
  key: 0,
  class: "cookie-select__title"
}
const _hoisted_3 = { class: "cookie-select__info" }
const _hoisted_4 = { class: "cookie-select__checkbox-heading cookie-select__checkbox-heading--disabled" }
const _hoisted_5 = { class: "cookie-select__checkbox-text" }
const _hoisted_6 = { class: "cookie-select__checkbox-heading" }
const _hoisted_7 = { class: "cookie-select__checkbox-text" }
const _hoisted_8 = { class: "cookie-select__checkbox-heading" }
const _hoisted_9 = { class: "cookie-select__checkbox-text" }
const _hoisted_10 = { class: "cookie-select__save-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_checkbox = _resolveComponent("varma-checkbox")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_feedback = _resolveComponent("varma-feedback")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "cookie-select-info", {}, undefined, true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_varma_checkbox, {
        modelValue: _ctx.mandatory,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mandatory) = $event)),
        disabled: "",
        "allow-text-wrap": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.mandatoryHeading), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mandatoryText), 1)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("hr", {
        class: _normalizeClass(["cookie-select__divider", {'cookie-select__divider--dark': _ctx.darkDivider }])
      }, null, 2),
      _createVNode(_component_varma_checkbox, {
        modelValue: _ctx.analytics,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.analytics) = $event)),
        "allow-text-wrap": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.analyticsHeading), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.analyticsText), 1)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("hr", {
        class: _normalizeClass(["cookie-select__divider", {'cookie-select__divider--dark': _ctx.darkDivider }])
      }, null, 2),
      _createVNode(_component_varma_checkbox, {
        modelValue: _ctx.marketing,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.marketing) = $event)),
        "allow-text-wrap": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.marketingHeading), 1),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.marketingText), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_varma_button, {
        primary: "",
        small: "",
        onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.saveCookies(); _ctx.executeScripts();})
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.saveButton), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_varma_feedback, {
      toast: "",
      timeout: "",
      type: _ctx.feedbackType,
      visible: _ctx.feedbackVisible,
      title: _ctx.cookiesSavedMessage,
      onHidden: _cache[4] || (_cache[4] = ($event: any) => (_ctx.feedbackVisible = false))
    }, null, 8, ["type", "visible", "title"])
  ]))
}