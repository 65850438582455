import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-127e6e2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "dialogContent",
  class: "item-modal__container"
}
const _hoisted_2 = { class: "item-modal__header" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "item-modal__title" }
const _hoisted_5 = { class: "item-modal__header-buttons" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = { class: "item-modal__close-footer" }
const _hoisted_9 = { class: "item-modal__close-footer-button-text" }
const _hoisted_10 = { class: "item-modal__inline-images" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = {
  key: 0,
  class: "item-modal__inline-images-close"
}
const _hoisted_13 = { class: "item-modal__inline-images-close-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_image_slider = _resolveComponent("varma-image-slider")!
  const _component_varma_dialog = _resolveComponent("varma-dialog")!

  return (_openBlock(), _createBlock(_component_varma_dialog, {
    ref: "dialog",
    show: _ctx.open,
    "allow-dismiss": true,
    "open-dialog-button": _ctx.returnFocus,
    "trap-focus": true,
    onOnClose: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            ref: "firstDialogElement",
            class: "item-modal__close-button",
            "aria-label": _ctx.$t('/common/close'),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_varma_icon, {
              name: "close",
              weight: "bold",
              size: "M",
              color: "black"
            })
          ], 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title) + " " + _toDisplayString(_ctx.premisesAreaText), 1),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.premisesContact)
              ? (_openBlock(), _createBlock(_component_varma_button, {
                  key: 0,
                  onClick: _ctx.openPremisesContact
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('/blocks/premisesfreespaces/askspace')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.premisesPdfUrl)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: _ctx.premisesPdfUrl,
                  target: "_blank",
                  class: "varma-button varma-ghost"
                }, _toDisplayString(_ctx.$t('/blocks/premisesfreespaces/downloadfloorplanpdf')), 9, _hoisted_6))
              : _createCommentVNode("", true)
          ])
        ]),
        (!_ctx.isSmallScreen)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("section", {
                class: "item-modal__image-slider",
                "aria-label": _ctx.$t('/blocks/premisesfreespaces/imagesarialabel')
              }, [
                _createVNode(_component_varma_image_slider, {
                  index: _ctx.index,
                  images: _ctx.images,
                  fill: true,
                  "show-cards": true,
                  "onUpdate:index": _ctx.indexChange
                }, null, 8, ["index", "images", "onUpdate:index"])
              ], 8, _hoisted_7),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  class: "item-modal__close-button item-modal__close-footer-button",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
                }, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('/blocks/premisesfreespaces/closeview')), 1),
                  _createVNode(_component_varma_icon, {
                    name: "close",
                    color: "primary",
                    weight: "bold",
                    size: "XS",
                    "vertical-align": "middle"
                  })
                ])
              ])
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
            return (_openBlock(), _createElementBlock("img", {
              key: i,
              src: `${image.url}?preset=free-spaces-main`,
              alt: image.alt
            }, null, 8, _hoisted_11))
          }), 128)),
          (_ctx.isSmallScreen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("button", {
                  id: "close-free-spaces-dialog-small-button",
                  class: "item-modal__close-button item-modal__inline-images-close-button",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
                }, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('/blocks/premisesfreespaces/closeimageview')), 1),
                  _createVNode(_component_varma_icon, {
                    name: "close",
                    color: "primary",
                    weight: "bold",
                    size: "XS",
                    "vertical-align": "middle"
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 512)
    ]),
    _: 1
  }, 8, ["show", "open-dialog-button", "onOnClose"]))
}