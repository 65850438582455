import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5346e1c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "varma-feedback__content" }
const _hoisted_2 = {
  key: 0,
  class: "varma-feedback__title"
}
const _hoisted_3 = { class: "varma-feedback__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["varma-feedback", {
            'varma-feedback--info': _ctx.type === _ctx.VarmaFeedbackType.Info,
            'varma-feedback--success': _ctx.type === _ctx.VarmaFeedbackType.Success,
            'varma-feedback--error': _ctx.type === _ctx.VarmaFeedbackType.Error,
            'varma-feedback--toast': _ctx.toast
        }])
      }, [
        _createVNode(_component_varma_icon, {
          name: _ctx.icon,
          color: _ctx.iconColor,
          weight: "bold",
          "vertical-align": "middle"
        }, null, 8, ["name", "color"]),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "feedback-content", {}, undefined, true)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}