
import { defineComponent, nextTick, PropType } from 'vue';

import { scrollElement } from '@/utils/scroll';
import { viewportWidth } from '@/utils/viewport';

import VarmaIcon from '../../common/icons/VarmaIcon.vue';
import VarmaImageSliderCards from './VarmaImageSliderCards.vue';

interface Image {
    url: string;
    alt: string;
}

export default defineComponent({
    components: {
        VarmaIcon,
        VarmaImageSliderCards
    },
    props: {
        index: { type: Number, default: 0 },
        images: { type: Array as PropType<Image[]>, default: (): Image[] => [] },
        presets: { type: Array as PropType<[number, string][]>, default: null },
        fill: { type: Boolean, default: false },
        showPages: { type: Boolean, default: false },
        showCards: { type: Boolean, default: false },
        roundView: { type: Boolean, default: false },
        changeSeconds: { type: Number, default: null}
    },
    emits: ['update:index'],
    data() {
        return {
            // This slider is used as a standalone component. In these cases index property isn't set.
            innerIndex: 0,
            timeout: undefined,
        } as {
            innerIndex: number;
            timeout: any;
        };
    },
    computed: {
        hasControls(): boolean {
            return this.images.length > 1;
        }
    },
    watch: {
        index(): void {
            this.innerIndex = this.index;
        },
        innerIndex(): void {
            this.indexChanged();
        }
    },
    mounted() {
        this.setup();
        window.addEventListener('resize', this.setup);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.setup); 
    },
    methods: {
        getInnerEl(): HTMLDivElement | null {
            return this.$refs['innerEl'] as HTMLDivElement;
        },

        getTrackEl(): HTMLDivElement | null {
            return this.$refs['trackEl'] as HTMLDivElement;
        },

        getPreset(): null | string {
            if (!this.presets) {
                return null;
            }

            const winWidth = viewportWidth.value;
            for (let i = this.presets.length - 1; i >= 0; --i) {
                const preset = this.presets[i];
                if (winWidth >= preset[0]) {
                    return preset[1];
                }
            }

            return null;
        },

        getImageUrl(url: string): string {
            const preset = this.getPreset();
            if (!preset) {
                return url;
            }

            return `${url}?preset=${preset}`;
        },

        async setup(): Promise<void> {
            this.indexChanged();
        },

        async indexChanged(): Promise<void> {
            await nextTick();
            
            const items = Array.from(this.getTrackEl()!.children) as HTMLElement[];                
            const offset = items[0].offsetLeft;

            const activeItem = items[this.innerIndex];

            const left = activeItem.offsetLeft - offset;

            scrollElement(this.getInnerEl()!, {
                left: left,
                behavior: 'smooth'
            });

            this.updateTimer();
        },

        setIndex(index: number): void {
            this.innerIndex = index;
            this.$emit('update:index', index);
        },

        setIndexRelative(rel: number): void {
            let index = this.innerIndex + rel;

            if (index < 0) {
                index = this.images.length - 1;
            } else if (index >= this.images.length) {
                index = 0;
            }

            this.setIndex(index);
        },

        updateTimer() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            if (this.changeSeconds) {                
                this.timeout = setTimeout(
                    () => { 
                        this.setIndexRelative(1);
                    }, 
                    this.changeSeconds * 1000);
            }
        },
        //swiping filched from https://stackoverflow.com/questions/12869055/does-typescript-support-touchevent
        startSwipe(touchEvent: TouchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd (touchEvent: TouchEvent, posXStart: number) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd) {
                this.setIndexRelative(-1);
            } else if (posXStart > posXEnd) {
                this.setIndexRelative(1);
            }
        }
    }
});
