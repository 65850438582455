
import { defineComponent } from 'vue';
import VarmaQAModel from './VarmaQAModel';
import VarmaDialog from '../../common/dialog/VarmaDialog.vue';

export default defineComponent({
    components: {
        VarmaDialog
    },
    props: {
        fullImgSrc: { type: String, required: false, default: '' },
        accordionModel: { type: String, required: true }
    },
    data() {
        return {
            model: JSON.parse(this.accordionModel),
            showFullImage: false
        } as 
        {
            model: VarmaQAModel;
            showFullImage: boolean;
        };
    },
    methods: {
        getAnchorLink(): string {
            return ''.concat('#', this.model.anchorId);
        },
        openDialog(): void {
            this.showFullImage = true;
        },
        closeDialog(): void {
            (this.$refs.dialog as typeof VarmaDialog).close();
            this.showFullImage = false;
        }
    }
});

