import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$t("/week")) + " " + _toDisplayString(_ctx.count), 1),
    _createVNode(_component_varma_icon, {
      size: "XS",
      color: "black",
      weight: "bold",
      name: "question-circle"
    }),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.alarma && _ctx.alarma(...args)))
    }, " press me ")
  ]))
}