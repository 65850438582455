
import { defineComponent, PropType, ref } from 'vue';
import { VarmaPremisesLocationCardModel } from './VarmaCardModel';
import { language } from '@/utils/language';

export default defineComponent({
    props: {
        model: { type: Object as PropType<VarmaPremisesLocationCardModel>, required: true },
        solid: { type: Boolean, required: false },
    },
    setup() {
        const link = ref<HTMLLinkElement>();
        const focus = (): void => {
            if (link.value) {
                link.value.focus();
            }
        };

        return { link, focus };
    },
    computed: {
        imageStyle(): string {
            if (!this.model.imageUrl) {
                return '';
            }

            return 'background-image: url(' + this.model.imageUrl + '?preset=premises-location-card)';
        },
        bodyText(): string {
            if (this.model.minVacantArea && this.model.maxVacantArea) {
                return this.$t('/premises/locationcard/vacantspaceformat')
                    .replace('{min}', this.model.minVacantArea.toLocaleString(language))
                    .replace('{max}', this.model.maxVacantArea.toLocaleString(language));
            }

            return this.$t('/premises/locationcard/novacantspace');
        }
    }
});
