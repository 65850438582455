
import { defineComponent, PropType } from 'vue';
import { ICON_MAP, VarmaIconAlign, VarmaIconSize, VarmaIconName, VarmaIconWeight } from './icons';

export default defineComponent({
    props: {
        size: {
            type: String as PropType<VarmaIconSize>,
            default: 'M',  
        },
        color: {
            type: String,
            default: 'primary'
        },
        weight: {
            type: String as PropType<VarmaIconWeight>,
            default: 'regular'
        },
        name: {
            required: true,
            type: String as PropType<VarmaIconName>,
        },
        title: {
            type: String,
            default: null
        },
        verticalAlign: {
            type: String as PropType<VarmaIconAlign>,
            default: '',
        },
        ariaLabel: {
            type: String,
            default: null,
        }
    },
    setup(props): { icon: any; classes: string } { 
        let icon: any;
        const classes = [];

        classes.push('icon-size--' + props.size);

        if (props.weight === 'regular') {
            icon = ICON_MAP[props.name].regular;
            classes.push('icon-stroke-' + props.color);
        } else if (props.weight === 'bold') {
            icon = ICON_MAP[props.name].bold;
            classes.push('icon-fill-' + props.color);
        } else if (props.weight === 'light') {
            icon = ICON_MAP[props.name].light;
            classes.push('icon-stroke-' + props.color);
        } else {
            throw new TypeError('Unknown icon weight.');
        }

        if (props.verticalAlign) {
            classes.push('icon-align-' + props.verticalAlign);
        }

        return  { icon, classes: classes.join(' ') };
    }
});
