import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f1735ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qacontent" }
const _hoisted_2 = { class: "content-text content-text--M" }
const _hoisted_3 = {
  key: 0,
  class: "qacontent__images"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "image-modal__enlarge" }
const _hoisted_6 = { class: "image-modal__image-container" }
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = {
  key: 0,
  class: "anchor-link"
}
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_button = _resolveComponent("varma-button")!
  const _component_varma_dialog = _resolveComponent("varma-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.model.imageUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.model.imageUrl,
              class: "qacontent__image",
              alt: _ctx.model.imageAltText
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_varma_button, {
                ref: "openModalButton",
                "hide-from-screen-reader": "",
                ghost: "",
                variant: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDialog()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("/common/button-enlarge-image")), 1)
                ]),
                _: 1
              }, 512)
            ]),
            _createVNode(_component_varma_dialog, {
              show: _ctx.showFullImage,
              "allow-dismiss": true,
              "open-dialog-button": this.$refs.openModalButton,
              ref: "dialog"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    "aria-label": _ctx.$t('/common/close'),
                    class: "image-modal__close",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog()))
                  }, _toDisplayString(_ctx.$t('/common/close')), 9, _hoisted_7),
                  _createElementVNode("img", {
                    src: _ctx.fullImgSrc,
                    alt: _ctx.model.imageAltText,
                    class: "image-modal__image"
                  }, null, 8, _hoisted_8)
                ])
              ]),
              _: 1
            }, 8, ["show", "open-dialog-button"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.model.linkHereVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("a", {
            href: _ctx.getAnchorLink()
          }, "Linkki tähän", 8, _hoisted_10)
        ]))
      : _createCommentVNode("", true)
  ]))
}