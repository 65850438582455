
import { defineComponent, PropType, watch, ref, watchEffect } from 'vue';
import { ensureElementVisible } from '@/utils/scroll';

export default defineComponent({
    props: {
        modelValue: { type: [String, Number], default: null },
        groupName: { type: String, required: true },
        id: { type: String, required: true },
        groupLabel: { type: String, default: ''},
        options: { type: Array as PropType<Array<{label: string; value: string}>>, required: true },
        rules: {
            type: Array as PropType<{ (v: any): boolean | string }[]>,
            default: (): { (v: any): boolean | string }[] => []
        }
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const internalValue = ref(props.modelValue);
        const validationError = ref(false as boolean | string);

        function validate(): boolean | string {
            let error: boolean | string = false;

            for (const rule of props.rules) {
                const result = rule(internalValue.value);

                if (result) {
                    error = result;
                    break;
                }
            }

            validationError.value = error;

            return error;
        }

        watch(
            () => internalValue.value,
            () => {
                validate();
                context.emit('update:modelValue', internalValue.value);
            }
        );

        watch(
            () => props.modelValue,
            () => {
                internalValue.value = props.modelValue;
            }
        );

        watchEffect(
            () => {
                if (validationError.value) {
                    validate();
                }
            }
        );

        return {
            internalValue,
            validationError,
            validate
        };
    },
    computed: {
        errorId(): string {
            return ''.concat(this.id, '--error');
        },
        errorVisible(): boolean {
            return !!this.validationError && typeof this.validationError == 'string';
        },
        ariaDescribedBy(): null | string {
            if (this.errorVisible) {
                return this.errorId;
            }
            return null;
        }
    },
    methods: {
        select(value: any): void {
            this.internalValue = value;
        },
        focus(index = 0): void {
            (this.$refs.inputs as Array<HTMLInputElement>)[index].focus();
        },
        ensureVisible(): void {
            ensureElementVisible(this.$refs.inputOffset as HTMLInputElement);
        }
    }
});
