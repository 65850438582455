
import { defineComponent } from 'vue';
import axios from 'axios';
import * as GA from '../../../utils/GA';
import { validateField, validateFormFields } from '../../common/forms/validators';

interface Result {
    minRetirementAgeYears: number;
    minRetirementAgeMonths: number;
    targetRetirementAgeYears: number | null;
    targetRetirementAgeMonths: number | null;
    maxRetirementAgeYears: number | null;
    maxRetirementAgeMonths: number | null;
    minOvaAgeYears: number | null;
    minOvaAgeMonths: number | null;
}

export default defineComponent({    
    props: {
        eserviceLink: { type: String, default: '' },
        eserviceLinkText: { type: String, default: '' },
        maxBirthYear: { type: Number, default: 0 },
    },
    data() {
        return {
            birthMonth: null,
            birthYear: null,
            birthDate: null,
            months: [
                { value: '1', text: this.$t('/unit/month/january') },
                { value: '2', text: this.$t('/unit/month/february') },
                { value: '3', text: this.$t('/unit/month/march') },
                { value: '4', text: this.$t('/unit/month/april') },
                { value: '5', text: this.$t('/unit/month/may') },
                { value: '6', text: this.$t('/unit/month/june') },
                { value: '7', text: this.$t('/unit/month/july') },
                { value: '8', text: this.$t('/unit/month/august') },
                { value: '9', text: this.$t('/unit/month/september') },
                { value: '10', text: this.$t('/unit/month/october') },
                { value: '11', text: this.$t('/unit/month/november') },
                { value: '12', text: this.$t('/unit/month/december') },
            ],
            result: null
        } as {
            birthMonth: null | string;
            birthYear: null | string;
            birthDate: null | Date;
            months: { value: string; text: string }[];
            result: null | Result;
        };
    },
    computed: {
        minRetirementDate(): string | null {
            if (!this.birthDate || !this.result) {
                return null;
            }

            const date = new Date(
                this.birthDate.getFullYear() + this.result.minRetirementAgeYears,
                this.birthDate.getMonth() + this.result.minRetirementAgeMonths + 1,
                1);

            return `1.${date.getMonth() + 1}.${date.getFullYear()}`;
        },
        targetRetirementDate(): string | null {
            if (!this.birthDate || !this.result || !this.result.targetRetirementAgeYears) {
                return null;
            }

            const date = new Date(
                this.birthDate.getFullYear() + this.result.targetRetirementAgeYears!,
                this.birthDate.getMonth() + this.result.targetRetirementAgeMonths! + 1,
                1);

            return `1.${date.getMonth() + 1}.${date.getFullYear()}`;
        },
        maxRetirementDate(): string | null {
            if (!this.birthDate || !this.result || !this.result.maxRetirementAgeYears) {
                return null;
            }

            const date = new Date(
                this.birthDate.getFullYear() + this.result.maxRetirementAgeYears!,
                this.birthDate.getMonth() + this.result.maxRetirementAgeMonths! + 1,
                1);

            return `1.${date.getMonth() + 1}.${date.getFullYear()}`;
        },
        minOvaDate(): string | null {
            if (!this.birthDate || !this.result || !this.result.minOvaAgeYears) {
                return null;
            }

            const date = new Date(
                this.birthDate.getFullYear() + this.result.minOvaAgeYears!,
                this.birthDate.getMonth() + this.result.minOvaAgeMonths! + 1,
                1);

            return `1.${date.getMonth() + 1}.${date.getFullYear()}`;
        },
    },
    mounted() {
        this.$watch(
            () => [this.birthMonth, this.birthYear],
            () => {
                this.result = null;
            });
    },
    methods: {
        validateField(v: string): boolean | string {
            return validateField(v);
        },
        validateYear(v: string): boolean | string {
            if (!v) {
                return false;
            }

            const max = this.maxBirthYear;

            const year = parseInt(v, 10);
            return !year || year < 1955 || (max > 0 && year > max)
                ? this.$t('/blocks/pensionagecalculator/yearrangeerror')
                : false;
        },
        async submit(): Promise<void> {
            let error = validateFormFields(this.$refs, ['birthMonth', 'birthYear']);

            if (error) {
                return;
            }

            const birthDate = new Date(parseInt(this.birthYear!, 10), parseInt(this.birthMonth!, 10) - 1, 1);

            GA.pushDataLayer({'name':'PensionAgeCalculator', 'action':'calculate', 'event': 'cCalculator' });

            const { data } = await axios.post('/api/pension-age-calculator', { birthYear: birthDate.getFullYear(), birthMonth: birthDate.getMonth() + 1 });
            this.result = data;
            this.birthDate = birthDate;
        },
    }
});

