

import { defineComponent, PropType, ref } from 'vue';
import VarmaButtonVariant from './VarmaButtonVariant';
import VarmaBaseButton from '../VarmaBaseButton';

export default defineComponent({
    props: {
        value: { type: Object as PropType<VarmaBaseButton>, default: null },
        disabled: Boolean,
        hideFromScreenReader: Boolean,
        selected: Boolean,
        small: Boolean,
        ghost: Boolean,
        textOnly: Boolean,
        variant: { type: String, default: VarmaButtonVariant.PRIMARY }
    }, 
    setup() {
        const button = ref<InstanceType<typeof HTMLButtonElement>>();

        const focus = (): void => {
            if (button.value) {
                button.value.focus();
            }
        };
        return {
            button,
            focus
        };
    },
    methods: {
        hasButtonText(): boolean {
            return this.value !== undefined;
        },
        getButtonText(): string | undefined {
            return this.value ? this.value.buttonText : '';
        },
        isDisabled(): boolean {
            return this.value ? this.value.disabled : this.disabled;
        },
        isSelected(): boolean {
            return this.value ? this.value.selected : this.selected;
        },
        isAccent(): boolean {
            return this.variant === VarmaButtonVariant.ACCENT;
        },
        isPrimary(): boolean {
            return this.variant === VarmaButtonVariant.PRIMARY;
        },
        isWarn(): boolean {
            return this.variant === VarmaButtonVariant.WARN;
        },
        getButtonValue(): string | number | undefined {
            return this.value ? this.value.value : undefined;
        }
    }
});
