
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        compact: { type: Boolean, required: false, default: false },
    },
    data: (): {
        tabs: any[];
    } => ({
        tabs: []
    }),
    methods: {
        addTab(tab: any): void {
            this.tabs.push(tab);
        },
        selectTab(selectedTab: any): void {
            for (const tab of this.tabs) {
                tab.active = false;
            }

            selectedTab.active = true;
        }
    }
});
