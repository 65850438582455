import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d16b699"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "premises-location-card__content" }
const _hoisted_3 = { class: "premises-location-card__title" }
const _hoisted_4 = { class: "premises-location-card__description" }
const _hoisted_5 = { class: "premises-location-card__body" }
const _hoisted_6 = {
  key: 0,
  class: "premises-location-card__aria"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    ref: "link",
    href: _ctx.model.url,
    class: _normalizeClass(["premises-location-card", { 'premises-location-card--solid': _ctx.solid }])
  }, [
    _createElementVNode("div", {
      class: "premises-location-card__image",
      style: _normalizeStyle(_ctx.imageStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.tags, (tag) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tag,
          "aria-hidden": "true",
          class: "premises-location-card__tag"
        }, _toDisplayString(tag), 1))
      }), 128))
    ], 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.model.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.model.description), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.bodyText), 1),
      (_ctx.model.tags && _ctx.model.tags.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("/premises/locationcard/environmentalcertificate")) + ": " + _toDisplayString(_ctx.model.tags.join(', ')), 1))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}