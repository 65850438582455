
import { defineComponent } from 'vue';
import VarmaIcon from './components/common/icons/VarmaIcon.vue';

export default defineComponent({
    components: {
        VarmaIcon
    },
    data() {
        return {
            count: 1
        };
    },
    methods: {
        alarma(): void {
            ++this.count;
            console.log(this.$t('/week'));
        }
    }
});

