import { ref } from 'vue';

function getHash() {
    return (window.location.hash || '').replace('#', '');
}

export const hash = ref<string>(getHash());

window.addEventListener('hashchange', () => { 
    hash.value = getHash();
});
